<template>
  <div class="parcels-wrapper__orders">
    <div class="grid-wrapper">
      <div class="col col-12">
        <div class="grid-info">
          <span class="grid-heading">
            <h1>{{ $t('Orders') }}</h1>
            <span class="entries">{{ NumberFormat(entries) }} {{ $t(`entr${entries == 1 ? 'y' : 'ies'}`) }} 
              <span v-if="SelectedOrders()">/ {{ NumberFormat(SelectedOrders()) }} {{ $t('selected') }}</span>
            </span>
          </span>
          <div class="grid-actions">
            <ul class="state-count" v-if="AllowStateCount()">
              <li :class="['circle', state.label, {link: state.label != search.state && !(state.label == 'locked' && search.locked), fetching: refresh.fetching}]" v-for="(state, index) in count.sum" :key="index" @click="ClickState(state.label)" :title="$t('Updated') + ': ' + TimeOnly(new Date(refresh.date).toUTCString())">
                <div class="circle-label">{{ $t(Capitalize(state.label)) }}</div>
                <div class="circle-value">{{ state.value }}</div>
                <div class="circle-total">{{ state.total }}</div>
                <svg class="pie" viewBox="0 0 100 100" v-if="AllowStateCountRefresh()">
                  <circle cx="50" cy="50" r="25" :style="{strokeDashoffset: refresh.timer.dashoffset}" />
                </svg>
              </li>
            </ul>
          </div>
          <div class="grid-search" style="margin-bottom: 11px;">
            <div class="search-fields" style="align-items: flex-end; max-width: 71%;">
              <input type="text" @keyup.enter="Search" v-model="search.query" :placeholder="$t('Search by order ID, tracking number, address etc...')" spellcheck="false" style="width: 50%;">
              <HelpIcon :message="'If you add a * before your search, then only order numbers that ends with the given search are returned.'" :right="'-10'" :left="'2'" :top="'-3'"></HelpIcon>              
              <div class="size-select" style="width: 160px; margin-top: -100%; margin-left: calc(3% + 0px);">
                <p class="grid-heading" style="font-weight: 600; color: #b3b8bd; padding-bottom: 2px;">{{ $t('Page size') }}</p>
                <v-select name="page-size-select" @input="SearchBySize" v-model="page.size" :options="page.sizes" :searchable="false" :clearable="false" />
              </div>
            </div>
          </div>
          <div class="grid-accordion">
            <div ref="extra_filter_toggle" class="toggle-button icon plus" @click="ToggleExtraSearchFilters" />
            <div class="accordion-wrapper grid-wrapper" ref="accordion_wrapper">
              <div class="accordion-container grid-info">
                <div class="grid-search" style="width: 59%; margin-right: 30px;">
                  <div class="search-fields">
                    <input type="text" @keyup.enter="SearchByProduct" v-model="search.product" :placeholder="$t('Search by SKU, EAN number or product name')" spellcheck="false" style="width: 100%;">
                  </div>
                </div>
                <div class="grid-search-filter">
                  <div style="width: 160px; margin-top: 0; flex-shrink: 0;">
                    <p class="grid-heading" style="font-weight: 600; color: #b3b8bd; padding-bottom: 2px;">{{ $t('Date type') }}</p>
                    <v-select name="date-type-select" v-model="search.date.type" :options="date.types" :clearable="false">
                      <template v-slot:selected-option="option">
                        <div class="truncate" :title="option.label">
                          <span>{{ $t(option.label) }}</span>
                        </div>
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="truncate" :title="option.label">
                          <span>{{ $t(option.label) }}</span>
                        </div>
                      </template>
                    </v-select>
                  </div>
                  <div class="grid-date">
                    <p class="grid-heading">{{ $t('Start date') }}</p>
                    <div class="date-range flex-row">
                      <div class="input">
                        <!-- @input="SearchByDate({start: $event})" -->
                        <VueCtkDateTimePicker id="search-date-start" @input="SearchDate({start: $event})" :value="search.date.start" label="" hint="" :locale="$i18n.locale.split('_')[0].replace(/no/, 'nb')" formatted="ddd, MMM D, YYYY, HH:mm" format="YYYY-MM-DD HH:mm:ss" :first-day-of-week="1" input-size="sm" :range="false" :no-shortcuts="true" :no-button="false" :auto-close="false" />
                      </div>
                    </div>
                  </div>
                  <div class="grid-date">
                    <p class="grid-heading">{{ $t('End date') }}</p>
                    <div class="date-range flex-row">
                      <div class="input">
                        <!-- @input="SearchByDate({end: $event})" -->
                        <VueCtkDateTimePicker id="search-date-end" @input="SearchDate({end: $event})" :value="search.date.end" label="" hint="" :locale="$i18n.locale.split('_')[0].replace(/no/, 'nb')" formatted="ddd, MMM D, YYYY, HH:mm" format="YYYY-MM-DD HH:mm:ss" :first-day-of-week="1" input-size="sm" :range="false" :no-shortcuts="true" :no-button="false" :auto-close="false" />
                      </div>
                    </div>
                  </div>
                  <a class="button green" @click.prevent="SearchByDate" href="" style="width: auto; height: 34px; align-self: flex-end;">{{ $t('Apply') }}</a>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-search-filter">
            <div class="grid-locked">
              <div class="toggle locked">
                <div class="grid-heading">{{ $t('Locked') }}</div>
                <div class="checkbox-toggle">
                  <input @click="SearchByLock" type="checkbox" v-model="search.locked">
                  <span class="toggle" />
                </div>
              </div>
            </div>
            <div class="grid-company" v-if="AllowCompanySearch()">
              <div class="select company">
                <div class="grid-heading">{{ $t('Company') }}</div>
                <v-select name="company" ref="company_select" @input="SearchByCompany" :value="company.options.find(option => option.code == search.company)" :options="company.options" />
              </div>
            </div>
            <div class="grid-state">
              <p class="grid-heading">{{ $t('Status') }}</p>
              <v-select class="parcel-state-select" @input="SearchByState" :value="state.options.find(option => option.code == search.state)" :options="state.options">
                <template v-slot:selected-option="option">
                  <span :class="['state', option.code]">
                    {{ $t(Capitalize(option.label)) }}
                  </span>
                </template>
                <template slot="option" slot-scope="option">
                  <span :class="['state', option.code]">
                    {{ $t(Capitalize(option.label)) }}
                  </span>
                </template>
              </v-select>
            </div>
            <div class="grid-state">
              <p class="grid-heading">{{ $t('Language') }}</p>
              <v-select name="parcel-language-select" @input="SearchByLanguage" :value="(language.company.options[search.company || main.company] || []).find(option => option.code == search.language)" :options="Alphabetize((language.company.options[search.company || main.company] || []).map(option => {option.label = LanguageName(option.language); return option}), 'label')">
                <template v-slot:selected-option="option">
                  <div class="truncate">
                    <Flag :code="option.code" size="small" type="language" :title="CountryName(option.country)" />
                    <span>{{ option.label }}</span>
                  </div>
                </template>
                <template slot="option" slot-scope="option">
                  <div class="truncate">
                    <Flag :code="option.code" size="small" type="language" :title="CountryName(option.country)" />
                    <span>{{ option.label }}</span>
                  </div>
                </template>
              </v-select>
            </div>
            <div class="grid-state">
              <p class="grid-heading">{{ $t('Delivery company') }}</p>
              <v-select class="parcel-shipping-select" @input="SearchByAgent" :value="search.agent" :v-model="search.agent" :options="courier.options" :multiple="false">
                <template v-slot:selected-option="option">
                  <div class="truncate">
                    <span :class="['courier', option.code]">
                      {{ option.label }}
                    </span>
                  </div>
                </template>
                <template slot="option" slot-scope="option">
                  <div class="truncate">
                    <span :class="['courier', option.code]">
                      {{ option.label }}
                    </span>
                  </div>
                </template>
              </v-select>
            </div>
            <div class="grid-shipping-method">
              <p class="grid-heading">{{ $t('Shipping method') }}</p>
              <v-select class="parcel-shipping-select" @input="SearchByShipping" :value="shipping.value" v-model="shipping.value" :options="shipping.options" :multiple="false">
                <template v-slot:selected-option="option">
                  <div class="truncate" :title="option.label">
                    <span :class="['courier', option.agent]">
                      {{ option.label }}
                    </span>
                  </div>
                </template>
                <template slot="option" slot-scope="option">
                  <div class="truncate" :title="option.label">
                    <span :class="['courier', option.agent]">
                      {{ option.label }}
                    </span>
                  </div>
                </template>
              </v-select>
            </div>
          </div>
          <div v-if="bulk.action.visible" class="grid-search-filter">
            <div class="grid-state" style="padding: 10px 0;">
              <p class="grid-heading">{{ $t('Action') }}</p>
              <v-select name="bulk-action" ref="bulk_action" v-model="bulk.action.selected" @input="BulkAction" :options="bulk.action.options.map(o => {o.label = $t(o.label); return o})" :clearable="false" />
            </div>
          </div>
        </div>
        <table class="parcel-list table odd-even" style="table-layout: fixed;">
          <tbody>
            <tr :data-id="parcel.id" :class="['parcel-list__row clickable', {pinned: parcel.has_pinned, event: parcel.courier_event}]" @mousedown="ClickOpen" @mousemove="ClickOpen" @mouseup="ClickOpen" :key="index" v-for="(parcel, index) in parcels">
              <td class="label" style="display: table-cell;">
                <div class="v-wrapper checkbox">
                  <label class="v-checkbox-label">
                    <input class="v-checkbox" type="checkbox" v-model="parcel.checked" @change="ToggleCheckbox($event, parcel)">
                    <span class="v-checkbox-toggle" />
                  </label>
                </div>
              </td>
              <td class="orderid">
                <div class="flex-row" style="align-items: center;">
                  <!-- <span class="pin" :class="{pinned: parcel.has_pinned}" @click="ToggleOrderPin(parcel, false)" :title="$t(parcel.has_pinned ? 'Pinned': 'Unpinned')" /> -->
                  <span class="lock" :class="{locked: parcel.is_locked}" @click="ToggleOrderLock(parcel, false)" :title="$t(parcel.is_locked ? 'Locked': 'Unlocked')" />
                  {{ parcel.company_id }}:{{ parcel.increment_id }}
                </div>
              </td>
              <td>
                <div v-if="AllowStateChange()" class="flex-row" style="display: inline-flex;">
                  <div class="selector state-selector">
                    <div class="selector-trigger" @click="SelectorTrigger" :title="$t(Capitalize(parcel.cms_status))">
                      <span class="selector-value" :data-value="parcel.state">{{ $t(Capitalize(parcel.state)) }}</span>
                    </div>
                    <ul class="selector-options">
                      <li class="selector-option" v-for="(option, index) in state.options" :key="index">
                        <label :for="parcel.id">
                          <input :class="['selector-option-value', {checked: option.code == parcel.state}]" type="radio" :id="parcel.id" :name="parcel.id" :value="option.code" :checked="option.code == parcel.state" @change="StateChange">
                          <span :class="['selector-option-text', option.code]">{{ $t(option.label) }}</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
                <div v-else class="flex-row state" :class="parcel.state" :title="$t(Capitalize(parcel.cms_status))">
                  {{ $t(Capitalize(parcel.state)) }}
                </div>
                <!--
                <div v-if="parcel.cms_status" style="position: absolute; margin: -4px 0 0 40px; font-size: 13px; font-weight: 600; font-family: system-ui; line-height: 1.2;" title="CMS status">
                  <span style="position: relative; margin-left: -35px;">CMS:</span>
                  {{ $t(Capitalize(parcel.cms_status)) }}
                </div>
                -->
              </td>
              <td class="webshop" v-html="Hyperlink({href: parcel.webshop.url, target: '_blank', text: parcel.webshop.url.replace('https://', ''), title: LanguageName(parcel.language.code)})" />
              <td class="orderdate">{{ DateFormat(parcel.order_date) }}</td>
              <td class="destination">
                <div class="country-city">
                  <!-- <gb-flag :code="iso[parcel.shipping_address.country]" size="small" /> -->
                  <Flag :code="iso[parcel.shipping_address.country]" size="small" :title="CountryName(iso[parcel.shipping_address.country])" />
                  <span class="city">{{ parcel.shipping_address.city }}</span>
                </div>
              </td>
              <td class="address">
                <div class="address-shipping" :class="{edited: parcel.manual_edits_shipping_address}">
                  <div v-if="parcel.shipping_address.first_name || parcel.shipping_address.last_name" class="recipient truncate">
                    <div class="flex-row customer" :class="{note: parcel.has_customer_note}" @click="parcel.has_customer_note && ViewParcelShow(parcel.id, 'note')" :title="parcel.has_customer_note && $t('Customer note')">
                      <span class="name">
                        {{ NameCase(parcel.shipping_address.first_name + (parcel.shipping_address.first_name && parcel.shipping_address.last_name ? ' ' : '') + parcel.shipping_address.last_name) }}</span>
                      <span v-if="parcel.has_customer_note" class="comment-exclamation"></span>
                    </div>
                  </div>
                  <div v-if="parcel.shipping_address.company">{{ parcel.shipping_address.company }}</div>
                  <div v-if="parcel.shipping_address.street_address">{{ parcel.shipping_address.street_address }}</div>
                  <span v-if="parcel.shipping_address.zip_code">{{ parcel.shipping_address.zip_code }} </span>
                  <span v-if="parcel.shipping_address.city">{{ parcel.shipping_address.city }}</span>
                </div>
              </td>
              <td style="max-width: 105px; overflow-wrap: anywhere;"><span style="font-size: 0.5rem">{{ parcel.currency_iso }}</span> <br> {{ CurrencyFormat(parcel.grand_total || '0.00', 2) }}</td>
              <td class="trackingnumber" style="padding-right: 0;" @click="ParcelTracking" :class="{tnt: parcel.tracking}" :data-tracking="parcel.tracking" :data-courier="parcel.courier" :data-language="parcel.language.code" :data-zip_code="parcel.shipping_address.zip_code">
                <div v-if="parcel.courier_difference" class="shipping">
                  <span class="courier-icon" :class="parcel.shipping_courier" :title="couriers[parcel.shipping_courier]"></span>
                  <div class="shipping-tracking">
                    <div class="shipping-tracking-label">{{ $t('Shipping method') }}</div>
                    <div>{{ parcel.shipping_description/*.replace(/%2F/g, '/')*/ }}</div>
                  </div>
                </div>
                <div class="shipping">
                  <span :class="['courier-icon', parcel.courier]" :title="couriers[parcel.courier]"></span>
                  <div class="shipping-tracking">
                    <div v-if="parcel.courier_difference" class="shipping-tracking-label">{{ $t('Tracking number') }}</div>
                    <span v-if="!parcel.courier_difference">{{ parcel.shipping_description/*.replace(/%2F/g, '/')*/ }}</span>
                    <div v-if="parcel.tracking" class="latest-tracking truncate" :class="{count: parcel.pack_log.length, multiple: parcel.pack_log.length > 1}" :data-count="parcel.pack_log.length">
                      <span class="tracking" :class="{url: parcel.tracking != 'shop'}">{{ parcel.tracking }}</span>
                      <span v-if="parcel.pack_log.length" class="list-icon" @click="ViewParcelShow(parcel.id, 'tracking')" :title="$t('Tracking history')"></span>
                    </div>
                    <div v-if="parcel.courier_event" style="font-size: 13px; font-weight: 600; font-family: system-ui; line-height: 1.2; padding-top: 4px;" :title="parcel.courier_event.text">
                      <!-- <b class="">[</b> <span>{{ parcel.courier_event.text }}</span> <b>]</b> -->
                      {{ $t(parcel.courier_event.message) }}
                    </div>
                  </div>
                </div>
              </td>
              <td @mouseenter="SetBackground" @mouseleave="SetBackground" class="actions">
                <a @click.prevent="" href="" class="icon dots">
                  <ul class="item-actions">
                    <li>
                      <a @click.prevent="ViewParcelShow(parcel.id)" href="" class="success">
                        {{ $t('View details') }}
                      </a>
                    </li>
                    <li>
                      <a @click.prevent="CopyOrderURL(parcel), main.view.focus()" href="" class="success">
                        {{ $t('Copy URL') }}
                      </a>
                    </li>
                    <li>
                      <a @click.prevent="OpenInCMS(parcel)" href="" class="success">
                        {{ $t('Open CMS') }}
                      </a>
                    </li>
                    <li>
                      <a @click.prevent="OpenPDF(parcel.id)" href="" class="success">
                        {{ $t('Open PDF') }}
                      </a>
                    </li>
                    <li>
                      <a class="actions-toggle" @click.prevent="" href="">
                        {{ $t('Download') }}
                        <u class="nested-actions">
                          <li>
                            <a @click.prevent="DownloadParcelOrder(parcel)" href="" class="success">{{ $t('Parcel order') }}</a>
                          </li>
                          <li>
                            <a @click.prevent="DownloadShippingLabel(parcel)" href="">{{ $t('Shipping label') }}</a>
                          </li>
                          <li>
                            <a @click.prevent="DownloadReturnLabel(parcel)" href="">{{ $t('Return label') }}</a>
                          </li>
                        </u>
                      </a>
                    </li>
                    <li>
                      <a class="actions-toggle" @click.prevent="" href="">
                        {{ $t('Print') }}
                        <u class="nested-actions">
                          <li>
                            <a @click.prevent="PrintOrder(parcel.id)" href="" class="success">{{ $t('Parcel order') }}</a>
                          </li>
                          <li>
                            <a @click.prevent="PrintShippingLabel(parcel, true)" href="">{{ $t('Shipping label') }}</a>
                          </li>
                          <li>
                            <a @click.prevent="PrintReturnLabel(parcel)" href="">{{ $t('Return label') }}</a>
                          </li>
                        </u>
                      </a>
                    </li>
                    <li>
                      <a class="actions-toggle" @click.prevent="" href="">
                        {{ $t('Create') }}
                        <u class="nested-actions">
                          <li>
                            <a @click.prevent="CreateNewCustomer(parcel)" href="">{{ $t('New customer') }}</a>
                          </li>
                          <li>
                            <a class="actions-toggle" @click.prevent="" href="">
                              {{ $t('Shipment') }}
                              <u class="nested-actions">
                                <li>
                                  <a @click.prevent="CreateCustomShippingLabel(parcel)" href="" :class="['success']">{{ $t('Shipping label') }}</a>
                                </li>
                                <li>
                                  <a @click.prevent="CreateCustomReturnLabel(parcel)" href="" :class="['success']">{{ $t('Return label') }}</a>
                                </li>
                              </u>
                            </a>
                          </li>
                        </u>
                      </a>
                    </li>
                    <li>
                      <a class="actions-toggle" @click.prevent="" href="">
                        {{ $t('Send') }}
                        <u class="nested-actions">
                          <li>
                            <a @click.prevent="SendReturnLabel(parcel)" href="">{{ $t('Return label') }}</a>
                          </li>
                        </u>
                      </a>
                    </li>
                  </ul>
                </a>
              </td>
              <td :class="[{'courier-event': parcel.courier_event}, (parcel.courier_event || {}).color]" v-if="parcels.some(parcel => parcel.courier_event)" />
            </tr>
          </tbody>
          <thead>
            <tr class="parcel-list__actions">
              <th class="label" style="width: 3%; display: table-cell; z-index: 3;">
                <div class="v-wrapper checkbox">
                  <label class="v-checkbox-label">
                    <input class="v-checkbox" type="checkbox" @input.prevent="HandleDropdownTrigger" :disabled="!parcels.length">
                    <div class="v-checkbox-toggle dropdown-trigger">
                      <div class="dropdown-list">
                        <div class="dropdown-item" v-show="bulk.select.visible.includes(option.code)" :key="option.code" v-for="option of bulk.select.options" @click="HandleDropdownOption(option)">{{ $t(option.label) }}</div>
                      </div>
                    </div>
                  </label>
                </div>
              </th>
              <th class="orderid" style="width: 9%;">
                <a :class="[page.order.by == 'increment_id' ? 'active' : '', page.order.direction]" @click.prevent="SetOrderBy('increment_id')" href="">
                  <span>{{ $t('Order number') }}</span>
                </a>
              </th>
              <th class="state" style="width: 8%; padding-left: 20px;">{{ $t('Status') }}</th>
              <th class="webshop" style="width: 8%;">{{ $t('Webshop') }}</th>
              <th class="orderdate" style="width: 14%;">
                <a :class="[page.order.by == 'order_date' ? 'active' : '', page.order.direction]" @click.prevent="SetOrderBy('order_date')" href="">
                  <span>{{ $t('Order date') }}</span>
                </a>
              </th>
              <th class="destination" style="width: 9%;">{{ $t('Country / city') }}</th>
              <th class="address" style="width: 18%;">{{ $t('Shipping address') }}</th>
              <th style="width: 7%; text-align: center; padding-left: 0px;">{{$t('Grand total')}}</th>
              <th class="trackingnumber" style="width: 18%;">{{ $t('Shipping / tracking') }}</th>
              <th class="edit" />
              <th class="courier-event" v-if="parcels.some(parcel => parcel.courier_event)" />
            </tr>
          </thead>
        </table>
        <div class="list-overflow-x" tabindex="0"></div>
        <div class="list-overflow-scrollbar bottom" style="bottom: 44px;">
          <div class="list-overflow-scrollbar-handle"></div>
        </div>
        <div class="grid-pagination shadow sticky bottom" :style="{marginTop: parcels.length && '2px'}">
          <div class="page-navigation">
            <div class="page-turn prev disabled" @click="PageController(false)"></div>
            <div class="page-number">
              <label class="page-number-current">
                <input type="number" min="1" :max="page.last" :value="page.current" @blur="PageNavigator" @keydown="PageNavigator">
                <span class="placeholder">{{NumberFormat(page.current)}}</span>
              </label>
              <span class="page-number-separator">/</span>
              <div class="page-number-last">{{NumberFormat(page.last)}}</div>
            </div>
            <div class="page-turn next" :class="{disabled: page.last == 1}" @click="PageController(true)"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="loading" ref="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

    <Modal modal="view_parcel" :value="modal.view_parcel.open" :title="`<span>${`${parcel.company_id}:${parcel.increment_id}`}</span><span>${DateFormat(parcel.order_date)}</span>`">
      <div id="view-parcel" v-if="Object.keys(parcel).length">
        <b id="order-lock" :class="{unlocked: !modal.view_parcel.locked}" @click.prevent="ToggleOrderLock(parcel, true)"></b>
        <span style="font-size: 1.2rem; font-weigth: bold;">Paperless codes:</span>
        <span><p v-for="(paperless_code, index) in parcel.paperless_code" :key="index" style="font-size: 1.0rem">{{paperless_code}}</p></span>
        <div class="state-wrapper" :class="[[-1, -2, -3, -4].includes((state.options.find(option => option.code == parcel.state) || {}).progress) && parcel.state]">
          <div class="state-wrapper__item">
            <span class="bar" :class="[{filled: (state.options.find(option => option.code == parcel.state) || {}).progress >= 0}]" />
            <span class="item-title" v-if="![-1, -2, -3, -4].includes((state.options.find(option => option.code == parcel.state) || {}).progress)">{{ $t('New') }}</span>
          </div>
          <div class="state-wrapper__item">
            <span class="bar" :class="[{filled: (state.options.find(option => option.code == parcel.state) || {}).progress >= 1}]" />
            <span class="item-title" v-if="![-1, -2, -3, -4].includes((state.options.find(option => option.code == parcel.state) || {}).progress)">{{ $t('Pick') }}</span>
          </div>
          <div class="state-wrapper__item">
            <span class="bar" :class="[{filled: (state.options.find(option => option.code == parcel.state) || {}).progress >= 2}]" />
            <span class="item-title" v-if="![-1, -2, -3, -4].includes((state.options.find(option => option.code == parcel.state) || {}).progress)">{{ $t('Pack') }}</span>
          </div>
          <div class="state-wrapper__item">
            <span class="bar" :class="[{filled: (state.options.find(option => option.code == parcel.state) || {}).progress >= 3}]" />
            <span class="item-title" v-if="![-1, -2, -3, -4].includes((state.options.find(option => option.code == parcel.state) || {}).progress)">{{ $t('Complete') }}</span>
          </div>
          <span class="state" v-if="[-1, -2, -3, -4].includes((state.options.find(option => option.code == parcel.state) || {}).progress)">
            {{ $t((state.options.find(option => option.code == parcel.state) || {}).label) }}
          </span>
        </div>

        <div class="modal-tabs">
          <ul class="modal-tabs__head">
            <li class="modal-tabs__head-item" :class="{active: modal.view_parcel.tab.active == 'shipping'}" data-tab="shipping" @click="SetActiveTab">
              <span>{{ $t('Shipping details') }}</span>
            </li>
            <li class="modal-tabs__head-item" :class="{active: modal.view_parcel.tab.active == 'order'}" data-tab="order" @click="SetActiveTab">
              <span>{{ $t('Order summary') }}</span>
            </li>
            <li class="modal-tabs__head-item" :class="{active: modal.view_parcel.tab.active == 'campaign'}" data-tab="campaign" @click="SetActiveTab" v-if="parcel.campaign.length">
              <span>{{ $t('Campaign') }}</span>
            </li>
            <li class="modal-tabs__head-item" :class="{active: modal.view_parcel.tab.active == 'lock'}" data-tab="lock" @click="SetActiveTab" v-if="modal.view_parcel.locked">
              <span>{{ $t('Order lock') }}</span>
            </li>
            <li class="modal-tabs__head-item" :class="{active: modal.view_parcel.tab.active == 'note'}" data-tab="note" @click="SetActiveTab" v-if="parcel.has_customer_note">
              <span>{{ $t('Customer note') }}</span>
            </li>
            <li class="modal-tabs__head-item" :class="{active: modal.view_parcel.tab.active == 'picking'}" data-tab="picking" @click="SetActiveTab" v-if="parcel.picked_by.length">
              <span>{{ $t('Pick list') }}</span>
            </li>
            <li class="modal-tabs__head-item" :class="{active: modal.view_parcel.tab.active == 'invoicing'}" data-tab="invoicing" @click="SetActiveTab" v-if="!(!parcel.invoice_parcel_id && !parcel.invoice_parcel_additional_ids.length)">
              <span>{{ $t('Bulk invoicing') }}</span>
            </li>
            <li class="modal-tabs__head-item" :class="{active: modal.view_parcel.tab.active == 'videos'}" data-tab="videos" @click="SetActiveTab"> <!-- v-if="parcel.pack_log.length" -->
              <span>{{ $t('Video list') }}</span>
            </li>
            <li class="modal-tabs__head-item" :class="{active: modal.view_parcel.tab.active == 'tracking'}" data-tab="tracking" @click="SetActiveTab" v-if="parcel.pack_log.length">
              <span>{{ $t('Tracking history') }}</span>
            </li>
            <li class="modal-tabs__head-item" :class="{active: modal.view_parcel.tab.active == 'status'}" data-tab="status" @click="SetActiveTab($event), GetOrderCmsStatusHistory(parcel.id)">
              <span>{{ $t('Status history') }}</span>
            </li>
            <li class="modal-tabs__head-item" :class="{active: modal.view_parcel.tab.active == 'comments'}" data-tab="comments" @click="SetActiveTab($event), GetOrderComments(parcel.id)">
              <span>{{ $t('Order log') }}</span>
            </li>
          </ul>
          
          <ul class="modal-tabs__body" @scroll="ModalTabBodyScroll">
            <li class="modal-tabs__body-content padding" v-if="modal.view_parcel.tab.active == 'shipping'">
              <div class="billing-address">
                <p class="title">{{ $t('Billing address') }}</p>
                <p class="name" style="display: flex; align-items: center;" v-if="(NameCase(parcel.billing_address.first_name + ' ' + parcel.billing_address.last_name) || '').replace(/\s/g, '').length">
                  <span class="icon-inline left link search" @click="SearchOrdersBy({name: NameCase(parcel.billing_address.first_name + ' ' + parcel.billing_address.last_name)})" :title="$t('Search in orders')" />
                  <span v-html="Hyperlink({scheme: 'google', target: '_blank', href: NameCase(parcel.billing_address.first_name + ' ' + parcel.billing_address.last_name)})" />
                </p>
                <p class="email" style="display: flex; align-items: center;">
                  <span class="icon-inline left link search" @click="SearchOrdersBy({email: parcel.billing_address.email})" :title="$t('Search in orders')" />
                  <span v-html="Hyperlink({scheme: 'mailto', href: parcel.billing_address.email})" />
                  <span v-if="parcel.customer['billing_address.mail']" class="icon-inline right link user" @click="SearchCustomersBy({email: parcel.billing_address.email})" :title="$t('Open customer')" />
                </p>
                <p class="phone" v-if="parcel.billing_address.phone_number" style="display: flex; align-items: center;">
                  <span class="icon-inline left link search" @click="SearchOrdersBy({phone: parcel.billing_address.phone_number})" :title="$t('Search in orders')" />
                  <span v-html="Hyperlink({scheme: 'tel', href: parcel.billing_address.phone_number, country: parcel.billing_address.country, text: parcel.billing_address.phone_number})" />
                  <span v-if="parcel.customer['billing_address.tlf']" class="icon-inline right link user" @click="SearchCustomersBy({phone: parcel.billing_address.phone_number})" :title="$t('Open customer')" />
                </p>
                <p class="company" v-if="parcel.billing_address.company" style="display: flex; align-items: center;">
                  <span class="icon-inline left link search" @click="SearchOrdersBy({company: parcel.billing_address.company})" :title="$t('Search in orders')" />
                  <span v-html="Hyperlink({scheme: 'google', target: '_blank', href: parcel.billing_address.company})" />
                </p>
                <p class="address" v-if="parcel.billing_address.street_address || parcel.billing_address.zip_code || parcel.billing_address.city" style="display: flex; align-items: center;">
                  <span class="icon-inline left link search" @click="SearchOrdersBy({address: parcel.billing_address.street_address})" :title="$t('Search in orders')" />
                  <span v-html="Hyperlink({scheme: 'map', target: '_blank', href: `${parcel.billing_address.street_address}${parcel.billing_address.street_address && parcel.billing_address.zip_code ? ', ' : ''}${parcel.billing_address.zip_code} ${parcel.billing_address.city}`.trim().replace(/^,$/, '')})" />
                </p>
                <div class="country" style="margin-top: 5px; display: flex; align-items: center; justify-content: flex-start;">
                  <Flag :code="iso[parcel.billing_address.country]" size="small" />
                  <div v-html="Hyperlink({scheme: 'map', target: '_blank', href: (parcel.billing_address.state_province ? parcel.billing_address.state_province + ', ' : '') + CountryName(iso[parcel.billing_address.country])})" />
                </div>
                <ul class="payment-information" v-if="parcel.payment_information">
                  <div class="title">{{ $t('Payment information') }}</div>
                  <li><b>{{ $t('Internal reference') }}</b>: {{ parcel.payment_information.id }}</li>
                  <li><b>{{ $t('Payment method') }}</b>: {{ parcel.payment_information.method }}</li>
                  <li><b>{{ $t('Transaction ID') }}</b>: {{ parcel.payment_information.transaction_id }}</li>
                  <li><b>{{ $t('Card number') }}</b>: {{ parcel.payment_information.cc_number_enc }}</li>
                  <li><b>{{ $t('Card type') }}</b>: {{ parcel.payment_information.cc_type }}</li>
                </ul>
              </div>
              <div class="shipping-address">
                <p class="title" :class="{edited: parcel.manual_edits_shipping_address}">{{ $t('Shipping address') }}</p>
                <div class="shipping-address-container" v-if="!modal.view_parcel.tab.shipping.editing">
                  <p class="name" style="display: flex; flex-direction: row-reverse; align-items: center; justify-content: flex-start;" v-if="(NameCase(parcel.shipping_address.first_name + ' ' + parcel.shipping_address.last_name) || '').replace(/\s/g, '').length">
                    <span v-if="parcel.agent_code != 'shop'" class="icon-inline right link search" @click="SearchOrdersBy({name: NameCase(parcel.shipping_address.first_name + ' ' + parcel.shipping_address.last_name)})" :title="$t('Search in orders')" />
                    <span v-html="Hyperlink({scheme: 'google', target: '_blank', href: NameCase(parcel.shipping_address.first_name + ' ' + parcel.shipping_address.last_name)})" />
                  </p>
                  <p class="email" style="display: flex; flex-direction: row-reverse; align-items: center; justify-content: flex-start;">
                    <span class="icon-inline right link search" @click="SearchOrdersBy({email: parcel.shipping_address.email})" :title="$t('Search in orders')" />
                    <span v-html="Hyperlink({scheme: 'mailto', href: parcel.shipping_address.email})" />
                    <span v-if="parcel.customer['shipping_address.mail']" class="icon-inline left link user" @click="SearchCustomersBy({email: parcel.shipping_address.email})" :title="$t('Open customer')" />
                  </p>
                  <p class="phone" style="display: flex; flex-direction: row-reverse; align-items: center; justify-content: flex-start;">
                    <span class="icon-inline right link search" @click="SearchOrdersBy({phone: parcel.shipping_address.phone_number})" :title="$t('Search in orders')" />
                    <span v-if="parcel.shipping_address.phone_number" v-html="Hyperlink({scheme: 'tel', href: parcel.shipping_address.phone_number, country: parcel.shipping_address.country, text: parcel.shipping_address.phone_number})" />
                    <span v-if="parcel.customer['shipping_address.tlf']" class="icon-inline left link user" @click="SearchCustomersBy({phone: parcel.shipping_address.phone_number})" :title="$t('Open customer')" />
                  </p>
                  <p class="company" v-if="parcel.shipping_address.company" style="display: flex; flex-direction: row-reverse; align-items: center; justify-content: flex-start;">
                    <span class="icon-inline right link search" @click="SearchOrdersBy({company: parcel.shipping_address.company})" :title="$t('Search in orders')" />
                    <span v-html="Hyperlink({scheme: 'google', target: '_blank', href: parcel.shipping_address.company})" />
                  </p>
                  <p class="address" v-if="parcel.shipping_address.street_address || parcel.shipping_address.zip_code || parcel.shipping_address.city" style="display: flex; flex-direction: row-reverse; align-items: center; justify-content: flex-start;">
                    <span class="icon-inline right link search" @click="SearchOrdersBy({address: String(parcel.shipping_address.street_address).replace(/\n?\s?pakkeshop:?.*$/i, '')})" :title="$t('Search in orders')" />
                    <span v-html="Hyperlink({scheme: 'map', target: '_blank', 
                      href: `${String(parcel.shipping_address.street_address).replace(/\n?\s?pakkeshop:?.*$/i, '')}${parcel.shipping_address.street_address && parcel.shipping_address.zip_code ? ', ' : ''}${parcel.shipping_address.zip_code} ${parcel.shipping_address.city}`.trim().replace(/^,$/, ''),
                      text: `${parcel.shipping_address.street_address}${parcel.shipping_address.street_address && parcel.shipping_address.zip_code ? ', ' : ''}${parcel.shipping_address.zip_code} ${parcel.shipping_address.city}`.trim().replace(/^,$/, '')
                    })" />
                  </p>
                  <div class="country" style="margin-top: 5px; display: flex; align-items: center; justify-content: flex-end;">
                    <Flag :code="iso[parcel.shipping_address.country]" size="small" />
                    <div v-html="Hyperlink({scheme: 'map', target: '_blank', href: (parcel.shipping_address.state_province ? parcel.shipping_address.state_province + ', ' : '') + CountryName(iso[parcel.shipping_address.country])})" />
                  </div>
                </div>
                <div class="shipping-address-editor" v-if="modal.view_parcel.tab.shipping.editing">
                  <div class="form" id="shipping-address-form">
                    <div class="form-group" :key="index" v-for="(group, index) in parcel.shipping_address_form">
                      <label :class="['label', {fetching: field.fetching}]" :key="key" v-for="(field, key) in group">
                        <select v-if="field.type == 'select'" :name="field.name" :class="[{invalid: field.invalid, caution: field.caution}]" :style="[{color: field.readonly && '#a8abae', cursor: field.readonly && 'default'}]" :readonly="field.readonly" :required="field.required" v-model="field.value" @input="field.input" @keydown="field.keydown" @change="field.change" @blur="field.blur">
                          <option :key="index" v-for="(option, index) in field.options" :value="option.label">{{ option.label }}</option>
                        </select>
                        <input v-else type="search" :name="field.name" :class="[{invalid: field.invalid, caution: field.caution}]" :style="[{color: field.readonly && '#a8abae', cursor: field.readonly && 'default'}]" :readonly="field.readonly" :required="field.required" :list="field.list && key + '_list'" v-model="field.value" @input="field.input" @keydown="field.keydown" @change="field.change" @blur="field.blur">
                        <span class="label-text">{{ $t(field.label) }}</span>
                        <datalist v-if="field.list" :id="key + '_list'">
                          <option :key="index" v-for="(option, index) in field.list" :data-value="option.label">
                            {{ /country/.test(key) ? CountryName(option.code) : option.label || option }}
                          </option>
                        </datalist>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="shipping-address-edit" v-if ="modal.view_parcel.tab.shipping.changeable">
                  <div class="truncate" style="display: flex; align-items: center; margin-right: auto;" v-if="modal.view_parcel.tab.shipping.editing">
                    <span :class="['agent-icon', parcel.shipping_courier]" :title="couriers[parcel.shipping_courier]" />
                    <p>{{ parcel.shipping_description }}</p>
                  </div>
                  <a class="button btn-stateless" style="min-width: 115px;" @click.prevent="OpenShippingAddressEditor" href="" v-if="!modal.view_parcel.tab.shipping.editing">{{ $t('Edit') }}</a>
                  <a class="button btn-success" style="min-width: 115px;" @click.prevent="SubmitEditShippingAddress" href="" v-if="modal.view_parcel.tab.shipping.editing">{{ $t('Save') }}</a>
                  <a class="button btn-danger" style="min-width: 115px;" @click.prevent="modal.view_parcel.tab.shipping.editing = false" href="" v-if="modal.view_parcel.tab.shipping.editing">{{ $t('Cancel') }}</a>
                </div>
                <div class="shipping-method">
                  <p class="title">{{ $t('Shipping method') }}</p>
                  <v-select v-if="modal.view_parcel.tab.shipping.changeable" class="parcel-shipping-select" name="shipping" @input="SetShippingMethod" :value="modal.view_parcel.tab.shipping.selected" v-model="modal.view_parcel.tab.shipping.selected" :options="modal.view_parcel.tab.shipping.options" :clearable="false">
                    <template v-slot:selected-option="option">
                      <div class="truncate">
                        <span :class="['courier', option.agent]">
                          {{ option.label }}
                        </span>
                      </div>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="truncate">
                        <span :class="['courier', option.agent]">
                          {{ option.label }}
                        </span>
                      </div>
                    </template>
                  </v-select>
                  <span v-else class="trackingnumber" style="display: inline-flex;">
                    <span class="shipping">
                      <span :class="['courier-icon', parcel.agent_code]" :title="couriers[parcel.agent_code]" />
                      <span class="shipping-method" style="margin: 0;">{{ parcel.shipping_description }}</span>
                    </span>
                  </span>
                </div>
              </div>
            </li>

            <li class="modal-tabs__body-content padding" v-if="modal.view_parcel.tab.active == 'order'">
              <table class="table order-items odd-even">
                <thead class="order-items__head">
                  <th>{{ $t('Product') }}</th>
                  <th></th>
                  <th>{{ $t('Weight') }}</th>
                  <th>{{ $t('Price') }}</th>
                  <th>{{ $t('Qty') }}</th>
                  <th>{{ $t('Total') }}</th>
                </thead>
                <tbody class="order-items__body">
                  <tr :class="{virtual: item.is_virtual, parent: item.is_parent, child: item.is_child}" :key="item.id" v-for="(item, index) in parcel.ordered_items">
                    <td>
                      <div class="product">
                        <span class="product-image" :style="{'--product-img-url': item.img_url && 'url(' + item.img_url + ')'}" @click="ProductImage" />
                        <span class="product-description">
                          <div v-if="item.product_link">
                            <span v-if="item.product_link.length == 1" v-html="Hyperlink({href: item.product_link[0].link, target: '_blank', text: item.product, title: item.product_link[0].name})" />
                            <p v-else-if="item.product_link.length > 1" class="hyperlink select" href @mousemove="Object.assign((($event.target.querySelector('.options') || {}).style || {}), {left: $event.layerX + 'px'})">
                              {{ item.product }}
                              <ul class="options">
                                <li :key="index" v-for="(link, index) in item.product_link">
                                  <a :href="link.link" target="_blank" style="white-space: nowrap;" draggable="false">
                                    <span :class="['icon', ProductLinkLogoClassName(link.name)]" style="margin-right: 10px;" />
                                    {{ link.name }}
                                  </a>
                                </li>
                              </ul>
                            </p>
                            <p v-else class="text">{{ item.product }}</p>
                          </div>
                          <p v-else class="text">{{ item.product }}</p>
                          <div class="extra">
                            <p class="barcode" v-if="item.barcodes.length" @mouseover="qr_barcode_index = index"><b>EAN:</b> <span class="ean-number" v-for="(barcode, index) in item.barcodes" :key="index" @mouseover="QRCodePopup(barcode), qr_barcode_index = index" @mouseleave="QRCodePopup('')">{{ barcode }}</span></p>
                            <p class="sku" v-if="item.sku"><b>SKU:</b> <span>{{ item.sku }}</span></p>
                            <p class="item-tariff flex-row" style="margin-top: 0px;">
                              <b>{{ $t('Tariff') }}:</b>
                              <span class="label pre">
                                <span class="tariff-input" @keydown="HandleOrderItemTariff" @blur="HandleOrderItemTariff" :contentEditable="parcel.tariff_changeable" :data-item="item.id">
                                  {{ item.tarif }}
                                </span>
                              </span>
                            </p>
                            <p class="item-country flex-row" style="margin-top: 0px;">
                              <b>{{ $t('Country') }}:</b>
                              <span class="label pre">
                                <span class="country-input" :data-value="item.country ? CountryName(item.country.code) : ''">
                                  <input @input="HandleOrderItemOriginCountry($event, item)" @blur="HandleOrderItemOriginCountry($event, item)" @keydown.space="$event.preventDefault" list="origin_country" :value="item.country ? CountryName(item.country.code) : ''" @keydown.enter="$event.target.blur()" :data-item="item.id" :readonly="!parcel.country_changeable" spellcheck="false">
                                </span>
                                <datalist id="origin_country">
                                  <option :key="index" v-for="(option, index) in country.options" :data-id="option.id" :data-code="option.code">
                                    {{ CountryName(option.code) }}
                                  </option>
                                </datalist>
                              </span>
                            </p>
                            <div class="qr-popup" v-if="qr_barcode !== '' && qr_barcode_index == index">
                              <div class="qr-popup-container">
                                <qrcode :value="qr_barcode"></qrcode>
                                <p> {{ qr_barcode }}</p>
                              </div>
                           </div>
                            <div class="unique-identifiers" style="margin-top: 0px;" v-if="item.product_unique.length > 1">
                              <label for="serials" :placeholder="'S/N' + (item.product_unique.length ? ' (' + item.product_unique.length + ')' : '') + ': ' + item.product_unique[0].identifier">
                                <span class="label pre"> - {{ TitleCase(item.product_unique[0].created_by) + ' - ' + DateFormat(item.product_unique[0].created_at) }}</span>
                                <input id="serials" list="uid" @keydown="HandleDatalist" @input="HandleDatalist">
                                <datalist id="uid">
                                  <option :value="serial.identifier" v-for="(serial, index) in item.product_unique" :key="index">
                                    {{ browser.firefox ? serial.identifier + ' - ' : '' }} {{ TitleCase(serial.created_by) }} - {{ DateFormat(serial.created_at) }}
                                  </option>
                                </datalist>
                              </label>
                              <span class="copied">{{ $t('Copied to clipboard') }}</span>
                            </div>
                            <p v-else-if="item.product_unique.length"><b>S/N:</b> <b>{{ item.product_unique[0].identifier }}</b> - {{ TitleCase(item.product_unique[0].created_by) }} - {{ DateFormat(item.product_unique[0].created_at) }}</p>
                          </div>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div class="pick-error-container" v-if="item.pick_error">
                        <div class="pick-error" title="pick error">
                        </div>
                        <p>Pick error</p>
                      </div>
                    </td>
                    <td>
                      <div class="item-weight">
                        <div class="label pre">
                          <div class="weight-input" @keydown="HandleOrderItemWeight" @blur="HandleOrderItemWeight" :contentEditable="parcel.weight_changeable" data-unit="kg" :data-item="item.id">
                            {{ CurrencyFormat(item.weight || '0.0000', 4) }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td :style="{textDecoration: item.is_child && 'line-through'}">
                      <p class="old-price" v-if="item.original_price != item.price">{{ item.currency_iso }} {{ CurrencyFormat(item.original_price || '0.0000', 4) }}</p>
                      <p class="final-price">{{ item.currency_iso }} {{ CurrencyFormat(item.price || '0.0000', 4) }}</p>
                    </td>
                    <td>
                      {{ item.qty }}
                    </td>
                    <td :style="{textDecoration: item.is_child && 'line-through'}">{{ item.currency_iso }} {{ CurrencyFormat(item.row_total_incl_tax || '0.0000', 4) }}</td>
                    <div class="ribbon top-right" v-if="item.is_virtual">
                      <span class="ribbon-text">{{ $t('Virtual') }}</span>
                    </div>
                  </tr>
                  <tr class="order-items__totals">
                    <td>
                      <!--
                      <div style="display: flex;">
                        <label class="label v-checkbox-label" style="flex-direction: row-reverse; align-items: center; cursor: pointer;">
                          <div class="label-text">{{ $t('Bulk invoicing') }}</div>
                          <div class="v-wrapper checkbox" style="margin-right: 10px;">
                            <input type="checkbox" class="v-checkbox" :checked="!parcel.skip_product_for_bulk_invoicing" @change="ToggleBulkInvoicing">
                            <span class="v-checkbox-toggle" style="width: 24px; height: 24px;" />
                          </div>
                        </label>
                      </div>
                      -->
                    </td>
                    <td></td>
                    <td></td>
                    <td colspan="2" class="price-title">
                      <p class="total-weight">{{ $t('Total weight') }}</p>
                      <p class="sub-total">{{ $t('Subtotal') }}</p>
                      <p class="custom-fee">{{ $t('Packaging fee') }}</p>
                      <p class="shipping-handling">{{ $t('Shipping & handling') }}</p>
                      <div class="discount" v-if="parcel.discount < 0">
                        <p style="height: 18px;">{{ $t('Discount') }}</p>
                        <div v-show="parcel.discount_description" style="height: 18px; font-weight: 600;" :key="description" v-for="description in parcel.discount_description.split(',')">
                          <small>&bull;</small>
                        </div>
                      </div>
                      <ul class="gift-cards" v-if="parcel.gift_cards">
                        <li v-for="gift_card in parcel.gift_cards" :key="gift_card.code">
                          <p>{{ $t('Gift card') }}: {{ gift_card.code }}</p>
                        </li>
                        <li v-if="parcel.gift_cards.length > 1">
                          <p>{{ $t('Gift card total') }}:</p>
                        </li>
                      </ul>
                      <p class="grand-total">{{ $t('Grand total') }}</p>
                      <p class="tax">{{ $t('VAT') }}</p>
                      <div class="bulk-invoicing" style="margin-top: 10px;">
                        <label class="label v-checkbox-label" style="flex-direction: row-reverse; align-items: center; cursor: pointer;">
                          <div class="v-wrapper checkbox">
                            <input type="checkbox" class="v-checkbox" :checked="!parcel.skip_product_for_bulk_invoicing" @change="ToggleBulkInvoicing">
                            <span class="v-checkbox-toggle" style="width: 24px; height: 24px;" />
                          </div>
                        </label>
                      </div>
                    </td>
                    <td>
                      <div class="total-weight">
                        <div class="label pre">
                          <div id="total-order-weight" class="weight-input" @keydown="HandleOrderTotalWeight" @blur="HandleOrderTotalWeight" :contentEditable="parcel.weight_changeable" data-unit="kg">
                            {{ CurrencyFormat(parcel.weight || '0.0000', 4) }}
                          </div>
                        </div>
                      </div>
                      <p class="sub-total">{{ parcel.currency_iso }} {{ CurrencyFormat(parcel.subtotal || '0.0000', 4) }}</p>
                      <p class="custom-fee">{{ parcel.currency_iso }} {{ CurrencyFormat(parcel.custom_fee || '0.0000', 4) }}</p>
                      <p class="shipping-handling">{{ parcel.currency_iso }} {{ CurrencyFormat(parcel.shipping_handling || '0.0000', 4) }}</p>
                      <div class="discount" v-if="parcel.discount < 0" style="max-width: 200px;">
                        <p style="height: 18px;">{{ parcel.currency_iso }} {{ CurrencyFormat(parcel.discount || '0.0000', 4) }}</p>
                        <div class="truncate" v-show="parcel.discount_description" style="height: 18px; font-weight: 600;" :key="description" v-for="description in parcel.discount_description.split(',').sort((a, b) => b.length - a.length)" @mouseenter="(e) => {if (IsTruncated(e.target)) e.target.title = description}">
                          <small>{{ description }}</small>
                        </div>
                      </div>
                      <ul class="gift-cards" v-if="parcel.gift_cards">
                        <li v-for="gift_card in parcel.gift_cards" :key="gift_card.code">
                          <p>{{ parcel.currency_iso }} {{ CurrencyFormat(-gift_card.amount || '0.0000', 4) }}</p>
                        </li>
                        <li v-if="parcel.gift_cards.length > 1">
                          <p>{{ parcel.currency_iso }} {{ CurrencyFormat(parcel.gift_cards.reduce((a, b) => a - b.amount, 0.0000) || '0.0000', 4) }}</p>
                        </li>
                      </ul>
                      <p class="grand-total">{{ parcel.currency_iso }} {{ CurrencyFormat(parcel.grand_total || '0.0000', 4) }}</p>
                      <p class="tax">{{ parcel.currency_iso }} {{ CurrencyFormat(parcel.tax || '0.0000', 4) }}</p>
                      <p class="bulk-invoicing" style="margin-top: 13px;">{{ $t('Bulk invoicing') }}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </li>

            <li class="modal-tabs__body-content padding" v-if="modal.view_parcel.tab.active == 'campaign' && parcel.campaign.length">
              <div class="tab-content__wrapper full-height">
                <div class="list-view" style="width: 100%; padding: 0;">
                  <div class="list-wrapper">
                    <div class="list-head">
                      <ul class="list-label">
                        <li class="list-row">
                          <div class="list-col">{{ $t('Campaign ID') }}</div>
                          <div class="list-col">{{ $t('Campaign name') }}</div>
                          <div class="list-col">{{ $t('Processed') }}</div>
                          <div class="list-col">{{ $t('Created') }}</div>
                        </li>
                      </ul>
                    </div>
                    <div class="list-body">
                      <ul class="list-content odd-even">
                        <li class="list-row" :key="index" v-for="(campaign, index) in parcel.campaign">
                          <div class="list-col">{{ campaign.id }}</div>
                          <div class="list-col">{{ campaign.name }}</div>
                          <div class="list-col">
                            <span class="state" :class="[campaign.processed ? 'positive' : 'negative']">
                              {{ $t(campaign.processed ? 'Yes' : 'No') }}
                            </span>
                          </div>
                          <div class="list-col date">{{ DateFormat(campaign.create_at) }}</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            
            <li class="modal-tabs__body-content padding" v-if="modal.view_parcel.tab.active == 'lock' && modal.view_parcel.locked">
              <div class="tab-content__wrapper">
                <h5 class="tab-content__wrapper--heading">{{ $t('Created by') }}:</h5>
                <p>{{ modal.view_parcel.lock.res_user_full_name }}</p>
              </div>
              <div class="tab-content__wrapper">
                <h5 class="tab-content__wrapper--heading">{{ $t('Lock note') }}:</h5>
                <p class="pre-wrap">{{ modal.view_parcel.lock.note }}</p>
              </div>
            </li>

            <li class="modal-tabs__body-content padding" v-if="modal.view_parcel.tab.active == 'note' && parcel.has_customer_note">
              <div class="tab-content__wrapper">
                <h5 class="tab-content__wrapper--heading">{{ $t('Customer note') }}:</h5>
                <p class="pre-wrap">{{ parcel.customer_note }}</p>
              </div>
            </li>

            <li class="modal-tabs__body-content padding" v-if="modal.view_parcel.tab.active == 'picking' && parcel.picked_by.length">
              <table class="table pick-items odd-even">
                <thead class="pick-items__head">
                  <th>{{ $t('ID') }}</th>
                  <th>{{ $t('Name') }}</th>
                  <th>{{ $t('Wagon number') }}</th>
                  <th>{{ $t('Wagon position') }}</th>
                </thead>
                <tbody class="pick-items__body">
                  <tr :key="item.id" v-for="item in parcel.picked_by">
                    <td>{{ item.order_pick_list_id }}</td>
                    <td>{{ NameCase(item.by) }}</td>
                    <td>{{ item.wagon_number }}</td>
                    <td>{{ item.wagon_pos }}</td>
                  </tr>
                </tbody>
              </table>
            </li>

            <li class="modal-tabs__body-content padding" v-if="modal.view_parcel.tab.active == 'invoicing' && !(!parcel.invoice_parcel_id && !parcel.invoice_parcel_additional_ids.length)">
              <div class="tab-content__wrapper">
                <h5 class="tab-content__wrapper--heading">{{ $t('Current bulk invoice ID') }}:</h5>
                <div v-if="parcel.invoice_parcel_id" v-html="Hyperlink({scheme: 'internal', href: 'invoices', text: parcel.invoice_parcel_id, target: '_blank'})" @click="CopyInvoiceListID(parcel.invoice_parcel_id)" />
              </div>
              <div class="tab-content__wrapper">
                <h5 class="tab-content__wrapper--heading">{{ $t('Previous bulk invoice IDs') }}:</h5>
                <div style="display: flex;">
                  <span style="display: flex;" v-for="(invoice_parcel_additional_id, index) in parcel.invoice_parcel_additional_ids" :key="index">
                    <div v-html="Hyperlink({scheme: 'internal', href: 'invoices', text: invoice_parcel_additional_id, target: '_blank'})" @click="CopyInvoiceListID(invoice_parcel_additional_id)" />
                    <span v-if="index + 1 < parcel.invoice_parcel_additional_ids.length">,&nbsp;</span>
                  </span>
                </div>
              </div>
              <div class="tab-content__wrapper" style="padding: 20px 10px 10px;">
                <button class="button" :disabled="!parcel.invoice_parcel_id" @click.prevent="EnqueueOrderForInvoicing(parcel.id)">{{ $t('Enqueue order for bulk invoicing') }}</button>
              </div>
            </li>

            <li class="modal-tabs__body-content padding" v-if="modal.view_parcel.tab.active == 'videos' && modal.view_parcel.tab.videos.loaded">
              <table class="table video-items odd-even">
                <thead class="pick-items__head">
                  <th>{{ $t('Video') }} ID</th>
                  <th>{{ $t('Date') }}</th>
                  <th>{{ $t('Start') }}</th>
                  <th>{{ $t('End') }}</th>
                  <th>{{ $t('Duration') }}</th>
                  <th>{{ $t('Size') }}</th>
                  <th>{{ $t('Packer') }}</th>
                  <th>{{ $t('Station') }}</th>
                  <th class="edit" />
                </thead>
                <tbody class="pick-items__body">
                  <tr :class="['clickable', {selected: item.id == video.id}]" :style="{textDecoration: item.is_video_removed && 'line-through'}" @mousedown="VideoItem($event, item)" @mousemove="VideoItem($event, item)" @mouseup="VideoItem($event, item)" :key="item.id" v-for="item in parcel.video_list">
                    <td>{{ item.id }}</td>
                    <td>{{ DateOnly(item.end_at) || '-' }}</td>
                    <td>{{ TimeOnly(item.start_at) || '-' }}</td>
                    <td>{{ TimeOnly(item.end_at) || '-' }}</td>
                    <td>{{ item.duration ? TimeFormat(item.duration, false, false) : '-' }}</td>
                    <td>{{ BytesToSize(item.bytes).size ? (NumberFormat(BytesToSize(item.bytes).size) + ' ' + BytesToSize(item.bytes).unit) : '-' }}</td>
                    <td>{{ NameCase(item.pack.res_user_full_name) || '-' }}</td>
                    <td style="text-transform: uppercase;">{{ item.pack.pos || '-' }}</td>
                    <td @mouseenter="SetBackground" @mouseleave="SetBackground" class="actions">
                      <a @click.prevent="" href="" class="icon dots">
                        <ul class="item-actions">
                          <li>
                            <a @click.prevent="PlayOrderVideo(item)" href="" class="success">
                              {{ $t('Play') }}
                            </a>
                          </li>
                          <li>
                            <a @click.prevent="GetOrderVideo(item, true)" href="" class="success">
                              {{ $t('Download') }}
                            </a>
                          </li>
                        </ul>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </li>

            <li class="modal-tabs__body-content padding" v-if="modal.view_parcel.tab.active == 'tracking' && parcel.pack_log.length">
              <div class="list-wrapper tracking-history">
                <div class="list-head">
                  <ul class="list-label">
                    <li class="list-row">
                      <div class="list-col">{{ $t('Delivery company') }}</div>
                      <div class="list-col">{{ $t('Tracking number') }}</div>
                      <div class="list-col">{{ $t('Created') }}</div>
                      <div class="list-col">{{ $t('Created by') }}</div>
                      <!-- <div class="list-col max-width">{{ $t('User') }}</div> -->
                      <div class="list-col max-width">{{ $t('Station') }}</div>
                      <div class="list-col max-width">{{ $t('Type') }}</div>
                      <div class="list-col max-width" style="max-width: 90px;">{{ $t('Video') }}</div>
                      <div class="list-col" v-if="parcel.shipment_list_ids">{{ $t('Collect list') }}</div>
                      <div class="list-col max-width actions" v-if="parcel.cancellable" />
                    </li>
                  </ul>
                </div>
                <div class="list-body" style="overflow-y: unset;">
                  <ul class="list-content odd-even">
                    <li :class="['list-row', {cancelled: item.cancelled, return: item.type == 'return'}]" :data-datetime="(new Date(item.date)).getTime()" :key="index" v-for="(item, index) in parcel.pack_log">
                      <div class="list-col">
                        <a v-if="item.tnt != 'shop'" :class="['courier-icon', item.agent_code, {loading: item.loading}]" :href="TrackingURL({agent: item.agent_code, code: item.tnt, zip_code: parcel.shipping_address.zip_code, lang: parcel.language.code,})" target="_blank" />
                        <span v-else :class="['courier-icon', item.agent_code]" />
                        {{ (item.tnt == 'shop' ? (parcel.shipping_address.company) : couriers[item.agent_code]) || $t(Capitalize(item.tnt)) }}
                      </div>
                      <div class="list-col" v-html="item.tnt != 'shop' ? Hyperlink({target: '_blank', 
                        href: TrackingURL({lang: parcel.language.code, agent: item.agent_code, code: item.tnt, tracking: true, company: parcel.company_code }), 
                        text: item.tnt == 'shop' ? parcel.shipping_address.street_address : item.tnt
                      }) : parcel.shipping_address.street_address || '-'" />
                      <div class="list-col date">{{ DateFormat(item.date) || '' }}</div>
                      <div class="list-col">{{ item.res_user_full_name || '' }}</div>
                      <!-- <div class="list-col max-width">{{ item.res_user_id || '' }}</div> -->
                      <div class="list-col max-width">{{ item.pos || '' }}</div>
                      <div class="list-col max-width">{{ $t(!/shop/i.test(item.tnt) ? (/return/i.test(item.type) ? 'Return' : 'Shipping') + ' label' : 'Pick-up')}}</div>
                      <div class="list-col max-width" style="max-width: 90px;">
                        <span :class="['list-icon big circle play-circle', item.video && 'enabled']" @click.prevent="PlayOrderVideo(item.video)" :title="item.video && $t('Play')" />
                      </div>
                      <div class="list-col" v-if="parcel.shipment_list_ids">
                        <div :class="['collect-list-id', {external: parcel.shipment_list_lookup && IsMainCompany()}]" @mouseenter="ShowCollectListData($event, parcel.shipment_list_lookup && item)" @mouseleave="ShowCollectListData($event, parcel.shipment_list_lookup && item)">
                          <div v-if="parcel.shipment_list_lookup && IsMainCompany()">
                            <div v-html="Hyperlink({scheme: 'internal', href: 'collect', text: item.shipment_list_id, target: '_blank'})" @click="CopyCollectListID(item.shipment_list_id)" />
                            <div class="collect-list-data">
                              <span>{{ DateFormat(item.shipment_list_data.create_date) }}</span>
                            </div>
                          </div>
                          <div v-else>{{ item.shipment_list_id || '' }}</div>
                        </div>
                      </div>
                      <div class="list-col" v-else-if="parcel.shipment_list_id" />
                      <div :class="['list-col max-width actions', {icon: item.cancellable && !item.cancelled}]" v-if="parcel.cancellable">
                        <ul>
                          <li class="" @mousedown="CancelLabel(item)" @mouseenter="$event.target.style.boxShadow = 'inset 0 0 0 4px rgba(40 128 214, 0.5)'">
                            <div>{{ $t('Cancel') }}</div>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>

            <li class="modal-tabs__body-content padding" v-if="modal.view_parcel.tab.active == 'status' && modal.view_parcel.tab.status.loaded">
              <div class="tab-content__wrapper">
                <div class="message-history">
                  <div class="message-history-input">
                    <input class="message-history-input--input" @input="UpdateOrderCmsStatusMessage" @blur="Trim" @keydown.enter="$event.target.blur()" :placeholder="$t('Write status comment')" spellcheck="false">
                    <button class="message-history-input--button" @click="SubmitOrderCmsStatusMessage(parcel.id)">{{ $t('Submit') }}</button>
                    <span class="message-history-input--outline"></span>
                  </div>
                  <ul class="message-history-list">
                    <li class="message-history-list__item" :data-float="item.cms ? 'left' : 'right'" :data-status="item.status" :key="index" v-for="(item, index) in modal.view_parcel.tab.status.history">
                      <div class="message-history-list__item--date">{{ DateFormat(item.created_at) }}</div>
                      <div class="message-history-list__item--bubble" :class="{'user-comment': !/cms|packship/i.test(item.created_by)}">
                        <div class="bubble-message comment pre-wrap">
                          <div class="bubble-message status capitalize" v-if="item.status"><b><i>{{ $t(Capitalize(item.status)) + (/ing/i.test(item.status) ? '...' : '') }}</i></b></div>
                          <b class="from" v-if="item.created_by">{{ item.created_by }}<br></b>
                          <span v-if="IsValidURL(item.comment)" v-html="Hyperlink({href: item.comment, text: item.comment.toLowerCase(), target: '_blank', style: 'color: #ffffff'})" />
                          <span v-else>{{ item.comment }}</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>

            <li class="modal-tabs__body-content padding" v-if="modal.view_parcel.tab.active == 'comments' && modal.view_parcel.tab.comments.loaded">
              <form @submit.prevent novalidate>
                <div class="tab-content__wrapper order-comments">
                  <div class="message-history">
                    <div class="message-history-input" style="height: 67px;">
                      <input list="comment-categories" class="message-history-input--input" v-model="modal.view_parcel.tab.comments.category" @change="FilterOrderComments" @keydown="OrderCommentsDataList" :placeholder="$t('Filter by category')" spellcheck="false" multiple type="email">
                      <datalist id="comment-categories">
                        <option :value="label" :key="value" v-for="(label, value) in modal.view_parcel.tab.comments.categories" />
                      </datalist>
                      <span class="message-history-input--outline"></span>
                    </div>

                    <ul class="message-history-list">
                      <li class="message-history-list__item" :data-float="item.side" :key="index" v-for="(item, index) in modal.view_parcel.tab.comments.list">
                        <div class="message-history-list__item--date">{{ DateFormat(item.date) }}</div>
                        <div class="message-history-list__item--bubble" :data-side="item.side" :data-type="item.type" :class="{'user-comment': item.side == 'right'}">
                          <div class="message-category-icon" :data-type="item.type" :title="CommentCategory({label: item.type})" :class="{'user-comment': item.side == 'right'}" />
                          <div class="bubble-message comment pre-wrap">
                            <b class="from" v-if="item.name">{{ item.name }}</b>
                            <span v-if="IsValidURL(item.text)" v-html="Hyperlink({href: item.text, text: item.text.toLowerCase(), target: '_blank', style: 'color: #ffffff'})" />
                            <span v-else>{{ item.text }}</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </form>
            </li>

          </ul>
        </div>
        <div class="order-actions">
          <a :class="['button center-text', modal.view_parcel.locked ? 'unlock' : 'lock', 'icon']" @click.prevent="ToggleOrderLock(parcel, true)" href="">
            {{ $t(modal.view_parcel.locked ? 'Unlock' : 'Lock') }}
          </a>
          <!-- <a :class="['button green', {disabled: parcel.customer_exists}]" @click.prevent="CreateNewCustomer(parcel)" href="">{{ $t('New customer') }}</a> -->
          <a class="button green" @click.prevent="CopyOrderURL(parcel)" href="">{{ $t('Copy URL') }}</a>
          <a class="button green" @click.prevent="OpenInCMS(parcel)" href="">{{ $t('Open CMS') }}</a>
          <a class="button green" @click.prevent="OpenPDF(parcel.id)" href="">{{ $t('Open PDF') }}</a>
          <a class="button" @click.prevent="BypassUsedInPacking(parcel.id)">Bypass order</a>
          <a class="button action-toggle" @click.prevent="$event.target.classList.toggle('visible')" href="">
            {{ $t('Download') }}
            <ul class="button-actions">
              <li>
                <a @click.prevent="DownloadParcelOrder(parcel), $event.target.closest('.action-toggle').classList.remove('visible')" href="" class="success">{{ $t('Parcel order') }}</a>
              </li>
              <li>
                <a @click.prevent="DownloadShippingLabel(parcel), $event.target.closest('.action-toggle').classList.remove('visible')" href="">{{ $t('Shipping label') }}</a>
              </li>
              <li>
                <a @click.prevent="DownloadReturnLabel(parcel), $event.target.closest('.action-toggle').classList.remove('visible')" href="">{{ $t('Return label') }}</a>
              </li>
            </ul>
            <span class="button-icon caret-up" />
          </a>
          <a class="button action-toggle" @click.prevent="$event.target.classList.toggle('visible')" href="">
            {{ $t('Print') }}
            <ul class="button-actions">
              <li>
                <a @click.prevent="PrintOrder(parcel.id), $event.target.closest('.action-toggle').classList.remove('visible')" href="" class="success">{{ $t('Parcel order') }}</a>
              </li>
              <li>
                <a @click.prevent="PrintShippingLabel(parcel, true), $event.target.closest('.action-toggle').classList.remove('visible')" href="">{{ $t('Shipping label') }}</a>
              </li>
              <li>
                <a @click.prevent="PrintReturnLabel(parcel), $event.target.closest('.action-toggle').classList.remove('visible')" href="">{{ $t('Return label') }}</a>
              </li>
            </ul>
            <span class="button-icon caret-up" />
          </a>
          <a class="button action-toggle" @click.prevent="$event.target.classList.toggle('visible')" href="">
            {{ $t('Create') }}
            <ul class="button-actions">
              <li>
                <a @click.prevent="CreateNewCustomer(parcel), $event.target.closest('.action-toggle').classList.remove('visible')" href="">{{ $t('New customer') }}</a>
              </li>
              <li>
                <a class="actions-toggle" @click.prevent="" href="">
                  {{ $t('Shipment') }}
                  <u class="button-actions">
                    <li>
                      <a @click.prevent="CreateCustomShippingLabel(parcel), $event.target.closest('.action-toggle').classList.remove('visible')" href="">{{ $t('Shipping label') }}</a>
                    </li>
                    <li>
                      <a @click.prevent="CreateCustomReturnLabel(parcel), $event.target.closest('.action-toggle').classList.remove('visible')" href="">{{ $t('Return label') }}</a>
                    </li>
                  </u>
                </a>
              </li>
            </ul>
            <span class="button-icon caret-up" />
          </a>
          <a class="button action-toggle right" @click.prevent="$event.target.classList.toggle('visible')" href="">
            {{ $t('Send') }}
            <ul class="button-actions">
              <li>
                <a @click.prevent="SendReturnLabel(parcel), $event.target.closest('.action-toggle').classList.remove('visible')" href="">{{ $t('Return label') }}</a>
              </li>
            </ul>
            <span class="button-icon caret-up" />
          </a>
        </div>
        <div class="loading" v-if="modal.view_parcel.loading">
          <div class="loading-element">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </Modal>

    <transition name="fade">
      <div @mousedown="PopupClickAround" @mouseup="PopupClickAround" class="validate-popup" v-if="popup.return_label.show">
        <div class="validate-popup__content">
          <div class="titles">
            <p class="title">Are you sure?</p>
            <p class="subtitle" v-html="popup.return_label.message"></p>
            <div class="email-form" style="margin-bottom: 30px;" v-if="popup.return_label.action == 'send'">
              <form ref="email_form" @submit.prevent>
                <button ref="email_form_submit" type="submit" hidden />
                <div class="flex-column">
                  <div class="label required" style="width: 100%;">
                    <!-- <span class="label-text">{{ $t('Email') }}</span> -->
                    <input class="v-input" name="email" type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" v-model="popup.return_label.email_address" :placeholder="$t('Email')" required>
                  </div>
                </div>
              </form>
            </div>
            <div class="select-dropdown" style="margin-bottom: 30px;">
              <v-select v-model="popup.return_label.courier_selected" @input="PopupCourierSelect" :value="popup.return_label.courier_value" :options="popup.return_label.courier_options" :clearable="false">
                <template v-slot:selected-option="option">
                  <span :class="['courier', option.code]">
                    {{ option.label }}
                  </span>
                </template>
                <template slot="option" slot-scope="option">
                  <span :class="['courier', option.code]">
                    {{ option.label }}
                  </span>
                </template>
              </v-select>
            </div>
          </div>
          <div class="actions">
            <a class="btn-stateless cancel" @click.prevent="" href="">{{ popup.return_label.button.cancel.text }}</a>
            <a class="btn-success confirm" @click.prevent="() => {if (popup.return_label.action == 'send') { $refs.email_form.onsubmit = PopupConfirm; $refs.email_form_submit.click()} else { PopupConfirm() }}" href="">{{ popup.return_label.button.confirm.text + popup.return_label.action + ' it' }}</a>
          </div>
        </div>
      </div>
    </transition>

    <!--
    <transition name="fade">
      <div @pointerdown="dialog.event" @pointerup="dialog.event" class="validate-popup" v-if="dialog.show">
        <div class="validate-popup__content">
          <div class="titles">
            <p class="title">
              {{ 
                /custom_label/.test(dialog.mode) ? `Custom ${dialog.mode.split('.')[1]} label` : ''
              }}
            </p>
            <p class="subtitle"></p>
            <div class="dialog-form" style="margin-bottom: 15px;">
              <form ref="dialog_form" @submit.prevent>
                <button ref="dialog_submit" type="submit" hidden />
                <div class="flex-column">
                  <div style="margin-bottom: 15px;">
                    <div class="label required">
                      <span class="label-text">
                        {{ 
                          /custom_label/.test(dialog.mode) ? 'Shipping method' : ''
                        }}
                       </span>
                      <input v-if="dialog.mode == 'setup'" class="v-input" type="number" name="scale" required v-model="editor.data.scale" @keydown.enter="$event.target.blur(), dialog.confirm()" step="0.01" min="0.01" />
                      <input v-if="dialog.mode == 'publish'" class="v-input" type="text" name="title" required v-model="dialog.data.title" @keydown.enter="$event.target.blur(), dialog.confirm()" spellcheck="false" />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="actions">
            <a class="btn-stateless cancel" @click.prevent="dialog.cancel" href="">Cancel</a>
            <a class="btn-success confirm" @click.prevent="dialog.confirm" href="">Okay</a>
          </div>
        </div>
      </div>
    </transition>
    -->

    <transition name="fade">
      <div class="validate-popup" v-if="bulk.action.popup.show">
        <div class="validate-popup__content">
          <div class="titles">
            <p class="title">Are you sure?</p>
            <!-- <p class="title">{{ $t(bulk.action.popup.title) }}</p> -->
            <p class="subtitle" v-html="bulk.action.popup.message" />
            <div class="select-dropdown" style="margin-bottom: 30px;">
              <div v-if="bulk.action.selected.code == 'change_status'">
                <div class="label">
                  <span class="label-text">Status</span>
                  <v-select ref="bulk_action_popup_select" @input="BulkActionPopupSelect" :options="bulk.action.popup.options" :clearable="false">
                    <template v-slot:selected-option="option">
                      <span :class="['state', option.code]">
                        {{ option.label }}
                      </span>
                    </template>
                    <template slot="option" slot-scope="option">
                      <span :class="['state', option.code]">
                        {{ option.label }}
                      </span>
                    </template>
                  </v-select>
                </div>
              </div>
              <div v-if="bulk.action.selected.code == 'send_message'">
                <div class="label" style="width: 300px;">
                  <span class="label-text">Template</span>
                  <v-select ref="bulk_action_popup_select" @input="BulkActionPopupSelect" :options="bulk.action.popup.options" :clearable="false" />
                </div>
                <div class="label" style="margin-top: 20px;">
                  <span class="label-text">Variable</span>
                  <v-select ref="bulk_action_popup_select_" @input="BulkActionPopupSelect" :options="bulk.action.popup.args" :clearable="false" />
                </div>
                <div class="label required" style="margin-top: 20px;">
                  <span class="label-text">Message</span>
                  <textarea ref="bulk_action_popup_textarea" class="v-textarea" style="max-height: 300px; resize: none;" @input="FitToContent" @blur="FormatText" required />
                </div>
                <div class="label grid-wrapper" style="margin-top: 20px;">
                  <span class="label-text">Sending at</span>
                  <div class="grid-info" style="margin: 0;">
                    <div class="grid-date" style="width: 100%;">
                      <div class="date-range flex-row">
                        <div class="input">
                          <VueCtkDateTimePicker id="bulk_action_popup_date" v-model="bulk.action.popup.date" label="" hint="" :locale="$i18n.locale.split('_')[0].replace(/no/, 'nb')" formatted="ddd, MMM D, YYYY, HH:mm" format="YYYY-MM-DD HH:mm:ss" :min-date="DateToISO(new Date())" :first-day-of-week="1" input-size="sm" :range="false" :no-shortcuts="true" :no-button="false" :auto-close="false" :noClearButton="true" />
                        </div>
                      </div>
                    </div>
                  </div>                  
                </div>
              </div>
            </div>
          </div>
          <div class="actions">
            <a class="btn-stateless cancel" @click.prevent="" href="">No</a>
            <a class="btn-success confirm" @click.prevent="" href="">Yes, do it</a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import { TableElementsBehaviour } from '@/mixins/TableElementsBehaviour';
  import { FormElementsBehaviour }  from '@/mixins/FormElementsBehaviour';
  import { Permissions }            from '@/helpers/Permissions';
  import { Config }                 from '@/helpers/Config';
  import { Tool }                   from '@/helpers/Tool';
  import { BPA }                    from '@/helpers/BPA';
  import Modal                      from '@/components/snippets/Modal';
  import Flag                       from '@/components/snippets/Flag';
  import HelpIcon from '@/components/snippets/HelpIcon';

  export default {
    name: 'ParcelsOrdersList',
    mixins: [
      TableElementsBehaviour, 
      FormElementsBehaviour,
      Permissions,
      Config,
      Tool,
      BPA
    ],
    components: {
      Modal,
      Flag,
      HelpIcon
    },
    data() {
      return {
        loading: true,
        dev: Config.dev,
        iso: {},
        courier: {
          options: [],
        },
        country: {
          options: []
        },
        language: {
          options: [],
          company: {
            options: {}
          }
        },
        couriers: {},
        companies: [],
        countries: [],
        entries: 0,
        cached: {},
        clickables: [],
        clipboard: null,
        parcels: [],
        parcel: {},
        video: {},
        count: {},
        refresh: {
          fetching: false,
          date: new Date(),
          done: {
            pct: 0,
          },
          time: {
            ms: 60000, // 5 min
          },
          timer: {}
        },
        page: {
          current: 1,
          number: 1,
          last: 1,
          size: 20,
          sizes: [10, 20, 30, 50, 100],
          order: {
            direction: 'desc',
            by: 'order_date' 
          }
        },
        date: {
          types: [
            {code: '', label: 'Order'},
            {code: 'tnt', label: 'Tracking'}
          ]
        },
        state: {
          selected: '',
          options: [
            {code: 'holded', label: 'Holded', progress: -1},
            {code: 'new', label: 'New', progress: 0},
            {code: 'pick', label: 'Pick', progress: 1},
            {code: 'awaiting', label: 'Awaiting', progress: -3},
            {code: 'pack', label: 'Pack', progress: 2},
            {code: 'manual', label: 'Manual', progress: -2},
            {code: 'complete', label: 'Complete', progress: 3},
            {code: 'canceled', label: 'Canceled', progress: -4}
          ]
        },
        company: {
          default: {},
          selected: {},
          options: [],
          value: ''
        },
        shipping: {
          methods: [],
          options: [],
          value: '',
          agent: {
            methods: {}
          }
        },
        search: {
          input: null,
          query: '',
          product: '',
          initial: {
            query: null,
            product: null
          },
          date: {
            type: {code: '', label: 'Order'},
            start: '',
            end: ''
          },
          company: '',
          locked: false,
          agent: [],
          method: [],
          language: '',
          shipping: [],
          state: '',
          selects: []
        },
        postal: {},
        parcel_shop: {
          search: {},
          options: [],
          selected: {label: ''}
        },
        bulk: {
          select: {
            options: [
              {code: 'select_all', label: 'Select all'},
              {code: 'deselect_all', label: 'Deselect all'},
              {code: 'select_page', label: 'Select page'},
              {code: 'deselect_page', label: 'Deselect page'}
            ],
            option: {
              select_all: true,
              deselect_all: false,
              select_page: true,
              deselect_page: false
            },
            visible: ['select_all', 'select_page'],
            orders: {
              all: [],
              checked: [],
              unchecked: []
            },
            all: false
          },
          action: {
            visible: false,
            options: [
              {code: 'change_status', label: 'Change status'},
              {code: 'send_message', label: 'Send message'},
              {code: 'unlock', label: 'Unlock'},
              {code: 'lock', label: 'Lock'},
            ],
            selected: '',
            popup: {
              show: false,
              title: '',
              message: '',
              select: {
                options: [],
                selected: ''
              }
            }
          }
        },
        modal: {
          view_parcel: {
            open: false,
            loading: false,
            locked: false,
            lock: {},
            tab: {
              active: '',
              body: {},
              shipping: {
                address: {},
                editing: false,
                changeable: false,
                address_form: [
                  {
                    'first_name': 'First name', 
                    'last_name': 'Last name'
                  },
                  {
                    'phone_number': 'Phone',
                    'email': 'Email'
                  },
                  {
                    'company': 'Company',
                    'vat_number': 'VAT number'
                  },
                  {
                    'street_address': 'Address',
                    'parcel_shop_id': 'Parcel shop ID'
                  },
                  {
                    'zip_code': 'Postal code',
                    'city': 'City'
                  },
                  {
                    'state_province': 'State province',
                    'country': 'Country'
                  },
                  {
                    'parcel_shop': 'Parcel shop'
                  }
                ],
                countries: [],
                selected: '',
                options: [],
                value: ''
              },
              order: {
                product_link: []
              },
              status: {
                loaded: false,
                message: '',
                history: []
              },
              comments: {
                category: '',
                categories: {
                  dwnld: 'Downloaded file',
                  cncld: 'Parcel cancelled',
                  lkd: 'Parcel locked',
                  state: 'Parcel status',
                  unlkd: 'Parcel unlocked',
                  wgt: 'Parcel weight',
                  trff: 'Order item tariff',
                  cntr: 'Order item country',
                  addr: 'Shipping address',
                  mthd: 'Shipping method',
                  prnt: 'Printed file',
                  usr: 'User comment'
                },
                loaded: false,
                value: '',
                json: {},
                list: []
              },
              videos: {
                loaded: false,
                list: []
              }
            }
          }
        },
        popup: {
          mousedown: null,
          return_label: {
            show: false,
            action: 'print',
            courier_options: [],
            courier_default: '',
            courier_selected: {},
            courier_value: '',
            message: 'Creates a new return label.',
            button: {
              cancel: {
                text: 'No'
              },
              confirm: {
                text: 'Yes, '
              }
            }
          }
        },
        browser: {
          firefox: navigator.userAgent.includes('Firefox'), 
          chrome: navigator.userAgent.includes('Chrome')
        },
        overflow: {
          parcel_list: true
        },
        dialog: {
          show: false,
          mode: '',
          data: {},
          event() {},
          confirm() {},
          cancel() {},
          close() {}
        },
        qr_barcode: '',
        qr_barcode_index: null,
      }
    },
    created() {
      this.refresh.time.remaining = this.refresh.time.ms;
      this.refresh.sec = this.refresh.ms / 1000;

      const dialog = this.dialog;
      dialog.event = (event) => {
        if (/down/.test(event.type)) {
          dialog.event.target = event.target;
        } else if (event.target == dialog.event.target) {
          if (event.target.classList.contains('validate-popup')) {
            dialog.close();
          }
          dialog.event.target = null;
        }
      }
      dialog.submit = async () => {
        //const form = this.$refs.dialog_form;
        const submit = this.$refs.dialog_submit;
        if (!await this.ValidateForm(submit)) return;

        dialog.close();
        dialog.data = {};
        dialog.mode = '';
      }
      dialog.confirm = () => {
        dialog.submit();
      }
      dialog.cancel = () => {
        dialog.close();
      }
      dialog.close = () => {
        dialog.show = false;
      }
      dialog.open = (mode) => {
        dialog.show = true;
        dialog.mode = mode;
        
        this.$nextTick().then(() => {
          let focus_element = null;
          if (dialog.mode == 'custom_label.return') {
            console.log()
          }
          if (focus_element) focus_element.focus();
        });
      }
    },
    async mounted() {
      this.main.view = document.querySelector('.content-scroll');
      this.main.view.scroll = {top: 0};

      this.clipboard = await Tool.PasteFromClipboard(true);
      this.user = JSON.parse(BPA.storage.getItem('user'));

      this.$eventHub.$on('CloseModal', (modal_name) => {
        this.main.view.classList.remove('no-scroll');
        let modal = this.modal;
        if (modal[modal_name]) {
          modal[modal_name].open = false;
        }
        if (modal_name == 'view_parcel') {
          let view_parcel = modal.view_parcel;
          let tab = view_parcel.tab;
          tab.active = '';
          tab.shipping.editing = false;
          tab.videos.loaded = false;
          tab.status.loaded = false;
          tab.status.message = '';
          tab.comments.loaded = false;
          tab.comments.value = '';
          tab.comments.json = {};
          tab.comments.list = [];
          this.parcel = {};
          this.cached.parcel = {};
          BPA.cache.session({name: this.$options.name, set: {parcel: {}}});
          for (let i = 0; i < this.clickables.length; i++) {
            if (this.clickables[i].classList.contains('selected')) {
              this.clickables[i].classList.remove('selected');
            }
          }
          if (this.video.event) this.video.event.remove();
        }
        this.ScanInputHandler();
      });
      
      this.couriers = BPA.api.Couriers('GET');
      for (let code in this.couriers) {
        //if (has_res_parcel_conf.includes(code)) {
          this.courier.options.push({
            code: code, label: this.couriers[code]
          });
        //}
      }
      this.courier.options.sort((a, b) => {
        a = a.label.toUpperCase(); 
        b = b.label.toUpperCase();
        return a < b ? -1 : a > b ? 1 : 0;
      });
      this.popup.return_label.courier_options = this.CloneObject(this.courier.options);

      BPA.api.Companies('GET').map(company => {
        this.companies.push(company);
        this.company.options.push({
          code: company.code, 
          label: company.name
        });
      });

      BPA.api.Countries('GET').map(country => {
        this.countries[country.name] = country;
        this.iso[country.name] = country.iso_code_2.toLowerCase();
        this.country.options.push({id: country.id, code: this.iso[country.name], label: country.name});
      });

      this.language.options = BPA.locale('options').map(option => {
        let label = option.label.split(' ')[0];
        let code = option.code.replace('-', '_');
        let language = code.split('_')[0].toLowerCase();
        if (/no/.test(code)) {
          code = code.replace('no', 'nb');
        }
        option.code = code;
        option.label = label;
        option.language = language;
        option.country = code.split('_')[1];
        return option;
      });

      let storage = BPA.util.storage;
      let languages = storage.get('languages');
      let company = BPA.util.GetCompany();
      let refetch = this.AllowCompanySearch() && Object.keys(languages).length == 1;
      if (!Object.keys(languages).length || refetch) {
        const get_languages = async (company_code) => {
          let locales = await this.GetLanguageOrderCount(company_code);
          company = company_code || company;
          languages[company] = [];
          for (let locale in locales) {
            let option = {code: locale};
            if (/nb/.test(locale)) {
              locale = locale.replace('nb', 'no');
            }
            let code = locale.split('_');
            option.label = this.LanguageName(code[0], 'en');
            option.language = code[0];
            option.country = code[1];
            languages[company].push(option);
          }
        }
        if (this.AllowCompanySearch()) {
          for (let company of this.companies) {
            if (!(company in languages)) {
              await get_languages(company.code);
            }
          }
        } else {
          await get_languages();
        }
        storage.set('languages', languages);
      }
      this.language.company = {options: languages};
      
      /*
      this.language.company.counter = document.createElement('div');
      this.language.company.counter.classList.add('counter');
      this.$nextTick().then(() => {
        let el = document.querySelector('[name=parcel-language-select] .vs__selected-options');
        el.parentElement.insertBefore(this.language.company.counter, el.nextElementSibling);
      });
      */

      //let has_res_parcel_conf = await this.HasResParcelConf() || [];

      this.modal.view_parcel.tab.shipping.address_form.map(group => {
        for (let field in group) this.modal.view_parcel.tab.shipping.address[field] = '';
      });

      await this.GetShippingMethods().then(options => {
        (options || []).sort((a, b) => {
          a = a.name; b = b.name;
          return a < b ? -1 : a > b ? 1 : 0;
        }).map(option => {
          if (!this.shipping.agent.methods[option.agent_code]) {
            this.shipping.agent.methods[option.agent_code] = [];
          }
          this.shipping.agent.methods[option.agent_code].push({
            label: option.shipping_method
          });          
        });
      });

      for (let method of this.shipping.methods) {
        let option = this.courier.options.find(option => option.code == method.agent_code);
        if (option) method.agent_name = option.label;
      }

      //console.log(this.CloneObject(this.courier.options))
      //console.log(this.CloneObject(this.shipping.methods))

      const vue = this;
      const key = {
        pressed: '',
        scanned: [],
        event: {
          down(event) {
            if (vue.search.input != document.activeElement) {
              if (!key.press) {
                key.press = true;
                key.down = event;
              }
            }
          },
          up(event) {
            key.up = event;
            key.press = false;
            if (key.down && key.down.code == key.up.code && (key.code ? key.code == key.up.code : true)) {
              if (key.up.timeStamp - key.down.timeStamp < 5) {
                key.pressed = key.up.key;
                if (/shift/ig.test(key.pressed)) {
                  key.pressed = ':';
                }
                if (/enter/ig.test(key.pressed)) {
                  vue.search.query = key.scanned.join('');
                  key.pressed = '';
                  key.scanned = [];
                  vue.Search();
                } else {
                  key.scanned.push(key.pressed);
                }
              }
            }
          }
        },
        timer(argument) {
          const subscribe = argument !== false;
          if (subscribe && this.timer.subscribed) return;
          if (typeof argument == 'string') key.code = argument;
          const subscription = subscribe ? 'add' : 'remove';
          const method = subscription + 'EventListener';
          Object.keys(this.event).map(event => window[method]('key' + event, this.event[event]));
          if (!subscribe) for (let key in this) if (!/event|timer/.test(key)) delete this[key];
          this.timer.subscribed = subscribe;
          this.scanned = [];
        }
      };
      this.key = key;

      let order_id = BPA.session.GetParcelOrderUrl(true);
      this.get_parcel_from_url = order_id && !isNaN(order_id);

      if (!this.get_parcel_from_url) {
        this.cached = BPA.cache.local({name: this.$options.name, get: ['page', 'search', /*'shipping',*/ 'company']});
        for (let key of Object.keys(this.cached)) this[key] = {...this[key], ...this.cached[key]};
        this.cached = {...this.cached, ...BPA.cache.session({name: this.$options.name, get: 'parcel'})};
      }
      if (this.clipboard) {
        let data = JSON.parse(this.clipboard) || {};
        if (data.search) {
          this.search.query = Object.values(data.search)[0];
          this.cached = {};
        }
      } else if (this.cached.search) {
        let search = this.CloneObject(this.cached.search);
        delete search.date.type;
        this.shipping.value = search.method[0] || '';
        if (search.product || Object.values(search.date).filter(e => e).length) {
          this.$refs.extra_filter_toggle && this.$refs.extra_filter_toggle.click();
        }
      }

      //this.search.input = document.querySelector('.grid-search input');
      this.search.inputs = Array.from(document.querySelectorAll('.grid-search input'));
      this.search.selects = Array.from(document.querySelectorAll('.grid-search-filter .v-select input'));

      if (this.get_parcel_from_url) {
        this.ViewParcelShow(order_id);
      } else {
        if (window.location.href.includes('?id=')) {
          this.$eventHub.$emit('ShowMessages', {
            message: 'Order not found',
            type: 'error',
            hide: 2000
          });
        }
        this.QueryParcels();
      }

      this.GetCompanyOrderCount().then(() => {
        if (this.AllowStateCountRefresh()) {
          this.refresh.date = new Date();
          this.$nextTick().then(() => {
            let length = 158;
            let end = 0;
            let now = Date.now;
            let initial = true;
            let duration = this.refresh.time.remaining;

            this.refresh.timer = {id: requestAnimationFrame.bind(window)};

            this.refresh.timer.display = () => {
              let current = end - now();
              let remaining = duration;
              if (this.refresh.timer.id) {
                if (current > 0) {
                  remaining = (this.refresh.timer.id(this.refresh.timer.display), current);
                } else {
                  this.refresh.timer.countdown();
                }
              }
              this.refresh.time.remaining = remaining;
              this.refresh.timer.percent(remaining);
            }

            this.refresh.timer.percent = (remaining) => {
              let percent = (duration - remaining) / duration * 100;
              let dashoffset = (length / 100) * percent;
              this.refresh.timer.dashoffset = dashoffset.toFixed(2);
              document.querySelectorAll('svg.pie > circle').forEach(circle => {
                circle.style.setProperty('stroke-dashoffset', this.refresh.timer.dashoffset);
              });
            }

            this.refresh.timer.start = () => {
              if (this.refresh.timer.id) {
                end = now() + duration;
                this.refresh.timer.id(this.refresh.timer.display);
                document.querySelectorAll('svg.pie > circle').forEach(circle => {
                  circle.style.removeProperty('stroke-dashoffset');
                });
              }
            }

            this.refresh.timer.restart = () => {
              this.refresh.timer.id = requestAnimationFrame.bind(window);
              this.refresh.timer.start();
            }

            this.refresh.timer.stop = () => {
              cancelAnimationFrame(this.refresh.timer.id);
              this.refresh.timer.id = null;
            }

            (this.refresh.timer.countdown = () => {
              if (!initial) this.refresh.timer.stop();
              this.GetCompanyOrderCount(null, initial).then(() => {
                this.refresh.date = new Date();
                this.refresh.timer[initial ? 'start' : 'restart']();
                initial = false;
              });
            })();
          });
        }
      });

      this.ScanInputHandler();

      const list_overflow = {};
      const list_overflow_selector = '[class*=list-overflow]';
      const scrollEvent = new Event('scroll');
      const mouse_props = {
        x: 0,
        initX: 0,
        up: true,
        down: false,
        move: false,
        direction: ''
      };
      document.querySelectorAll(list_overflow_selector).forEach(element => {
        const className = element.className.split(/\s|-/);
        if (className.includes('scrollbar')) {
          if (className.includes('handle') && list_overflow.scrollbar) {
            list_overflow.scrollbar.handle = list_overflow.scrollbar.firstChild;
            list_overflow.scrollbar.handle.mouse = this.CloneObject(mouse_props);
            list_overflow.scrollbar.handle.offset = {left: 0};
            list_overflow.scrollbar.handle.left = 0;
            return;
          }
          list_overflow.scrollbar = element.cloneNode(true);
          list_overflow.scrollbar.state = {
            mousedown: false
          }
        } else {
          list_overflow.container = element.cloneNode(true);
          list_overflow.container.list = document.querySelector('table.parcel-list');
        }
        element.remove();
      });

      list_overflow.container.onscroll = (event) => {
        if (event.target.scrollLeft) {
          let scroll = (100 * event.target.scrollLeft) / event.target.clientWidth;
          list_overflow.scrollbar.handle.style.setProperty('--offsetLeft', scroll + '%');
        } else {
          list_overflow.scrollbar.handle.style.removeProperty('--offsetLeft');
        }
      }
      const scrollbar = {
        container: list_overflow.container,
        element: list_overflow.scrollbar,
        handle: list_overflow.scrollbar.handle,
        list: list_overflow.container.list,
        mouse: list_overflow.scrollbar.handle.mouse,
        vue: this,
        rect() {
          return {
            container: scrollbar.container.getBoundingClientRect(),
            element: scrollbar.element.getBoundingClientRect(),
            handle: scrollbar.handle.getBoundingClientRect(),
            list: scrollbar.list.getBoundingClientRect()
          }    
        },
        event: {
          mousedown(e) {
            if (e.which != 1) return;
            /*
            if ([scrollbar.element, scrollbar.handle].includes(e.target)) {
              
            }
            */
            if (e.target == scrollbar.handle) {
              scrollbar.mouse.down = true;
              scrollbar.mouse.x = e.clientX;
              scrollbar.mouse.initX = scrollbar.mouse.x;
              scrollbar.handle.initLeft = scrollbar.handle.offset.left;
              scrollbar.handle.classList.add('active');
            }
            /*
            if (e.target == scrollbar.element) {
              if (e.clientX > scrollbar.rect().handle.right) {
                scrollbar.container.scrollLeft = scrollbar.list.scrollWidth - scrollbar.container.clientWidth;
              } else {
                scrollbar.container.scrollLeft = 0;
              }
            }
            */
          },
          mousemove(e) {
            e.preventDefault();
            scrollbar.mouse.move = scrollbar.mouse.down;
            if (scrollbar.mouse.move) {
              if (e.clientX < scrollbar.mouse.x) {
                scrollbar.mouse.direction = 'left'; 
              } else if (e.clientX > scrollbar.mouse.x) {
                scrollbar.mouse.direction = 'right';
              }
              const rect = scrollbar.rect();
              let scrollableWidth = rect.list.width - rect.container.width;
              let draggableWidth = rect.element.width - rect.handle.width;
              let ratio = scrollableWidth / draggableWidth;
              let distance = (scrollbar.mouse.x - scrollbar.mouse.initX) - scrollbar.handle.initLeft;
              let movement = distance * ratio;
              scrollbar.handle.offset.left = -Math.ceil((rect.handle.left + 1) - scrollbar.handle.left);
              scrollbar.container.scrollLeft = movement;
              scrollbar.mouse.x = e.clientX;
            }
          },
          mouseup(e) {
            scrollbar.mouse.down = false;
            if ([scrollbar.element, scrollbar.handle].includes(e.target)) {
              const scrollTop = scrollbar.vue.main.view.scrollTop;
              scrollbar.container.focus();
              scrollbar.vue.main.view.scrollTop = scrollTop;
            }
            scrollbar.mouse = scrollbar.vue.CloneObject(mouse_props);
            scrollbar.handle.classList.remove('active');
          }
        },
        listener(subscribe) {
          subscribe = subscribe !== false;
          const subscription = subscribe ? 'add' : 'remove';
          const method = subscription + 'EventListener';
          Object.keys(this.event).map(e => window[method](e, this.event[e]));
        }
      };
      this.overflow.scrollbar = scrollbar;
      this.onresize = () => {
        const wrapper = this.main.view.querySelector('.grid-wrapper');
        const card = wrapper.querySelector('.col.col-12:first-child');
        const list = document.querySelector('table.parcel-list');
        const card_rect = card.getBoundingClientRect();
        const list_rect = list.getBoundingClientRect();
        const style = window.getComputedStyle(card);
        const padding = style.getPropertyValue('padding-left');
        const card_width = card_rect.width - parseInt(padding) * 2;
        const list_width = list_rect.width;
        const overflow_elements = ['container', 'scrollbar'];
        this.overflow.parcel_list = list_width > card_width;
        this.OverflowScrollbar(this.overflow.parcel_list);
        if (this.overflow.parcel_list) { // overflow occured
          list_overflow.scrollbar.handle.style.width = (100 * card_width) / list_width + '%';
          list_overflow.container.dispatchEvent(scrollEvent);
          if (wrapper.contains(list_overflow.container)) return;
          overflow_elements.map(e => card.insertBefore(list_overflow[e], list));
          list_overflow.container.append(list);
          list_overflow.scrollbar.handle.left = list_overflow.scrollbar.handle.getBoundingClientRect().left;
        } else {
          list_overflow.scrollbar.style.removeProperty('width');
          if (!wrapper.contains(list_overflow.container)) return;
          card.insertBefore(list, list_overflow.container);
          overflow_elements.map(e => list_overflow[e].remove());
        }
      }
      this.main.view.onscroll = (event) => {
        const content = event.target;
        if (content.classList.contains('no-scroll')) {
          content.scrollTop = content.scroll.top;
          event.preventDefault();
          return;
        }
        const main = content.getBoundingClientRect();
        content.querySelectorAll(list_overflow_selector).forEach(element => {
          const className = element.className.split(/\s|-/);
          const elm = element.getBoundingClientRect();
          if (className.some(e => /x|y/.test(e))) {
            if (className.includes('x')) {
              if (elm.top < main.top) {
                element.style.setProperty('--offsetTop', main.top - elm.top + 'px');
              } else {
                element.style.removeProperty('--offsetTop');
              }
            }
          }          
        });
      };
      this.main.view.dispatchEvent(scrollEvent);
      window.addEventListener('resize', this.onresize);
      // Event listener for accordion transtion overflow property
      ['start', 'end'].map(event => {
        this.$refs.accordion_wrapper[`ontransition${event}`] = (e) => {
          if (e.propertyName == 'height') {
            if (event == 'end') {
              if (e.target.previousSibling.classList.contains('open')) {
                e.target.style.setProperty('overflow', 'visible');
              }
            } else if (event == 'start') {
              e.target.style.removeProperty('overflow');
            }
          }
        }
      });
    },
    destroyed() {
      window.removeEventListener('resize', this.onresize);
      this.main.view.classList.remove('no-scroll');
      if (this.AllowStateCountRefresh()) {
        this.refresh.timer.stop();
      }
      this.OverflowScrollbar(false);
      this.ScanInputHandler(false);
      if (this.modal.view_parcel.tab.body.resize) {
        this.modal.view_parcel.tab.body.resize.disconnect();
      }
    },
    computed: {
      main() {
        const main = {
          company: BPA.util.GetCompany()
        };
        return main;
      },
      sticky() {
        const stickies = {};
        document.querySelectorAll('.sticky').forEach(sticky => {
          stickies[sticky.className.split(' ').pop()] = sticky;
        });
        return stickies;
      },
      CountriesList() {
        const tmp = {};
        //this.countries.map(x => tmp[x.id] = x.name);
        return tmp;
      },
      modalTabsBody() {
        const modal_tabs_body = {};
        document.querySelectorAll('ul.modal-tabs__body').forEach(ul => {
          const content = ul.closest('.modal-wrapper__content');
          let modal_id = content.querySelector('[id]').id;
          if (modal_id) {
            modal_id = modal_id.split('-')[0];
            modal_tabs_body[modal_id] = ul;
          } 
        });
        return modal_tabs_body;
      },
      locale() {
        return this.$i18n.locale;
      }
    },
    watch: {
      locale() {
        this.state.selected = this.$t(this.Capitalize(this.search.state));
      }
    },
    methods: {
      ScanInputHandler(subscribe) {
        this.key.timer(subscribe);
      },
      OverflowScrollbar(subscribe) {
        this.overflow.scrollbar.listener(subscribe);
      },
      IsTruncated(element = HTMLElement) {
        return Tool.IsTruncated(element);
      },
      IsValidURL(string = '') {
        return Tool.IsValidURL(string);
      },
      Hyperlink(props = {}) {
        if (props.scheme == 'mailto') {
          props.href = props.href.toLowerCase();
        }
        if (props.scheme == 'tel') {
          if (!props.href.startsWith('+')) {
            if (props.country) {
              props.href = BPA.phone(props.country) + props.href;
              delete props.country;
            }
          }
        }
        return Tool.Hyperlink(props);
      },
      Alphabetize(list, prop) {
        return Tool.Alphabetize(list, prop);
      },
      DateToISO(date) {
        return Tool.DateToISO(date);
      },
      DateFormat(date) {
        return Tool.DateFormat(date);
      },
      DateOnly(date) {
        if (!date) return;
        date = this.DateFormat(date).replace(this.TimeOnly(date), '');
        return date.replace(/,(?=[^,]*$)/, '');
      },
      TimeOnly(date) {
        return Tool.TimeOnly(date);
      },
      TimeFormat(seconds = 0, all_parts = false, colon_divided = false) {
        let time = Tool.TimeFormat(seconds, all_parts, colon_divided)
        if (!colon_divided) {
          time = time.split(' ').map(x => x.replace(/\D/g, '') + this.$t(x.replace(/\d/g, ''))).join(' ');
          time = time ? time : ('00' + this.$t('s'));
        }
        return time;
      },
      NumberFormat(number) {
        return Tool.NumberFormat(number);
      },
      CurrencyFormat(number, minmax) {
        return Tool.CurrencyFormat(number, null, minmax);
      },
      CountryName(country_code, locale) {
        return Tool.CountryName(country_code, locale);
      },
      LanguageName(country_code, locale) {
        return Tool.LanguageName(country_code, locale);
      },
      CloneObject(object = {}) {
        return Tool.CloneObject(object);
      },
      Capitalize(string) {
        if (string) return Tool.Capitalize(string);
      },
      TitleCase(string) {
        if (string) return Tool.TitleCase(string);
      },
      Style(element, property) {
        return Tool.Style(element, property);
      },
      Empty(element) {
        if (element) return Tool.Empty(element);
      },
      NameCase(full_name = '', first_last = false) {
        if (typeof full_name == 'string') full_name = full_name.split(' ');
        full_name = full_name.filter(e => e && !/undefined|null/i.test(e));
        if (!full_name.length) return;
        let name_prefixes = ['al', 'da', 'de', 'di', 'el', 'der', 'von', 'van'];
        let name_prefix = full_name.filter(name => name_prefixes.includes(name.toLowerCase()));
        if (name_prefix.length) {
          let name_split = full_name.indexOf(name_prefix[0]);
          name_prefix = name_prefix.map(prefix => /al/i.test(prefix) ? this.Capitalize(prefix) : prefix.toLowerCase());
          full_name = [full_name.slice(0, name_split).join(' '), full_name.slice(name_split).join(' ')];
        } else {
          full_name = [full_name.pop(), full_name.join(' ')].reverse();
        }
        full_name = full_name.filter(e => e);
        for (let i = 0; i < full_name.length; i++) {
          let names = full_name[i].split(' ');
          for (let l = 0; l < names.length; l++) {
            names[l] = name_prefix.find(prefix => new RegExp(names[l], 'i').test(prefix)) ? names[l] : this.Capitalize(names[l]);
            //console.log(names[l])
          }
          full_name[i] = names.join(' ');
        }
        return first_last ? full_name : full_name.join(' ').trim();
      },
      BytesToSize(bytes) {
        let size = Tool.BytesToSize(bytes);
        if (size != '0 Bytes') {
          let parts = size.split(' ');
          return {size: parts[0], unit: parts[1]};
        }
        return {};
      },
      Trim(e) {
        if (typeof e == 'string') {
          return String(e).trim();
        }
        if (typeof e == 'object' && e.target) {
          const element = e.target;
          const prop = 'value' || 'textContent';
          const text = element[prop];
          element[prop] = String(text).trim();
          return element[prop];
        }
      },
      CapitalizeFirstLetter(e) {
        if (typeof e == 'string') {
          return String(e.charAt(0).toUpperCase() + e.slice(1)).replace(/\s\s+/g, ' ');
        }
        if (typeof e == 'object' && e.target) {
          const element = e.target;
          const prop = 'value' || 'textContent';
          const text = element[prop];
          element[prop] = String(text.charAt(0).toUpperCase() + text.slice(1)).replace(/\s\s+/g, ' ');
          return element[prop];
        }
      },
      UserName(string = this.user.display_name) {
        if (string.length > 50) {
          string = string.split(' ');
          string = string[0] + ' ' + string.slice(-1)[0];
        }
        return this.TitleCase(string);
      },
      Check(required) {
        return BPA.permissions(required).length;
      },
      AllowStateCountRefresh() {
        return this.Check([/*0, 11*/ 'admin', 'list_admin']);
      },
      AllowStateCount() {
        return this.Check([/*0, 7, 8*/ 'admin', 'parcels_admin', 'parcels']);
      },
      AllowStateChange() {
        return this.Check([/*0, 7*/ 'admin', 'parcels_admin']);
      },
      SummarizeCompanyOrderCount(company_code) {
        let count = this.CloneObject(this.count);
        let sum = {};
        delete count.sum;
        for (let company in count) {
          for (let state in count[company]) {
            let state_count = count[company][state];
            if (['new', 'pick', 'pack', 'manual', 'locks'].includes(state)) {
              if (!sum[state]) sum[state] = state_count;
              else sum[state] += state_count;
            }
          }
        }
        let company = company_code || this.main.company;
        this.count.sum = [
          {label: 'new', value: count[company].new, total: sum.new},
          {label: 'pick', value: count[company].pick, total: sum.pick},
          {label: 'pack', value: count[company].pack, total: sum.pack},
          {label: 'manual', value: count[company].manual, total: sum.manual},
          {label: 'locked', value: count[company].locks, total: sum.locks}
        ];
      },
      async GetCompanyOrderCount(company_code, initial) {
        return await new Promise((resolve, reject) => {
          if (!this.AllowStateCount()) return reject();
          if (initial) return resolve(this.count);
          this.refresh.fetching = true;
          BPA.api.GetCompanyOrderCount(company_code).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            this.refresh.fetching = false;
            if (!response.ok || !response.result) return reject();
            let json = response.result || {};
            this.count = {...this.count, ...json};
            this.SummarizeCompanyOrderCount(company_code);
            resolve(this.count);
          }).catch(reject);
        }).catch(e => e);
      },
      async GetLanguageOrderCount(company_code) {
        return await BPA.api.GetLanguageOrderCount(company_code).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => response.result || {}).catch(e => e);
      },
      async EnqueueOrderForInvoicing(order_id) {
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, queue it',
          disapprove: 'No',
          message: 'Enqueues the current order for bulk invoicing.',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', async approve => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          if (await BPA.api.EnqueueOrderForInvoicing(order_id).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            this.loading = false;
            return response.ok;
          }).catch(e => e)) {
            let parcel = this.parcel;
            let invoice_parcel_id = parcel.invoice_parcel_id;
            parcel.invoice_parcel_additional_ids.push(invoice_parcel_id);
            parcel.invoice_parcel_id = null;
          }
        });
      },
      ToggleExtraSearchFilters(event) {
        ['open', 'plus', 'minus'].map(className => {
          return event.target.classList.toggle(className);
        });
      },
      async SearchByCompany(option) {
        if (option && this.AllowCompanySearch()) {
          await this.GetShippingMethods(option.code);
          this.search.company = option.code;
          this.company.selected = option;
        } else {
          await this.GetShippingMethods();
          this.company.selected = {};
          this.search.company = '';
        }
        this.search.auto_company = false;
        this.page.current = 1;
        this.GetCompanyOrderCount(this.search.company);
        await this.ResetBulk();
        this.QueryParcels();
      },
      async SearchByState(option) {
        this.state.selected = option ? option.code : '';
        this.search.state = this.state.selected;
        this.page.current = 1;
        await this.ResetBulk();
        this.QueryParcels();
      },
      async SearchByLanguage(option) {
        option = option ? option.code : '';
        this.search.language = option;
        this.page.current = 1;
        await this.ResetBulk();
        this.QueryParcels();
      },
      async SearchByAgent(options) {
        options = options || [];
        if (!Array.isArray(options)) options = [options];
        let methods = this.CloneObject(this.search.method) || [];
        let search = this.CloneObject(this.search.shipping) || [];
        let shipping = this.CloneObject(this.shipping.options) || [];
        search = shipping.filter(method => options.some(option => option.code == method.agent));
        if (options.length) {
          search = search.filter(method => {
            if (methods.some(option => option.agent == method.agent)) {
              if (!methods.find(option => option.code == method.code)) return;
            }
            return method;
          });
          for (let option of methods) {
            if (!search.some(method => option.code == method.code)) {
              search.push(option);
            }
          }
        } else {
          search = methods;
        }
        this.search.shipping = search;
        this.search.agent = options;
        this.page.current = 1;
        await this.ResetBulk();
        this.QueryParcels();
      },
      async SearchByShipping(options) {
        options = options || [];
        if (!Array.isArray(options)) options = [options];
        let agents = this.CloneObject(this.search.agent) || [];
        let search = this.CloneObject(this.search.shipping) || [];
        let shipping = this.CloneObject(this.shipping.options) || [];
        search = shipping.filter(method => agents.some(option => option.code == method.agent) || options.some(option => option.agent == method.agent));
        if (options.length) {
          search = search.filter(method => {
            if (options.some(option => option.agent == method.agent)) {
              if (!options.find(option => option.code == method.code)) return;
            }
            return method;
          });
        } else {
          search = shipping.filter(method => agents.some(option => option.code == method.agent));
        }
        search = search.length ? search : options;
        this.search.shipping = search;
        this.search.method = options;
        this.page.current = 1;
        await this.ResetBulk();
        this.QueryParcels();
      },
      async SearchByLock(e) {
        this.search.locked = e.target.checked;
        this.page.current = 1;
        await this.ResetBulk();
        this.QueryParcels();
      },
      async SearchByProduct() {
        this.search.inputs.map(input => input.blur());
        this.search.product = this.search.product.replace(/\s\s+/g, ' ').trim();
        if (this.search.product == this.search.initial.product) return;
        this.search.auto_company = true;
        this.page.current = 1;
        await this.ResetBulk();
        this.QueryParcels();
      },
      async SearchDate(date) {
        let type = Object.keys(date)[0];
        if (date[type] && !this.search.date[type]) {
          let time = (type == 'start' ? '00:00' : '23:59') + ':00';
          date[type] = date[type].split(' ')[0] + ' ' + time;
        }
        this.search.date[type] = date[type];
      },
      async SearchByDate(/*date*/) {
        /*
        let entry = Object.entries(date);
        this.search.date[entry[0][0]] = entry[0][1];
        */
        this.page.current = 1;
        await this.ResetBulk();
        this.QueryParcels();
      },
      async SearchBySize() {
        await this.ResetBulk();
        this.QueryParcels();
      },
      async Search() {
        this.search.inputs.map(input => input.blur());
        this.search.query = this.search.query.replace(/^(\d{1})æ|~(\d{5})/ig, '$1:$2');
        this.search.query = this.search.query.replace(/\s\s+/g, ' ').trim();
        if (this.search.query == this.search.initial.query) return;
        this.search.auto_company = true;
        this.page.current = 1;
        await this.ResetBulk();
        this.QueryParcels();
      },
      async ClickState(state) {
        const locked = state == 'locked';
        if (this.refresh.loading) return;
        if (this.search.state != state) {
          if (!locked) {
            this.state.selected = this.$t(this.Capitalize(state));
            this.search.state = state;
          }
        } else {
          delete this.search.state;
        }
        if (this.search.locked && locked) {
          delete this.search.locked;
        } else if (locked) {
          this.search.locked = true;
        }
        if (this.AllowStateCountRefresh()) {
          this.refresh.timer.countdown();
        }    
        this.page.current = 1;
        await this.ResetBulk();
        this.QueryParcels();
      },
      async QueryParcels(spinner) {
        let query = this.search.query;
        let colon = false;
        if (spinner !== false) this.loading = true;
        if (this.search.auto_company && this.AllowCompanySearch()) {
          let company_id = query.split(':')[0];
          let company_code = BPA.company('code', company_id);
          if (company_code && company_code != this.main.company) {
            let option = this.company.options.find(company => company.code == company_code);
            await this.GetShippingMethods(option.code);
            this.search.company = option.code;
            this.company.value = option.label;
            this.company.selected = option;
          } else {
            await this.GetShippingMethods();
            this.company.selected = {};
            this.search.company = '';
            this.company.value = '';
          }
        }
        let request = {
          query: colon ? colon[1] : query,
          product: this.search.product,
          target_company: this.search.company,
          state: this.search.state,
          order_by: this.page.order.by,
          order_direction: this.page.order.direction,
          page_offset: this.page.current,
          page_size: this.page.size
        }
        if ('pinned' in this.search) {
          request.pinned = this.search ? 'yes' : 'no';
        }
        if (this.search.locked) {
          request.only_locked = true;
        }
        if (!this.search.product) {
          delete request.product;
        }
        if (!this.search.company) {
          delete request.target_company;
        }
        if (!this.search.state) {
          delete request.state;
        }
        if (this.search.language) {
          request.language = this.search.language;
        }
        if (this.search.date.start || this.search.date.end) {
          let date_type = this.search.date.type.code;
          if (date_type) date_type += '_';
          if (this.search.date.start) {
            request[`${date_type}from_date`] = Tool.DateToUTC(this.search.date.start);
          }
          if (this.search.date.end) {
            request[`${date_type}to_date`] = Tool.DateToUTC(this.search.date.end);
          }
        }
        if (this.search.shipping && this.search.shipping.length) {
          let search_shipping = this.CloneObject(this.search.shipping);
          search_shipping = search_shipping.map(option => option.code);
          request.shipping_description = JSON.stringify(search_shipping);
        }
        if ((this.clipboard || {}).search) {
          BPA.cache.session({name: this.$options.name, set: {parcel: {}}});
          BPA.cache.remove({type: 'session', item: 'ParcelsOrdersList'});
          request.query = this.search.query;
          request.target_company = this.search.company;
        }
        //console.log(request.shipping_description)
        //console.log(this.CloneObject(request))
        await BPA.api.GetParcels(request).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          this.loading = false;
          if (!response.ok || !response.result) return;
          let list = response.result || {};
          if (!list.page_offset) list.page_offset = 0;
          this.search.initial.product = this.search.product;
          this.search.initial.query = this.search.query;
          this.cms_order_link = list.cms_order_link;
          this.page.current = list.page_offset + 1;
          this.page.last = list.total_pages || 1;
          this.entries = list.items_total;
          this.parcels = list.items;
          
          if (!this.get_parcel_from_url) {
            let search = this.CloneObject(this.search);
            search.initial.product = null;
            search.initial.query = null;
            if (!(this.clipboard || {}).search) {
              BPA.cache.local({name: this.$options.name, set: {page: this.page, search, /*shipping: this.shipping,*/ company: this.company}});
              this.clipboard = null;
            }
          }

          let bulk = this.bulk;
          let bulk_select = bulk.select;
          let bulk_action = bulk.action;

          this.parcels.forEach(parcel => {
            if (bulk_select.all) {
              if (!bulk_select.orders.unchecked.includes(parcel.id)) {
                if (!bulk_select.orders.checked.includes(parcel.id)) {
                  bulk_select.orders.checked.push(parcel.id);
                }
                parcel.checked = true;
              }
            } else {
              parcel.checked = bulk_select.orders.checked.includes(parcel.id);
            }
            for (let type of ['billing', 'shipping']) {
              let address = parcel[`${type}_address`];
              address.street_address = this.TitleCase(address.street_address);
            }
            parcel.courier = this.CourierFromShippingMethod(parcel.shipping_description);
            parcel.pack_log.sort((a, b) => new Date(b.date) - new Date(a.date));
            for (let item of parcel.pack_log) {
              item.shipment_list_data = {};
              item.loading = false;
            }
            parcel.shipment_list_ids = parcel.pack_log.some(item => item.shipment_list_id);
            parcel.shipment_list_lookup = this.user.permission.some(check => [0, 11].includes(check));
            parcel.company_code = BPA.company('code', parcel.company_id);
            parcel.company_name = BPA.company('name', parcel.company_code);
            parcel.picked_by = parcel.picked_by || [];
            let parcel_courier = parcel.courier;
            let event = {
              status: {
                pre: 'registered',
                on_way: 'dispatched',
                await: 'awaiting',
                done: 'completed',
              },
              message: {
                registered: 'The shipment is registered',
                dispatched: 'The shipment is on the way',
                awaiting: 'The shipment is awaiting action',
                completed: 'The shipment is completed',
                error: 'Something unexpected has occurred'
              },
              state: {
                registered: false,
                dispatched: false,
                awaiting: false,
                completed: false,
                error: false
              },
              color: {
                registered: 'violet',
                dispatched: 'blue',
                awaiting: 'yellow',
                completed: 'green',
                error: 'red'
              }
            };
            if (parcel.pack_log.length) {
              parcel.tracking = parcel.pack_log[0].tnt;
              parcel.courier = parcel.pack_log[0].agent_code;
              let newest = parcel.pack_log.find(event => event.newest);
              //console.log(parcel.increment_id, parcel.agent_code, newest.tnt_status)
              if (newest.tnt_status) {
                let tnt_event = newest.tnt_status;
                parcel.courier_event = {state: event.state};
                parcel.courier_event.status = tnt_event.status/*.toLowerCase()*/;
                parcel.courier_event.type = tnt_event.last_event_type || '';
                parcel.courier_event.text = tnt_event.text || '';
                for (let [key, value] of Object.entries(event.status)) {
                  if (key == parcel.courier_event.type) {
                    parcel.courier_event.message = event.message[value];
                    parcel.courier_event.color = event.color[value];
                    parcel.courier_event.state[value] = true;
                  }
                }
                if (event.last_event_has_error) {
                  parcel.courier_event.color = event.color.error;
                  parcel.courier_event.state.error = true;
                }
              }
            }
            parcel.shipping_courier = this.CourierFromShippingMethod(parcel.shipping_description);
            parcel.courier_difference = parcel.courier != parcel_courier;

            let language = {iso: parcel.language || ''};
            language.code = String(language.iso.split('_')[0]).toLowerCase();
            language.domain = String(language.iso.split('_')[1]).toLowerCase();
            if (/gb/.test(language.domain)) language.domain = 'co.uk';
            if (/nb/.test(language.code)) language.code = 'no';
            
            parcel.language = language;

            parcel.webshop = Tool.Webshop(parcel.company_id, parcel.increment_id)
          });

          if (!this.parcels.some(parcel => parcel.checked)) {
            let index = bulk_select.visible.indexOf('deselect_page');
            if (index > -1) bulk_select.visible.splice(index, 1);
            if (!bulk_select.visible.includes('select_page')) {
              bulk_select.visible.push('select_page');
            }
          } else {
            let checked = this.parcels.filter(parcel => parcel.checked);
            if (checked.length == this.parcels.length) {
              let index = bulk_select.visible.indexOf('select_page');
              if (index > -1) bulk_select.visible.splice(index, 1);
            }
            if (!bulk_select.visible.includes('deselect_page')) {
              bulk_select.visible.push('deselect_page');
            }
          }
          bulk_select.visible = [...new Set(bulk_select.visible)];
          for (let type of ['checked', 'unchecked']) {
            bulk_select.orders[type].sort();
          }
          if (bulk_action.visible) {
            bulk_action.visible = false;
            bulk_action.visible = true;
          } else {
            bulk_action.visible = true;
            bulk_action.visible = false;
          }
          //console.log(this.CloneObject(bulk_select))

          this.$nextTick().then(async () => {
            this.clickables = document.querySelectorAll('.clickable');
            if (this.cached.parcel && Object.keys(this.cached.parcel).length) {
              this.ViewParcelShow(this.cached.parcel.id);
            }/*else if (this.clickables.length == 1 && (this.clipboard || {}).search) {
              this.ViewParcelShow(this.clickables[0].dataset.id);
            }*/
            if (this.get_parcel_from_url && this.clickables.length == 1) {
              this.clickables[0].classList.add('selected');
            }
            this.SetPageJumpWidth();
            this.onresize();
            //console.log(this.CloneObject(this.parcels))
            /*
            this.OrderLabelStatus({
              increment_id: this.parcels[0].increment_id,
              check_value: this.parcels[0].shipping_address.email
            });
            */
            /*
            let order_ids = await this.GetAllOrderIDs();
            console.log(order_ids)
            */
            //console.log(this.CloneObject(this.parcels))
          });
        }).catch(e => e);
      },
      async GetAllOrderIDs() {
        return await new Promise((resolve, reject) => {
          BPA.api.GetParcels({ids_only: true}).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok) return reject();
            resolve(response.result || []);
          }).catch(reject);
        }).catch(e => e);
      },
      async SetOrderStateBulk(request) {
        return await new Promise((resolve) => {
          BPA.api.SetOrderStateBulk(request).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => resolve(response.ok));
        }).catch(e => e);
      },
      async SetOrderBulkInvoicingSkip(request) {
        return await BPA.api.SetOrderBulkInvoicingSkip(request).then(response => {
          return BPA.api.response({response});
        }).then(response => response.ok).catch(e => e);
      },
      async ToggleBulkInvoicing(event) {
        let checkbox = event.target;
        let skip = !checkbox.checked;
        let order_id = this.parcel.id;
        let request = {order_id, skip};
        let action = skip ? 'remove': 'add';
        this.$eventHub.$emit('ValidateModalStart', {
          approve: `Yes, ${action} it`,
          disapprove: 'No',
          message: `${this.Capitalize(action)}s the current order ${skip ? 'from' : 'to'} bulk invoicing.`,
          type: skip ? 'danger' : 'success'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return checkbox.checked = skip;
          this.loading = true;
          if (await this.SetOrderBulkInvoicingSkip(request)) {
            this.$eventHub.$emit('ShowMessages', {
              message: `Order successfully ${action + (action == 'add' ? 'ed' : 'd')} ${skip ? 'from' : 'to'} bulk invoicing`,
              type: 'success',
              hide: 2000
            });
          }
          this.loading = false;
        });
      },
      CourierFromString(str) {
        let couriers = {};
        const obj = BPA.api.Couriers('GET');
        for (let key in obj) couriers[key] = obj[key].toLowerCase();
        const words = str.length ? str.toLowerCase().replace('butik', 'shop').replace('dpd', 'postnord').split(' ') : [];
        const courier = words.find(word => Object.values(couriers).includes(word));
        let code = courier ? Object.keys(couriers).find(key => couriers[key] == courier) : {};
        if (!Object.keys(code).length) {
          //const extra = {dfm: 'DFM', dpd: 'DPD', pat: 'POST AT'};
          //console.log(extra, words)
          code = '';
        }
        return code;
      },
      CourierFromShippingMethod(shipping_description) {
        let shipping_methods = this.shipping.methods;
        let shipping_courier = shipping_methods.find(option => {
          return option.shipping_method/*.replace(/%2F/g, '/')*/ == shipping_description;
        });
        if (shipping_courier) {
          shipping_courier = shipping_courier.agent_code;
        } else {
          for (let option of shipping_methods) {
            let agent_code = new RegExp(`^${option.agent_code}`, 'i');
            if (agent_code.test(shipping_description)) {
              shipping_courier = option.agent_code;
              break;
            }
          }
        }
        return shipping_courier || null;
      },
      async OpenShippingAddressEditor() {
        let form_template = this.CloneObject(this.modal.view_parcel.tab.shipping.address_form);
        let form_data = this.CloneObject(this.parcel.shipping_address);
        let address = ['street', 'zip', 'city'];
        let country = ['country'];
        let fields = [...address, ...country];
        let required_regex = new RegExp(['name', 'email', 'phone', ...fields].flat().join('|'));
        let shop_id_regex = /^(.*?)\n?\s?pakkeshop:(.*?)$/i;
        const parcel_shop_fields = {};
        const is_parcel_shop = /shop/i.test(this.parcel.shipping_type) && !/shop|instabox/.test(this.parcel.shipping_courier);
        //console.log(this.CloneObject(this.parcel))
        //console.log('is_parcel_shop', is_parcel_shop)
        const parcel_shop_search = is_parcel_shop && await this.CanSearchParcelShops();
        const address_index = (key) => fields.map((e, i) => new RegExp(e).test(key) && i).filter(e => e !== false)[0];
        const shipping_address_form = (ungroup = false) => {
          let form = this.parcel.shipping_address_form;
          if (ungroup) {
            let reduced = {};
            for (let group of form) {
              for (let field in group) {
                reduced[field] = group[field];
              }
            }
            return reduced;
          }
          return form;
        }
        const get_postal_codes = async () => {
          if (Array.isArray(country)) return;
          const break_loop = [];
          const options = {data: {}, postal: [], city: []};
          for (let group of shipping_address_form()) {
            if (break_loop.length == 2) break;
            for (let key in group) {
              if (/zip|city/.test(key)) {
                break_loop.push(key);
                let field = group[key];
                if (/zip/.test(key)) {
                  field.fetching = true;
                  options.data = await this.GetPostalCodes(country.code);
                  field.fetching = false;
                  options.postal = Object.keys(options.data).sort();
                  options.city = [...new Set(Object.values(options.data))].sort();
                  field.list = options.postal;
                } else {
                  field.list = options.city;
                }
                field.data = options.data;
                /*
                field.invalid = field.list.length && !field.list.includes(field.value);
                if (field.invalid && /city/.test(key)) {
                  field.invalid = !field.list.some(option => new RegExp(field.value, 'i').test(option) || new RegExp(option, 'i').test(field.value));
                }
                */
                //console.log(field.list)
                field.caution = field.list.length && !field.list.includes(field.value);
                if (field.caution && /city/.test(key)) {
                  field.caution = !field.list.some(option => new RegExp(field.value, 'i').test(option) || new RegExp(option, 'i').test(field.value));
                }
                if (break_loop.length == 2) break;
              }
            }
          }
        };
        const get_parcel_shops = async () => {
          if (Array.isArray(country)) return [];
          let break_loop = false;
          let parcel_shop_options = [];
          let parcel_shop_option = null;
          const parcel_shop_field_keys = Object.keys(parcel_shop_fields);
          for (let group of shipping_address_form()) {
            if (break_loop) break;
            for (let key in group) {
              if (/parcel_shop$/.test(key)) {
                break_loop = true;
                let parcel_shop_field = group[key];
                parcel_shop_field.fetching = true;
                //console.log(address.filter(e => e))
                parcel_shop_options = address.filter(e => e).length ? await this.GetParcelShops({
                  agent_code: this.parcel.shipping_courier || this.parcel.agent_code, 
                  country_code: country.code, 
                  address: address.join(' ').replace(/\s+/g, ' ').trim()
                }) : [];
                parcel_shop_field.fetching = false;
                parcel_shop_field.options = parcel_shop_options;
                for (let option of parcel_shop_options) {
                  let key_map = parcel_shop_field_keys.map(key => {
                    let form_field = parcel_shop_fields[key];
                    let form_field_value = String(form_field.value);
                    let option_field_value = String(option.data[key]);
                    if (/shop_id/.test(key)) form_field_value = option_field_value;
                    if (/country/.test(key)) form_field_value = String(form_field.code);
                    return form_field_value.toLowerCase() == option_field_value.toLowerCase();
                  })/*.filter(e => e)*/;
                  if (key_map.every(e => e)/*key_map.length == parcel_shop_field_keys.length*/) {
                    parcel_shop_option = option;
                    break;
                  }
                }
                parcel_shop_field.value = (parcel_shop_option || {}).label || '';
                for (let key in parcel_shop_fields) {
                  let field = parcel_shop_fields[key];
                  if (field.value) field.invalid = false;
                  if (/company/.test(key) && parcel_shop_option) {
                    field.value = parcel_shop_option.data.company_name;
                  }
                  if (/shop_id/.test(key)) {
                    let shop_id = '';
                    if (field.value) {
                      parcel_shop_option = parcel_shop_options.find(option => option.id == field.value);
                      if (parcel_shop_option) {
                        parcel_shop_field.value = parcel_shop_option.label || '';
                        for (let prop in parcel_shop_fields) {
                          if (!/country/.test(prop)) {
                            let form_field = parcel_shop_fields[prop];
                            form_field.value = parcel_shop_option.data[prop];
                            form_field.invalid = false;
                          }
                        }
                      } else {
                        shop_id = String(form_data.street_address).replace(shop_id_regex, '$2').replace(/\s/g, '');
                        if (shop_id != String(field.value).replace(/\s/g, '')) shop_id = '';
                      }
                    }
                    field.value = (parcel_shop_option || {}).id || shop_id || '';
                    field.invalid = !field.value;
                  }
                }
                break;
              }
            }
          }
        };
        for (let group in form_template) {
          for (let key in form_template[group]) {
            let required = required_regex.test(key);
            let value = key in form_data ? form_data[key] : '';
            let field = form_template[group][key] = {
              label: form_template[group][key],
              name: `edit-${String(key).replace(/_/g, '-')}`,
              invalid: required && !String(value).replace(/\s/g, '').length,
              required, value,
              form_key: key,
              shop_key: null,
              change: (event) => {
                let input = event.target;
                let field = form_template[group][key];
                let value = String(input.value).replace(/\s+/g, ' ').trim();
                if (/name/.test(key)) {
                  //value = this.TitleCase(value);
                  value = this.NameCase(value);
                }
                if (/email|shop_id/.test(key)) {
                  value = String(value).replace(/\s/g, '');
                  if (/email/.test(key)) {
                    value = String(value).toLowerCase();
                  }
                }
                if (field.required) {
                  field.invalid = !String(value).replace(/\s/g, '').length;
                }
                field.value = value;
                input.value = value;
              },
              keydown: (event) => {
                if (/enter/i.test(event.key)) {
                  event.target.blur();
                }
              },
              blur: (event) => {
                let input = event.target;
                let value = input.value || '';
                if (/phone/.test(key)) {
                  field.value = String(value).replace(/(?!\+)[\D\s]/g, '');
                  input.valule = field.value;
                }
              },
              input: () => {}
            };
            if (/last_name/.test(key)) {
              let form_group = form_template[group];
              let last_name_field = form_group[key];
              let first_name_field = form_group.first_name;
              if (!String(last_name_field.value).replace(/\s/g, '').length) {
                let full_name = String(first_name_field.value).replace(/\s+/g, ' ').trim();
                if (full_name.length > 1) {
                  full_name = this.NameCase(full_name, true);
                  for (let i = 0; i < full_name.length; i++) {
                    let field = form_group[`${!i ? 'first' : 'last'}_name`];
                    field.value = full_name[i];
                    field.invalid = false;
                  }
                }
              }
            }
            if (/email/.test(key)) {
              field.value = field.value.toLowerCase();
            }
            if (/company/.test(key)) {
              field.required = is_parcel_shop;
            }
            if (is_parcel_shop) {
              let shop_data_key = '';
              let street = form_data.street_address;
              switch (key) {
                case 'company': shop_data_key = 'company_name'; break;
                case 'street_address': shop_data_key = 'address'; break;
                case 'parcel_shop_id': shop_data_key = 'parcel_shop_id'; break;
                case 'zip_code': shop_data_key = 'zipcode'; break;
                case 'city': shop_data_key = 'city'; break;
                case 'country': shop_data_key = 'country_iso_2a';
              }
              if (/parcel_shop/.test(key)) {
                let shop_id = String(street).replace(shop_id_regex, '$2');
                if (/shop_id/.test(key)) {
                  field.required = true;
                  //field.readonly = parcel_shop_search;
                  field.readonly = false;
                  field.value = String(shop_id).replace(/\s/g, '');
                } else if (parcel_shop_search) {
                  field.fetching = true;
                  field.type = 'select';
                  field.options = this.parcel_shop.options;
                  field.change = (event) => {
                    let input = event.target;
                    let value = input.value;
                    input.blur();
                    if (value) {
                      let option = field.options.find(option => option.label == value);
                      if (option) for (let key in parcel_shop_fields) {
                        if (!/country/.test(key)) {
                          let form_field = parcel_shop_fields[key];
                          form_field.value = option.data[key];
                          form_field.invalid = false;
                        }
                      }
                      //field.invalid = !option;
                      field.caution = !option;
                    }
                  }
                } else {
                  delete form_template[group][key];
                }
              }
              if (/street/.test(key)) {
                let index = address_index(key);
                field.value = String(street).replace(shop_id_regex, '$1').trim();
                field.change = async (event) => {
                  let input = event.target;
                  let value = input.value.replace(/\s+/g, ' ').trim();
                  //let run_parcel_shop_search = true;
                  if (String(value).replace(/\s/g, '').length) {
                    if (new RegExp(shop_id_regex).test(value)) {
                      for (let key in parcel_shop_fields) {
                        if (/shop_id/.test(key)) {
                          let form_field = parcel_shop_fields[key];
                          let shop_id = String(value).replace(shop_id_regex, '$2');
                          form_field.value = String(shop_id).replace(/\s/g, '');
                          form_field.invalid = false;
                          break;
                        }
                      }
                    }
                  }
                  field.value = String(value).replace(shop_id_regex, '$1').trim();
                  field.invalid = !String(field.value).replace(/\s/g, '').length;
                  address[index] = field.value;
                  input.value = field.value;
                  /*
                  if (!field.invalid) {
                    let parcel_shop = shipping_address_form(true).parcel_shop;
                    let parcel_shop_street = String(parcel_shop.value).replace(/^(.*?) - (.*?), (.*?)$/, '$2');
                    let value_test = new RegExp(parcel_shop_street, 'i').test(field.value);
                    if (!value_test) value_test = new RegExp(field.value, 'i').test(parcel_shop_street);
                    run_parcel_shop_search = !value_test;
                  }
                  if (run_parcel_shop_search) {
                    get_parcel_shops();
                  }
                  */
                  get_parcel_shops();
                }
                address[index] = field.value;
              }
              if (shop_data_key) {
                field.shop_key = shop_data_key;
                parcel_shop_fields[shop_data_key] = field;
              }
            } else if (/parcel_shop/.test(key)) {
              delete form_template[group][key];
            }
            if (/zip|city/.test(key)) {
              let index = address_index(key);
              field.data = {};
              field.list = [];
              field.fetching = false;
              field.change = (event) => {
                let input = event.target;
                let value = String(input.value).replace(/\s+/g, ' ').trim();
                let bind = {field: null, value: null, index: null};
                let break_loop = false;
                for (let g in form_template) {
                  if (break_loop) break;
                  for (let k in form_template[g]) {
                    if (new RegExp(/zip/.test(key) ? 'city' : 'zip').test(k)) {
                      break_loop = true;
                      bind.field = form_template[g][k];
                      bind.index = address_index(k);
                      break;
                    }
                  }
                }
                if (/zip/.test(key)) {
                  value = String(value).replace(/s/g, '');
                  bind.value = field.data[value];
                } else {
                  bind.value = Object.keys(field.data).find(key => field.data[key] == value);
                }
                if (bind.value) {
                  bind.field.invalid = false;
                  bind.field.value = bind.value;
                }
                //field.invalid = field.list.length && !field.list.includes(value);
                field.caution = field.list.length && !field.list.includes(value);
                if (!field.value) {
                  field.caution = false;
                  field.invalid = true;
                } else {
                  field.invalid = false;
                }
                /*
                if (field.invalid && /city/.test(key)) {
                  field.invalid = !field.list.some(option => new RegExp(field.value, 'i').test(option) || new RegExp(option, 'i').test(field.value));
                }
                */
                field.value = value;
                input.value = value;
                address[bind.index] = bind.field.value;
                address[index] = field.value;
                if (!field.invalid && parcel_shop_search) {
                  get_parcel_shops();
                }
                input.blur();
              }
              address[index] = field.value;
            }
            if (/country/.test(key)) {
              field.list = this.CloneObject(this.country.options);
              field.code = this.iso[this.Capitalize(field.value)];
              field.value = this.CountryName(field.code);
              country = field.list.find(option => this.CountryName(option.code) == field.value) || {};
              field.change = async (event) => {
                let input = event.target;
                let value = String(input.value);
                if (value) value = Array.from(input.list.options).map(option => {
                  if (new RegExp(value, 'i').test(option.value)) {
                    field.value = option.value;
                    return option.dataset.value;
                  }
                }).filter(e => e)[0];
                country = field.list.find(option => option.label == value) || {};
                value = value ? this.iso[this.Capitalize(value)] : '';
                if (value) {
                  await get_postal_codes();
                  if (parcel_shop_search) {
                    get_parcel_shops();
                  }
                }
                field.invalid = !value;
                input.blur();
              }
            }
          }
        }
        this.parcel.shipping_address_form = form_template;
        this.modal.view_parcel.tab.shipping.editing = true;
        await get_postal_codes();
        if (parcel_shop_search) {
          get_parcel_shops();
        }
      },
      async GetPostalCodes(country_code) {
        return await new Promise((resolve, reject) => {
          if (!country_code) return reject();
          if (this.postal.hasOwnProperty(country_code)) {
            return resolve(this.postal[country_code]);
          }
          BPA.api.GetPostalCodes(country_code).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            let data = {};
            let result = response.result || [];
            for (let i = 0; i < result.length; i++) {
              data[result[i].zipcode] = result[i].city;
            }
            if (/at/.test(country_code)) {
              data[2020] = 'Hollabrunn';
              data[5101] = 'Bergheim';
              data[6372] = 'Oberndorf in Tirol';
            }
            if (/de/.test(country_code)) {
              data[25938] = 'Wrixum';
              data[97526] = 'Sennfeld';
              data[98749] = 'Steinheid';
            }
            if (/fr/.test(country_code)) {
              data[38970] = 'Corps';
            }
            if (/lu/.test(country_code)) {
              data[1273] = 'Luxembourg';
            }
            if (/nl/.test(country_code)) {
              data['1015GC'] = 'Amsterdam';
              data['3011ZX'] = 'Rotterdam';
            }
            this.postal[country_code] = data;
            resolve(this.postal[country_code]);
          }).catch(reject);
        }).catch(e => e);
      },
      async GetParcelShops(request = {}) {
        return await new Promise((resolve, reject) => {
          if (!Object.keys(request).length) return reject();
          const search_history = this.parcel_shop.search;
          const search_key = Object.values(request).join(' ').toLowerCase().replace(/\s|-/g, '_').replace(/[æøå]/g, (o) => {
            return {'æ': 'ae', 'ø': 'oe', 'å': 'aa', 'á': 'a', 'é': 'e'}[o];
          });
          const create_shop_options = () => {
            const result = search_history[search_key];
            const parcel_shop_options = [];
            const option_limit = 20;
            for (let item of result) {
              const option = {data: item};
              let meters = item.distance_m || 0;
              let unit = meters >= 1000 ? 'km' : 'm';
              let kilometers = meters / 1000;
              kilometers = this.NumberFormat(Math.round(kilometers * 100) / 100);
              let distance = (unit == 'km' ? kilometers : meters) + ' ' + unit;
              const strip_parcel_shop_id = (address) =>  String(address).replace(/\n?\s?pakkeshop:?.*$/i, '').replace(/\s+/g, ' ').trim();
              const address = (id) => strip_parcel_shop_id(item.address) + (id ? ' Pakkeshop: ' + id : '') + ', ' + item.zipcode + ' ' + item.city;
              option.id = item.parcel_shop_id;
              option.name = item.company_name;
              option.address = address(item.parcel_shop_id);
              option.label = '[' + item.parcel_shop_id + '] ' + option.name + ' - ' + address() + ' (' + distance + ')';
              option.data.address = strip_parcel_shop_id(option.data.address);
              parcel_shop_options.push(option);
            }
            if (parcel_shop_options.length > option_limit) {
              parcel_shop_options.length = option_limit;
            }
            this.parcel_shop.options = parcel_shop_options;
            return parcel_shop_options;
          }
          if (search_history[search_key]) {
            return resolve(create_shop_options());
          }
          BPA.api.GetParcelShops(request).then(response => {
            return BPA.api.response({response, return: 'text', error(){}});
          }).then(response => {
            if (!response.ok || !response.result) return reject([]);
            let result = response.result || '';
            if (!result || !Tool.IsJsonString(result)) {
              result = '{"items": []}';
            }
            result = JSON.parse(result).items;
            search_history[search_key] = result;
            resolve(create_shop_options());
          }).catch(reject);
        }).catch(e => e);
      },
      SubmitEditShippingAddress() {
        const shipping_address_form = this.CloneObject(this.parcel.shipping_address_form);
        const shipping_address = this.CloneObject(this.parcel.shipping_address);
        const is_parcel_shop = /shop/i.test(this.parcel.shipping_type) && !/shop|instabox/.test(this.parcel.shipping_courier);
        const form_data = {}
        const edited_fields = {};
        let modal_shipping_address_form = this.CloneObject(this.modal.view_parcel.tab.shipping.address_form);
        modal_shipping_address_form = modal_shipping_address_form.map(o => Object.entries(o)).flat();
        modal_shipping_address_form = modal_shipping_address_form.reduce((p, c) => {p[c[0]] = c[1]; return p}, {});
        for (let group of shipping_address_form) {
          for (let key in group) {
            let field = group[key];
            let value = field.value;
            form_data[key] = value;
            if (/country/.test(key) && value) {
              let country = field.list.find(option => this.CountryName(option.code) == value) || {};
              form_data[key] = country.label || '';
              form_data.country_id = country.id || '';
            }
            if (field.invalid) {
              let input = document.querySelector(`input[name=${field.name}]`);
              if (input) input.focus();
              return;
            }
          }
        }
        if (is_parcel_shop) {
          form_data.street_address = `${form_data.street_address} Pakkeshop: ${form_data.parcel_shop_id}`;
        }
        delete form_data.parcel_shop_id;
        delete form_data.parcel_shop;
        for (let key in form_data) {
          if (form_data[key] != shipping_address[key]) {
            edited_fields[key] = form_data[key];
          }
        }
        if (!Object.keys(edited_fields).length) {
          return this.modal.view_parcel.tab.shipping.editing = false;
        }
        /*
        console.log(this.CloneObject(form_data))
        console.log(this.CloneObject(edited_fields))
        console.log(modal_shipping_address_form)
        */
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, save it',
          disapprove: 'No',
          message: 'Saves changes to shipping address.',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', approve => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          BPA.api.EditShippingAddress({
            order_id: this.parcel.id,
            address_edit: edited_fields
          }).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return this.loading = false;
            const parcel = this.parcels.find(parcel => parcel.id == this.parcel.id);
            const shipping = this.modal.view_parcel.tab.shipping;
            let comment = 'Shipping address changed by ' + this.UserName() + '.\n';
            for (let key in edited_fields) {
              if (key != 'country_id') {
                let field_value = edited_fields[key];
                comment += modal_shipping_address_form[key] + ': "' + (shipping.address[key] || '') + '" → "' + (field_value || '') + '",\n';
              }
            }                
            for (let key in form_data) {
              shipping.address[key] = form_data[key];
            }
            [this.parcel, parcel].map(parcel => {
              parcel.manual_edits_shipping_address = true;
              parcel.shipping_address = shipping.address;
            });
            this.SubmitOrderComment({
              order_id: parcel.id,
              comment: comment.replace(/,\n$/, '.'),
              comment_type: 'addr',
              refresh: true,
              prompt: false
            }).then(() => {
              this.loading = false;
              shipping.editing = false;
              this.$eventHub.$emit('ShowMessages', {
                message: 'Shipping address successfully updated',
                type: 'success',
                hide: 2000
              });
            }).catch(() => this.loading = false);
          }).catch(() => this.loading = false);
        });
      },
      EditShippingAddress() {
        let invalid_input = false;
        let shipping_address_edit = {};
        const shipping_address_form = {};
        const shipping_address_form_inputs = {};
        const field = (name) => name.replace('edit-', '').replace(/-/g, '_');
        const modal_shipping_address = this.modal.view_parcel.tab.shipping.address;
        let modal_shipping_address_form = this.CloneObject(this.modal.view_parcel.tab.shipping.address_form);
        modal_shipping_address_form = modal_shipping_address_form.map(o => Object.entries(o)).flat();
        modal_shipping_address_form = modal_shipping_address_form.reduce((p, c) => {p[c[0]] = c[1]; return p}, {});
        let form_input_selector = '#shipping-address-form input';
        const get_input = (name_end) => document.querySelector(`${form_input_selector}[name$=${name_end}]`);
        const parcel_shop_id_input = get_input('parcel-shop-id');
        document.querySelectorAll(`${form_input_selector}:not([name*=parcel-shop])`).forEach(input => {
          const label = input.previousSibling;
          const field_name = field(input.name);
          shipping_address_form[field_name] = input.value;
          shipping_address_form_inputs[field_name] = input;
          if (/address/.test(field_name)) {
            if (/pakkeshop:/i.test(modal_shipping_address.street_address) && parcel_shop_id_input) {
              if (!parcel_shop_id_input.value) return invalid_input = parcel_shop_id_input;
              shipping_address_form[field_name] = `${input.value} Pakkeshop: ${parcel_shop_id_input.value}`;
            }
          }
          if (/country/.test(field_name)) {
            shipping_address_form[field_name] = Array.from(input.list.options).map(option => {
              if (option.value.toLowerCase() == String(input.value).toLowerCase()) return option.dataset.value;
            }).filter(e => e)[0];
          }
          input.label = label ? label.textContent : this.Capitalize(field_name.replace('_', ' '));
          if (input.classList.contains('invalid')) invalid_input = input;
        });
        for (let field in modal_shipping_address) {
          if (String(shipping_address_form[field]).toLowerCase() == String(modal_shipping_address[field]).toLowerCase()) {
            delete shipping_address_form[field];
          }
        }
        if (!Object.keys(shipping_address_form).length) {
          return this.modal.view_parcel.tab.shipping.editing = false;
        }
        if (invalid_input) return invalid_input.focus();
        if ('country' in shipping_address_form) {
          shipping_address_form.country = this.Capitalize(shipping_address_form.country);
          shipping_address_form.country_id = this.countries[shipping_address_form.country].id;
        }
        shipping_address_edit = this.CloneObject(shipping_address_form);
        delete shipping_address_edit.country;
        delete shipping_address_form.country_id;
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, save it',
          disapprove: 'No',
          message: 'Saves changes to shipping address.',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', approve => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          BPA.api.EditShippingAddress({
            order_id: this.parcel.id,
            address_edit: shipping_address_edit
          }).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return;
            const parcel = this.parcels.find(parcel => parcel.id == this.parcel.id);
            const shipping = this.modal.view_parcel.tab.shipping;
            let comment = 'Shipping address changed by ' + this.UserName() + '.\n';
            for (let field_name in shipping_address_edit) {
              let field_value = shipping_address_edit[field_name];
              if (field_name == 'country_id') {
                field_value = shipping_address_form.country;
              }
              field_name = field_name.replace('_id', '');
              comment += modal_shipping_address_form[field_name] + ': "' + (shipping.address[field_name] || '') + '" → "' + (field_value || '') + '",\n';
            }                
            for (let field_name in shipping_address_form) {
              shipping.address[field_name] = shipping_address_form[field_name];
            }
            [this.parcel, parcel].map(parcel => {
              parcel.manual_edits_shipping_address = true;
              parcel.shipping_address = shipping.address;
            });
            this.SubmitOrderComment({
              order_id: parcel.id,
              comment: comment.replace(/,\n$/, '.'),
              comment_type: 'addr',
              refresh: true,
              prompt: false
            }).then(() => {
              this.loading = false;
              shipping.editing = false;
              this.$eventHub.$emit('ShowMessages', {
                message: 'Shipping address successfully updated',
                type: 'success',
                hide: 2000
              });
            }).catch(() => this.loading = false);
          }).catch(() => this.loading = false);
        });
      },
      DownloadShippingLabel(parcel) {
        if (!parcel) return console.error('parcel missing');
        const order_id = parcel.id;
        const user_name = this.UserName();
        const view_parcel = this.modal.view_parcel;
        const active_tab = view_parcel.tab.active;
        //const parcel_order = this.parcels.find(order => order.id == parcel.id);
        const packing_station = BPA.printer.fetch('station');
        const shipping_agent =  parcel.shipping_courier || parcel.agent_code;
        const option = this.courier.options.find(option => option.code == shipping_agent);
        if (!packing_station) {
          this.$eventHub.$emit('ShowMessages', {
            message: 'Packing station name is missing',
            type: 'error',
            hide: 2000
          });
          return;
        }
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, download it',
          disapprove: 'No',
          message: 'Downloads a new' + (option ? ' ' + option.label : '') + ' shipping label',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', approve => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          BPA.api.DownloadShippingLabel({order_id, packing_station}).then(response => {
            return BPA.api.response({response, return: 'blob'});
          }).then(response => {
            this.loading = false
            if (!response.ok || !response.result) return;
            if (view_parcel.open && active_tab == 'tracking') {
              this.parcel.cancel_checked = true;
              let pack_log_item = {
                agent_code: option.code,
                cancellable: option.code == 'dao',
                date: Tool.DateToUTC(new Date()),
                loading: false,
                pos: packing_station,
                res_user_full_name: user_name,
                res_user_id: this.user.user_id
              };
              if (pack_log_item.cancellable) {
                pack_log_item.cancelled = false;
              }
              this.parcel.pack_log.unshift(pack_log_item);
              this.ViewParcelShow(parcel.id, view_parcel.tab.active);
            }
            /*
            if (parcel_order && parcel_order.pack_log.length) {
              parcel_order.pack_log.push('');
            } else {
              this.QueryParcels();
            }
            */
            this.QueryParcels();
            this.SubmitOrderComment({
              order_id: order_id, 
              comment: `${option ? `${option.label} shipping` : 'Shipping'} label downloaded by ${user_name}.\nDescription: "${parcel.shipping_description}".`,
              comment_type: 'dwnld',
              refresh: view_parcel.open,
              prompt: false
            }).then(() => {
              BPA.api.download({
                name: `[SHIPPING LABEL] ${parcel.company_name} - ${parcel.increment_id} (${parcel.shipping_description})`,
                blob: response.result,
                new_tab: BPA.browser == 'firefox'
              });
            });
          }).catch(e => e);
        });
      },
      SendReturnLabel(parcel) {
        if (!parcel) return console.error('parcel missing');
        const user_name = this.UserName();
        const view_parcel = this.modal.view_parcel;
        const parcel_order = this.parcels.find(order => order.id == parcel.id);
        const popup_return_label = this.popup.return_label;
        const packing_station = BPA.printer.fetch('station');
        if (!packing_station) {
          return this.$eventHub.$emit('ShowMessages', {
            message: 'Packing station is not selected',
            type: 'error',
            hide: 2000
          });
        }
        if (view_parcel.open) {
          popup_return_label.courier_default = popup_return_label.courier_options.find(courier => {
            return courier.code == view_parcel.tab.shipping.selected.agent;
          });
        } else {
          popup_return_label.courier_default = popup_return_label.courier_options.find(courier => {
            return courier.code == ('shipping_courier' in parcel ? parcel.shipping_courier : parcel.agent_code)
          });
        }
        popup_return_label.courier_selected = popup_return_label.courier_default;
        popup_return_label.courier_value = popup_return_label.courier_selected.label;
        popup_return_label.email_address = parcel.shipping_address.email;
        popup_return_label.action = 'send';
        popup_return_label.show = true;
        this.$eventHub.$on('ValidateModalStop', (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          let popup_selected_courier = popup_return_label.courier_selected;
          let type = parcel.shipping_courier == 'shop' ? 'bill' : 'shipp';
          let address = parcel[`${type}ing_address`] || {};
          let first_name = (address.first_name || '').split(' ')[0];
          let subject_translation = () => {
            let subject = '';
            switch (parcel.language.code) {
              case 'sv': subject = 'Här är din returetikett från'; break;
              case 'no': subject = 'Her er returetiketten din fra'; break;
              case 'de': subject = 'Hier ist Ihr Rücksendeetikett von'; break;
              case 'da': subject = 'Her er din returetikette fra'; break;
              default: subject = 'Here is your return label from';
            }
            return subject + ' ' + parcel.company_name;
          };
          this.loading = true;
          BPA.api.CreateReturnLabel({
            order_id: parcel.id,
            agent_code: popup_selected_courier.code,
            operation_code: 'email',
            packing_station: packing_station,
            email: popup_return_label.email_address,
            name: this.Capitalize(first_name || ''),
            lang: parcel.language.iso,
            subject: subject_translation()
          }).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            this.loading = false;
            if (!response.ok || !response.result) return;
            let json = response.result || {};
            popup_return_label.courier_default = {};
            popup_return_label.courier_selected = {};
            popup_return_label.courier_value = '';
            if (view_parcel.open) {
              this.ViewParcelShow(parcel.id, view_parcel.tab.active);
            }
            if (parcel_order && parcel_order.pack_log.length) {
              parcel_order.tracking = json.track_and_trace;
              parcel_order.courier = json.agent_code;
              parcel_order.pack_log.push('');
            } else {
              this.QueryParcels();
            }
            this.SubmitOrderComment({
              order_id: parcel.id,
              comment: `${popup_selected_courier.label} return label sent by ${user_name}.\nEmail: "${popup_return_label.email_address}".`,
              comment_type: 'email',
              refresh: view_parcel.open,
              prompt: false
            }).then(() => {
              this.$eventHub.$emit('ShowMessages', {
                message: 'Return label successfully created and sent to email',
                type: 'success',
                hide: 2000
              });
            });
          }).catch(e => e);
        });
      },
      PrintReturnLabel(parcel) {
        if (!parcel) return console.error('parcel missing');
        const user_name = this.UserName();
        const view_parcel = this.modal.view_parcel;
        const active_tab = view_parcel.tab.active;
        const parcel_order = this.parcels.find(order => order.id == parcel.id);
        const popup_return_label = this.popup.return_label;
        const printer_id = (BPA.printer.fetch('label') || {}).id;
        const packing_station = BPA.printer.fetch('station');
        if (!printer_id) {
          this.$eventHub.$emit('ShowMessages', {
            message: 'Label printer is not selected',
            type: 'error',
            hide: 2000
          });
          return;
        }
        if (!packing_station) {
          this.$eventHub.$emit('ShowMessages', {
            message: 'Packing station is not selected',
            type: 'error',
            hide: 2000
          });
          return;
        }
        if (view_parcel.open) {
          popup_return_label.courier_default = popup_return_label.courier_options.find(courier => {
            return courier.code == view_parcel.tab.shipping.selected.agent;
          });
        } else {
          popup_return_label.courier_default = popup_return_label.courier_options.find(courier => {
            return courier.code == ('shipping_courier' in parcel ? parcel.shipping_courier : parcel.agent_code)
          });
        }
        popup_return_label.courier_selected = popup_return_label.courier_default;
        popup_return_label.courier_value = popup_return_label.courier_selected.label;
        popup_return_label.action = 'print';
        popup_return_label.show = true;
        this.$eventHub.$on('ValidateModalStop', (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          const selected_agent_code = popup_return_label.courier_selected.code;
          const option = this.courier.options.find(option => option.code == selected_agent_code);
          this.loading = true;
          BPA.api.CreateReturnLabel({
            order_id: parcel.id,
            agent_code: selected_agent_code,
            operation_code: popup_return_label.action,
            packing_station: packing_station,
            printer_id: printer_id
          }).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            this.loading = false;
            if (!response.ok || !response.result) return;
            let json = response.result || {};
            popup_return_label.courier_default = {};
            popup_return_label.courier_selected = {};
            popup_return_label.courier_value = '';
            if (view_parcel.open && active_tab == 'tracking') {
              this.parcel.cancel_checked = true;
              let pack_log_item = {
                agent_code: option.code,
                cancellable: option.code == 'dao',
                date: Tool.DateToUTC(new Date()),
                loading: false,
                pos: packing_station,
                res_user_full_name: user_name,
                res_user_id: this.user.user_id
              };
              if (pack_log_item.cancellable) {
                pack_log_item.cancelled = false;
              }
              this.parcel.pack_log.unshift(pack_log_item);
              this.ViewParcelShow(parcel.id, view_parcel.tab.active);
            }
            if (parcel_order && parcel_order.pack_log.length) {
              parcel_order.tracking = json.track_and_trace;
              parcel_order.courier = json.agent_code;
              parcel_order.pack_log.push('');
            } else {
              this.QueryParcels();
            }
            this.SubmitOrderComment({
              order_id: parcel.id,
              comment: `${option ? `${option.label} return` : 'Return'} label printed by ${user_name}.`,
              comment_type: 'prnt',
              refresh: view_parcel.open,
              prompt: false
            }).then(() => {
              this.$eventHub.$emit('ShowMessages', {
                message: 'Return label successfully created and sent to label printer',
                type: 'success',
                hide: 2000
              });
            });
          }).catch(e => e);
        });
      },
      async OpenPDF(parcel_id) {
        let blob = await this.GetPdfAsBlob(parcel_id);
        let blobURL = URL.createObjectURL(blob);
        window.open(blobURL);
        URL.revokeObjectURL(blobURL);
      },
      async GetPdfAsBlob(parcel_id) {
        return await new Promise((resolve, reject) => {
          if (!parcel_id) return reject();
          this.loading = true;
          BPA.api.DownloadOrder(parcel_id).then(response => {
            return BPA.api.response({response, 
              return: () => {
                return response.arrayBuffer();
              }
            });
          }).then(response => {
            this.loading = false;
            if (!response.ok || !response.result) return reject();
            let arrayBuffer = response.result || {};
            resolve(new Blob([arrayBuffer], {type: 'application/pdf'}));
          }).catch(reject);
        }).catch(e => e);
      },
      DownloadParcelOrder(parcel) {
        let user_name = this.UserName();
        let view_parcel = this.modal.view_parcel;
        this.loading = true;
        BPA.api.DownloadOrder(parcel.id).then(response => {
          return BPA.api.response({response, return: 'blob'});
        }).then(response => {
          this.loading = false;
          if (!response.ok || !response.result) return;
          this.SubmitOrderComment({
            order_id: parcel.id,
            comment: `Parcel order PDF downloaded by ${user_name}`,
            comment_type: 'dwnld',
            refresh: view_parcel.open,
            prompt: false
          }).then(() => {
            BPA.api.download({
              name: `[PARCEL ORDER] ${parcel.company_name} - ${parcel.increment_id}`,
              blob: response.result,
              new_tab: BPA.browser == 'firefox'
            });
          });
        }).catch(e => e);
      },
      DownloadReturnLabel(parcel) {
        if (!parcel) return console.error('parcel missing');
        const user_name = this.UserName();
        const view_parcel = this.modal.view_parcel;
        const active_tab = view_parcel.tab.active;
        //const parcel_order = this.parcels.find(order => order.id == parcel.id);
        const popup_return_label = this.popup.return_label;
        const packing_station = BPA.printer.fetch('station');
        if (!packing_station) {
          this.$eventHub.$emit('ShowMessages', {
            message: 'Packing station name is missing',
            type: 'error',
            hide: 2000
          });
          return;
        }
        if (view_parcel.open) {
          popup_return_label.courier_default = popup_return_label.courier_options.find(courier => {
            return courier.code == view_parcel.tab.shipping.selected.agent;
          });
        } else {
          popup_return_label.courier_default = popup_return_label.courier_options.find(courier => {
            return courier.code == ('shipping_courier' in parcel ? parcel.shipping_courier : parcel.agent_code)
          });
        }
        popup_return_label.courier_selected = popup_return_label.courier_default;
        popup_return_label.courier_value = popup_return_label.courier_selected.label;
        popup_return_label.action = 'download';
        popup_return_label.show = true;
        this.$eventHub.$on('ValidateModalStop', (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          const popup_selected_courier = popup_return_label.courier_selected;
          this.loading = true;
          BPA.api.CreateReturnLabel({
            order_id: parcel.id,
            agent_code: popup_selected_courier.code,
            operation_code: popup_return_label.action,
            packing_station: packing_station
          }).then(response => {
            return BPA.api.response({response, return: 'blob'});
          }).then(response => {
            this.loading = false;
            if (!response.ok || !response.result) return;
            popup_return_label.courier_default = {};
            popup_return_label.courier_selected = {};
            popup_return_label.courier_value = '';
            if (view_parcel.open && active_tab == 'tracking') {
              this.parcel.cancel_checked = true;
              let pack_log_item = {
                agent_code: popup_selected_courier.code,
                cancellable: popup_selected_courier.code == 'dao',
                date: Tool.DateToUTC(new Date()),
                loading: false,
                pos: packing_station,
                res_user_full_name: user_name,
                res_user_id: this.user.user_id
              };
              if (pack_log_item.cancellable) {
                pack_log_item.cancelled = false;
              }
              this.parcel.pack_log.unshift(pack_log_item);
              this.ViewParcelShow(parcel.id, view_parcel.tab.active);
            }
            /*
            if (parcel_order && parcel_order.pack_log.length) {
              parcel_order.pack_log.push('');
            } else {
              this.QueryParcels();
            }
            */
            this.QueryParcels();
            this.SubmitOrderComment({
              order_id: parcel.id,
              comment: `${popup_selected_courier.label} return label downloaded by ${user_name}.`,
              comment_type: 'dwnld',
              refresh: view_parcel.open,
              prompt: false
            }).then(() => {
              BPA.api.download({
                name: `[RETURN LABEL] ${parcel.company_name} - ${parcel.increment_id} (${popup_selected_courier.label})`,
                blob: response.result,
                new_tab: BPA.browser == 'firefox'
              });
            });
          }).catch(e => e);
        });        
      },
      PrintShippingLabel(parcel, new_label) {
        if (!parcel) return console.error('parcel missing');
        const user_name = this.UserName();
        const view_parcel = this.modal.view_parcel;
        const active_tab = view_parcel.tab.active;
        //const parcel_order = this.parcels.find(order => order.id == parcel.id);
        const printer_id = (BPA.printer.fetch('label') || {}).id;
        const packing_station = BPA.printer.fetch('station');
        const shipping_agent =  parcel.shipping_courier || parcel.agent_code;
        const option = this.courier.options.find(option => option.code == shipping_agent);
        if (!printer_id) {
          return this.$eventHub.$emit('ShowMessages', {
            message: 'Label printer is not selected',
            type: 'error',
            hide: 2000
          });
        }
        if (!packing_station) {
          return this.$eventHub.$emit('ShowMessages', {
            message: 'Packing station name is missing',
            type: 'error',
            hide: 2000
          });
        }
        let message = 'Reprints the existing shipping label.\n\n<u><b>Do NOT use it to send the order again!</b></u>';
        if (new_label) message = 'Prints a new' + (option ? ' ' + option.label : '') + ' shipping label.';
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, print it',
          disapprove: 'No',
          message: message,
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', approve => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          BPA.api.PrintShippingLabel({
            order_id: parcel.id,
            printer_id: printer_id,
            packing_station: packing_station,
            disable_cache: new_label
          }).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            this.loading = false;
            if (!response.ok) return;
            if (view_parcel.open && active_tab == 'tracking') {
              this.parcel.cancel_checked = true;
              let pack_log_item = {
                agent_code: option.code,
                cancellable: option.code == 'dao',
                date: Tool.DateToUTC(new Date()),
                loading: false,
                pos: packing_station,
                res_user_full_name: user_name,
                res_user_id: this.user.user_id
              };
              if (pack_log_item.cancellable) {
                pack_log_item.cancelled = false;
              }
              this.parcel.pack_log.unshift(pack_log_item);
              this.ViewParcelShow(parcel.id, view_parcel.tab.active);
            }
            this.QueryParcels();
            this.SubmitOrderComment({
              order_id: parcel.id,
              comment: `${option ? `${option.label} shipping` : 'Shipping'} label printed by ${user_name}.\nDescription: "${parcel.shipping_description}".`,
              comment_type: 'prnt',
              refresh: view_parcel.open,
              prompt: false
            }).then(() => {
              this.$eventHub.$emit('ShowMessages', {
                message: 'Shipping label sucessfully created and sent to label printer',
                type: 'success',
                hide: 2000
              });
            });
          }).catch(e => e);
        });
      },
      ToggleOrderPin(parcel, in_modal) {
        console.log(parcel, in_modal)
        let pinned = parcel.has_pinned;
        this.$eventHub.$emit('ValidateModalStart', {
          approve: `Yes, ${pinned ? 'un': ''}pin it`,
          disapprove: 'No',
          message: `${pinned ? 'Unp' : 'P'}ins the current order.`,
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', approve => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          console.log(pinned, !pinned ? parcel.id : pinned)
          BPA.api.ToggleOrderPin({toggle: !pinned, target_id: !pinned ? parcel.id : pinned, }).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return;
            console.log(response.result)
          }).catch(e => e);
        });
      },
      ToggleOrderLock(parcel, in_modal) {
        const ShowLockNotePrompt = () => {
          this.$eventHub.$emit('ShowAlert', {
            title: 'Lock note required', 
            message: `<div style="width: 400px;">
              <div style="display: flex; flex-direction: column;">
                <textarea maxlength="265" class="alert-textarea" style="height: 5em; font-size: 1em; color: #333; padding: 7px; white-space: normal; outline: none; border-radius: 4px; border: 1px solid rgba(60, 60, 60, 0.26); resize: vertical; overflow-y: auto;"></textarea>
              </div>
            </div>`,
            persist: true,
            button: 'Lock order'
          });
          this.$nextTick().then(() => {
            const self = this;
            const popup = document.querySelector('.alert-popup');
            const textarea = popup.querySelector('.alert-textarea');
            const action = popup.querySelector('.alert-action');
            const submit = action.querySelector('.alert-button');
            const cancel = document.createElement('button');
            const style = {
              border: 0,
              padding: '10px',
              outline: 'none',
              fontWeight: 600,
              color: '#ffffff',
              minWidth: '10em',
              cursor: 'pointer',
              userSelect: 'none',
              textAlign: 'center',
              marginRight: '10px',
              borderRadius: '10px',
              fontSize: '0.7778rem',
              textDecoration: 'none',
              backgroundColor: '#1b577a'
            }
            function cancelLock() {
              self.$eventHub.$emit('HideAlert');
              alertEventListeners('remove');
            }
            function submitLock() {
              let note = textarea.value.replace(/[^\S\r\n]+/g, ' ').replace(/\n\s*\n/g, '\n').split('\n').map(x => x && x.trim()).join('\n');
              if (!note) {
                self.$eventHub.$emit('ShowMessages', {
                  message: 'Missing lock note',
                  type: 'error',
                  hide: 2000
                });
                return self.ToggleOrderLock(parcel, in_modal);
              }
              note = self.Capitalize(note);
              BPA.api.CreateLock({id: parcel.id, note: note}).then(response => {
                return BPA.api.response({response, return: 'json'});
              }).then(response => {
                alertEventListeners('remove');
                if (!response.ok || !response.result) return;
                let json = response.result;
                if (in_modal) {
                  self.modal.view_parcel.lock = json || {};
                  self.modal.view_parcel.locked = true;
                }
                let comment = 'Parcel locked by ' + self.UserName() + '.\n"' + note + '".';
                let update = in_modal && self.modal.view_parcel.tab.active == 'comments';
                self.SubmitOrderComment({
                  order_id: parcel.id,
                  comment: comment,
                  comment_type: 'lkd',
                  refresh: update,
                  prompt: false
                }).then(() => {
                  self.$eventHub.$emit('ShowMessages', {
                    message: 'Parcel is now locked',
                    type: 'success',
                    hide: 2000
                  });
                  self.QueryParcels(!in_modal);
                });
              });
            }
            function alertEventHandler(event) {
              const element = event.target;
              if (event.type == 'keydown') {
                if (event.key == 'Escape') {
                  cancelLock();
                }
              }
              if (event.type == 'click') {
                if ([popup, cancel].includes(element)) {
                  cancelLock();
                }
                if (element == submit) {
                  submitLock(event);
                }
              }
            }
            function alertEventListeners(state) {
              if (!in_modal) self.ScanInputHandler(state != 'add');
              ['click', 'keydown'].map(e => popup[state + 'EventListener'](e, alertEventHandler));
            }
            for (let prop in style) cancel.style[prop] = style[prop];
            cancel.className = 'alert-button cancel';
            cancel.textContent = 'Cancel';
            submit.classList.add('red');
            action.prepend(cancel);
            alertEventListeners('add');
            textarea.focus();
          });
        }
        if (!in_modal ? !parcel.is_locked : !this.modal.view_parcel.locked) {
          ShowLockNotePrompt();
          return;
        }
        if (!in_modal) this.ScanInputHandler(false);
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, unlock order',
          disapprove: 'No',
          message: 'Unlocks the current order.',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', approve => {
          this.$eventHub.$off('ValidateModalStop');
          if (!in_modal) this.ScanInputHandler();
          if (!approve) return;
          BPA.api.DeleteLock(parcel.id).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return;
            this.QueryParcels(!in_modal);
            this.modal.view_parcel.lock = {};
            this.modal.view_parcel.locked = false;
            if (in_modal) {
              const tabs = document.querySelector('#view-parcel .modal-tabs__head');
              const lock = tabs && tabs.querySelector('li[data-tab=lock]');
              if (lock && lock.classList.contains('active')) {
                const last = tabs.querySelector('li:last-child');
                if (last == lock) {
                  const prev = lock.previousSibling;
                  prev && prev.dispatchEvent(new Event('click'));
                } else {
                  const next = lock.nextSibling;
                  next && next.dispatchEvent(new Event('click'));
                }
              }
            }
            let comment = 'Parcel unlocked by ' + this.UserName() + '.';
            let update = in_modal && this.modal.view_parcel.tab.active == 'comments';
            this.SubmitOrderComment({
              order_id: parcel.id,
              comment: comment,
              comment_type: 'unlkd',
              refresh: update,
              prompt: false
            }).then(() => {
              this.$eventHub.$emit('ShowMessages', {
                message: 'Parcel is now unlocked',
                type: 'success',
                hide: 2000
              });
            });
          }).catch(e => e);
        });
      },
      HandleDatalist(e) {
        let event = e.type;
        let input = e.target;
        if (event == 'input') {
          clearTimeout(input.timer);
          let copied = input.parentElement.nextSibling;
          Tool.CopyToClipboard(input.value).then(() => {
            copied.classList.add('display');
            input.timer = setTimeout(() => {
              copied.classList.remove('display');
            }, 1000);
          });
        }
        e.preventDefault();
        input.value = '';
      },
      CopyOrderURL(order = {}) {
        if (!order.id) return;
        let location = window.location;
        let port = location.port;
        let protocol = location.protocol;
        let host = location.hostname.split('.');
        let pathname = location.pathname.replace(/\/$/, '');
        host.splice(0, 1, BPA.company('code', order.company_id));
        host = protocol + '//' + host.join('.') + (port ? ':' + port : '') + pathname;
        let order_number = order.company_id + ':' + order.increment_id;
        let order_url = host + '?id=' + order.id + '&order=' + order_number;
        Tool.CopyToClipboard(order_url).then(() => {
          this.$eventHub.$emit('ShowMessages', {
            message: 'Order URL copied to clipboard',
            type: 'success',
            hide: 2000
          });
        });
      },
      OpenInCMS(parcel) {
        let link_url = this.cms_order_link[parcel.company_id];
        if (!link_url) {
          return this.$eventHub.$emit('ShowMessages', {
            message: 'Missing CMS link URL',
            type: 'error',
            hide: 2000
          });
        }
        let order_id = link_url.match(/{order_id}/);
        if (!order_id) {
          return this.$eventHub.$emit('ShowMessages', {
            message: 'Missing {order_id} in order CMS link',
            type: 'error',
            hide: 2000
          });
        }
        let url = link_url.replace(order_id[0], parcel.order_id);
        //url += (!url.endsWith('/') ? '/' : '');
        window.open(url, '_blank');
      },
      async CreateNewCustomer(parcel = {}) {
        /*
        if (parcel.customer_exists) {
          this.$eventHub.$emit('ValidateModalStart', {
            approve: 'Yes, open it',
            disapprove: 'No',
            message: 'Opens existing customer.',
            type: 'success'
          });
          this.$eventHub.$on('ValidateModalStop', (approve) => {
            this.$eventHub.$off('ValidateModalStop');
            if (!approve) return;
            let query = null;
            console.log('parcel', this.CloneObject(parcel))
            if (parcel.customer['shipping_address.mail']) {
              query = {email: parcel.shipping_address.email};
            } else if (parcel.customer['shipping_address.tlf']) {
              query = {phone: parcel.shipping_address.phone_number};
            }
            if (!query) {
              return this.$eventHub.$emit('ShowMessages', {
                message: 'Something went wrong...',
                type: 'error',
                hide: 2000
              });
            }
            this.SearchCustomersBy(query);
          });
          return;
        }
        */
        let type = parcel.shipping_courier == 'shop' ? 'bill' : 'shipp';
        await this.CopyDataToClipboard({create: parcel[`${type}ing_address`]});
        window.open(window.location.origin + '/parcels/customers', '_blank');
      },
      async CreateCustomReturnLabel(parcel = {}) {
        await this.CreateCustomLabel(parcel, 'return');
      },
      async CreateCustomShippingLabel(parcel = {}) {
        await this.CreateCustomLabel(parcel, 'shipping');
      },
      async CreateCustomLabel(parcel = {}, label_type = 'shipping') {
        this.$eventHub.$emit('HideMessages');
        let address_recipient = parcel.shipping_address;
        let shipping_method = await this.GetLabelGeneratorSetup();
        let country_shipping = await this.GetCountryShippingAgentMethods();
        let country_options = BPA.api.Countries('GET').map(country => ({
          id: country.id, code: country.iso_code_2, label: country.name
        }));
        let agent = this.courier.options.find(option => option.code == parcel.shipping_courier);
        if (parcel.shipping_courier == 'shop') {
          parcel.shipping_description = 'GLS - Erhvervsadresse';
          agent = this.courier.options.find(option => option.code == 'gls');
          address_recipient.first_name = parcel.billing_address.first_name;
          address_recipient.last_name = parcel.billing_address.last_name;
        }
        let sender_country = country_options.find(option => option.code == 'DK');
        let recipient_country = country_options.find(option => option.label == address_recipient.country);
        let sender_recipient = {shipping: `DK_${recipient_country.code}`, return: `${recipient_country.code}_DK`}[label_type];
        if (sender_recipient == 'NO_DK') {
          sender_recipient = 'NO_NO';
          recipient_country = country_options.find(option => option.code == 'NO');
        }
        let abroad_shipment = /^DK/.test(sender_recipient) && !/DK$/.test(sender_recipient);
        let country_shipping_agents = country_shipping[sender_recipient] || {};
        let country_shipping_methods = country_shipping_agents[agent.code] || [];
        let shipping_description = this.shipping.options.find(option => option.code == parcel.shipping_description);
        let shipping_description_type = shipping_description.type.trim();
        let shipping_method_options = (shipping_method[agent.code] || []).filter(option => country_shipping_methods.includes(option.code));
        if (!country_shipping_methods.length) {
          let shipment = {sender_country, recipient_country};
          if (label_type == 'return') {
            shipment.sender_country = recipient_country;
            shipment.recipient_country = sender_country;
          }
          this.$eventHub.$emit('ShowMessages', {
            message: `No ${label_type} method with ${agent.label} from ${shipment.sender_country.label} to ${shipment.recipient_country.label}`,
            type: 'error',
            hide: 5000
          });
          return;
        }
        let shipping_mehod = (() => {
          for (let option of shipping_method_options) {
            if (label_type == 'shipping') {
              switch (shipping_description_type) {
                case 'pakkeshop': {
                  switch (option.code) {
                    case 'shop': {
                      let bulk_option = shipping_method_options.find(option => /bulk/.test(option.code));
                      if (bulk_option) return bulk_option;
                      return option;
                    }
                    case 'shop_bulk': return option;
                    case 'parcel_shops': return option;
                    case 'collect_in_store': return option;
                  }
                  break;
                }
                case 'commercial': {
                  switch (option.code) {
                    case 'private': {
                      let customs_option = shipping_method_options.find(option => /customs/.test(option.code));
                      if (customs_option && abroad_shipment) return customs_option;
                      return option;
                    }
                    case 'private_with_customs_declaration': return option;
                    case 'direkte': {
                      let abroad_option = shipping_method_options.find(option => /udland/.test(option.code));
                      if (abroad_option && abroad_shipment) return abroad_option;
                      return option;
                    }
                    case 'direkte_udland': return option;
                    case 'parcel': return option;
                    case 'express': return option;
                    case 'instahome': return option;
                  }
                  break;
                }
                case 'business_address': {
                  switch (option.code) {
                    case 'business': return option;
                    default: {
                      console.log(option)
                    }
                  }
                }
              }
            } else if (label_type == 'return') {
              switch (option.code) {
                case 'shop_return': {
                  let bulk_option = shipping_method_options.find(option => /bulk/.test(option.code));
                  if (bulk_option) return bulk_option;
                  return option;
                }
                case 'shop_return_bulk': return option;
                case 'return': return option;
                case 'return_standalone': return option;
              }
            }
          }
        })();
        if (shipping_mehod && /customs/.test(shipping_mehod.code)) {
          let order = await BPA.api.GetParcel(parcel.id).then(response => {
            return BPA.api.response({response, return: 'json', error: (message) => {
              if (response && response.status == 401) return response;
              this.$eventHub.$emit('ShowMessages', {message, type: 'error', close: true});
            }});
          }).then(response => response.result);
          if (order) parcel = {...order, ...parcel};
        }
        await this.CopyDataToClipboard({[label_type]: parcel});
        window.open(window.location.origin + '/parcels/shipments', '_blank');
      },
      async SearchCustomersBy(params = {}) {
        await this.CopyDataToClipboard({search: params});
        window.open(window.location.origin + '/parcels/customers', '_blank');
      },
      async SearchOrdersBy(params = {}) {
        await this.CopyDataToClipboard({search: params});
        window.open(window.location.origin + '/parcels/orders', '_blank');
      },
      async GetOrderCmsStatusHistory(order_id) {
        if (this.modal.view_parcel.tab.status.loaded) return;
        this.modal.view_parcel.loading = true;
        await BPA.api.GetOrderCmsStatusHistory(order_id).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          this.modal.view_parcel.loading = false;
          if (!response.ok) return;
          let list = response.result || [];
          //console.log(this.CloneObject(list))
          const status = ['pending', 'processing', 'complete', 'closed'];
          this.modal.view_parcel.tab.status.history = list;
          this.modal.view_parcel.tab.status.history.map((item, i) => {
            if (!item.comment) { // Remove empty entries
              this.modal.view_parcel.tab.status.history.splice(i, 1);
            } else {
              let packship = 'Packship: ';
              let username = new RegExp(/:\s\[(.*?)\]/);
              let match = username.exec(item.comment);
              /*
              if (i == list.length - 2) {
                item.comment = packship + item.comment;
              }
              */
              item.cms = !(new RegExp(packship, 'i')).test(item.comment);
              username = match ? match[1] : '';
              if (username.length > 50) {
                username = username.split(' ');
                username = username[0] + ' ' + username.slice(-1)[0];
              }
              item.created_by = item.cms ? 'CMS' : username ? username : 'PackShip';
              item.comment = String(this.Capitalize(item.comment.slice(!item.cms ? match ? match.index + match[0].length + 1 : packship.length : 0)) || '').replace(/^packship: /i, '');
              item.status = status.find(status => item.status.toLowerCase().includes(status)) || item.status;
            }
          });
          //console.log(this.CloneObject(this.modal.view_parcel.tab.status.history))
          this.modal.view_parcel.tab.status.loaded = true;
        }).catch(e => e);
      },
      UpdateOrderCmsStatusMessage(e) {
        if (e.target) this.modal.view_parcel.tab.status.message = e.target.value.trim();
      },
      async SubmitOrderCmsStatusMessage(order_id, comment = '', username = true, bypass = false, update = true) {
        return await new Promise((resolve, reject) => {
          if (!order_id) return reject(); 
          comment = comment || this.modal.view_parcel.tab.status.message || '';
          if (!comment) return reject(); 
          const submitMessage = () => {
            const params = {
              order_id: order_id,
              request: {mgs: this.Capitalize(comment)}
            }
            if (!bypass) {
              params.request.mgs = (username ? 'Packship: [' + this.UserName() + '] ' : '') + comment;
            }
            this.modal.view_parcel.loading = true;
            BPA.api.UpdateOrderCmsStatusHistory(params).then(response => {
              return BPA.api.response({response});
            }).then(async response => {
              this.modal.view_parcel.loading = false;
              if (!response.ok) return reject();
              resolve(response);
              await this.SubmitOrderComment({
                order_id: order_id,
                comment: comment,
                comment_type: 'usr',
                refresh: update,
                prompt: false
              });
              if (!update) return this.modal.view_parcel.tab.status.loaded = false;
              const history = this.modal.view_parcel.tab.status.history || [];
              const prev_status = history.length && history[0].status;
              const now = new Date();
              this.modal.view_parcel.tab.status.history.unshift({
                comment: Tool.Capitalize(comment),
                created_at: Tool.DateToUTC(now),
                status: prev_status ? prev_status : '',
                created_by: username ? this.UserName() : 'PackShip'
              });
              this.modal.view_parcel.tab.status.message = '';
              const input = document.querySelector('#view-parcel .message-history input');
              if (input) input.value = this.modal.view_parcel.tab.status.message;
              if (bypass) return;
              this.$eventHub.$emit('ShowMessages', {
                message: 'Order CMS status history updated',
                type: 'success',
                hide: 2000
              });
            }).catch(reject);
          }
          if (bypass) return submitMessage();
          this.$eventHub.$emit('ValidateModalStart', {
            approve: 'Yes, save comment',
            disapprove: 'No',
            message: 'Updates order CMS status history.',
            type: 'success'
          });
          this.$eventHub.$on('ValidateModalStop', (approve) => {
            this.$eventHub.$off('ValidateModalStop');
            if (!approve) return reject();
            submitMessage();
          });
        }).catch(e => e);
      },
      async SubmitOrderComment(params = {}) {
        //console.log(this.CloneObject(params))
        return await new Promise((resolve, reject) => {
          const required = ['order_id', 'comment', 'comment_type'];
          const submit = () => this.SetOrderComment(params).then((response) => {
            if (response.comment_type == 'usr') {
              this.modal.view_parcel.tab.comments.value = '';
            }
            if (response.refresh) {
              const json = this.modal.view_parcel.tab.comments.json;
              if (Object.keys(json).length) {
                json.res_user_ids[this.user.user_id] = this.user.display_name;
                json.items.push({
                  id: json.items.length + 1,
                  create_by: this.user.user_id,
                  create_date: Tool.DateToUTC(new Date()),
                  type: response.comment_type,
                  value: response.comment
                });
                this.FilterOrderComments();
              }
            }
            if (response.prompt) {
              this.$eventHub.$emit('ShowMessages', {
                message: 'Order comment submitted',
                type: 'success',
                hide: 2000
              });
            }
            resolve();
          }).catch(reject);
          if (!required.every((prop) => params[prop])) {
            return reject();
          }
          params.comment = Tool.Capitalize(params.comment);
          if (!params.prompt) {
            return submit();
          }
          this.$eventHub.$emit('ValidateModalStart', {
            approve: 'Yes, save comment',
            disapprove: 'No',
            message: 'Submits new order comment.',
            type: 'success'
          });
          this.$eventHub.$on('ValidateModalStop', (approve) => {
            this.$eventHub.$off('ValidateModalStop');
            if (!approve) return; 
            submit();
          });
        });
      },
      async SetOrderComment(params = {}) {
        //console.log(this.CloneObject(params))
        if (!Object.keys(params).length) return;
        const argument = {...this.CloneObject(params)};
        const required = ['order_id', 'comment', 'comment_type'];
        for (let p in params) if (!required.includes(p)) delete params[p];
        if (!required.every((prop) => params[prop])) return;
        return await new Promise((resolve, reject) => {
          this.modal.view_parcel.loading = true;
          BPA.api.SetOrderComment(params).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            this.modal.view_parcel.loading = false;
            if (!response.ok) return;
            resolve({...argument, ...response.result});
          }).catch(reject);
        }).catch(e => e);
      },
      async GetOrderComments() {
        const params = Array.from(arguments);
        if (!params.length) return;
        if (this.modal.view_parcel.tab.comments.loaded) return;
        this.modal.view_parcel.loading = true;
        return await BPA.api.GetOrderComments(params).then(response => {
          this.modal.view_parcel.loading = false;
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok) return;
          let json = response.result || {};
          this.modal.view_parcel.tab.comments.loaded = true;
          this.modal.view_parcel.tab.comments.json = this.CloneObject(json);
          //this.modal.view_parcel.tab.comments.list = this.CommentList(json);
          this.FilterOrderComments();
        }).catch(e => e);
      },
      CommentList(json = {}) {
        const list = [];
        const items = json.items || [];
        const users = json.res_user_ids || {};
        for (let i = 0; i < items.length; i++) {
          const item = items[i] || {};
          const user_id = item.create_by || null;
          const user_name = users[user_id] || 'PackShip';
          const side = item.type != 'usr' ? 'left' : 'right';
          const name = side == 'right' ? user_name : 'PackShip';
          list.push({
            text: Tool.Capitalize(item.value),
            date: item.create_date,
            type: item.type,
            user: user_id,
            id: item.id,
            side, name
          });
        }
        return list.sort((a, b) => {
          a = new Date(a.date);
          b = new Date(b.date);
          return b < a ? -1 : b > a ? 1 : 0;
        });
      },
      CommentCategory(category = {}) {
        const categories = this.modal.view_parcel.tab.comments.categories;
        if (!Object.keys(category).length) {
          const options = Object.keys(categories);
          for (let i = 0; i < options.length; i++) {
            options[i] = {value: options[i], label: categories[options]};
          }
          return this.Alphabetize(options, 'label');
        }
        if (category.value) {
          //return Object.keys(categories).find(value => categories[value] == category.value);
          let category_values = category.value.split(',');
          return Object.keys(categories).filter(value => category_values.includes(categories[value]));
        }
        if (category.label) {
          return categories[category.label];
        }
      },
      FilterOrderComments() {
        const comments = this.modal.view_parcel.tab.comments;
        const json = JSON.parse(JSON.stringify(comments.json));
        //const category = this.CommentCategory({value: comments.category});
        const categories = this.CommentCategory({value: comments.category}) || [];
        //if (category) json.items = json.items.filter(comment => comment.type == category);
        if (categories.length) json.items = json.items.filter(comment => categories.includes(comment.type));
        /* New search query feature */
        if (!categories.length && comments.category) {
          let search = String(comments.category).replace(/\s\s+/g, ' ').trim().split(' ');
          const test =  (substring, string) => new RegExp(substring, 'ig').test(string);
          json.items = json.items.filter(comment => search.some(query => test(query, comment.value) || test(query, json.res_user_ids[comment.create_by])));
        }
        comments.list = this.CommentList(json);
      },
      OrderCommentsDataList(event) {
        if (event.key == 'Enter') {
          event.target.blur();
        }
        if (event.key == 'Escape') {
          this.modal.view_parcel.tab.comments.category = '';
          this.FilterOrderComments();
        }
      },
      async ResetBulk() {
        return await new Promise(resolve => {
          let bulk = this.bulk;
          let select = bulk.select;
          let action = bulk.action;
          let popup = action.popup;
          select.visible = ['select_all', 'select_page'];
          select.orders = {all: [], checked: [], unchecked: []};
          select.all = false;
          action.visible = false;
          popup.selected = '';
          popup.show = false;
          popup.options = [];
          popup.message = '';
          popup.title = '';
          resolve(true);
        });
      },
      async BulkAction(option = {}) {
        let bulk = this.bulk;
        let action = bulk.action;
        let popup = action.popup;
        let selected = this.SelectedOrders();
        let options = [];
        let event = {
          reset() {
            action.selected = '';
            popup.selected = '';
            popup.show = false;
            popup.options = [];
            popup.message = '';
            popup.title = '';
            popup.date = '';
          },
          mousedown: (e) => {
            event.mousedown.target = e.target;
          },
          mouseup: async (e) => {
            if (e.target != event.mousedown.target) return;
            let class_names = ['validate-popup', 'btn-stateless cancel', 'btn-success confirm'];
            if (class_names.includes(e.target.className)) {
              if (/confirm/.test(e.target.className)) {
                let select = bulk.select;
                let code = action.selected.code;
                let message = '';
                const get_order_ids = async () => {
                  if (select.all) {
                    if (!select.orders.all.filter(id => id).length) {
                      select.orders.all = await this.GetAllOrderIDs();
                    }
                    if (select.orders.unchecked.length) {
                      select.orders.checked = select.orders.all.filter(id => !select.orders.unchecked.includes(id));
                    } else {
                      select.orders.checked = select.orders.all;
                    }
                  }
                  return select.orders.checked;
                }
                if (code == 'change_status') {
                  if (!popup.selected) {
                    select = this.$refs.bulk_action_popup_select;
                    return select && select.$refs.search.focus();
                  }
                  this.loading = true;
                  let state = popup.selected.code;
                  let orders = await get_order_ids();
                  if (await this.SetOrderStateBulk({state, orders})) {
                    message = 'All order states successfully updated';
                    for (let parcel of this.parcels) {
                      if (orders.includes(parcel.id)) {
                        parcel.state = state;
                      }
                    }
                  }
                }
                if (code == 'send_message') {
                  let textarea = this.$refs.bulk_action_popup_textarea;
                  //let date_input = document.getElementById('bulk_action_popup_date-input');
                  let sms_send_at = Tool.DateToUTC(popup.date);
                  //console.log(popup.date, sms_send_at)
                  if (!textarea.value.replace(/\s|\n/g, '').trim().length) {
                    return textarea.focus();
                  }
                  let request = {mgs: textarea.value, sms_send_at};
                  let orders = await get_order_ids();
                  console.log(this.CloneObject(request), this.CloneObject(orders))
                  return;
                }
                this.loading = false;
                this.HandleDropdownOption({code: 'deselect_all'});
                if (message) this.$eventHub.$emit('ShowMessages', {
                  message, type: 'success', hide: 2000
                });
              }
              event.listeners.remove();
              event.reset();
            }
          },
          keydown(e) {
            if (/escape/i.test(e.key)) {
              event.listeners.remove();
              event.reset();
            }
          },
          listeners: {
            add() {
              for (let listener of ['mousedown', 'mouseup', 'keydown']) {
                document.addEventListener(listener, event[listener], false);
              }
            },
            remove() {
              for (let listener of ['mousedown', 'mouseup', 'keydown']) {
                document.removeEventListener(listener, event[listener]);
              }
            }
          }
        }
        popup.title = this.$t(option.label);
        popup.message = `You have selected <b>${this.NumberFormat(selected)}</b> order${selected != 1 ? 's' : ''}.`;
        if (selected >= 10000) popup.message += `\n\nPlease be patient and <b>DO NOT</b>\nrefresh or leave this page while\nthe action is being carried out!`;
        if (option.code == 'change_status') {
          options = this.CloneObject(this.state.options)
        }
        if (option.code == 'send_message') {
          let templates = await this.GetOrderSMSTemplateList();
          for (let template of templates) {
            template.label = template.title;
            delete template.title;
            options.push(template);
          }
          popup.variable = null;
          popup.selection = {
            start: 0, 
            end: 0
          }
          popup.args = [
            {code: 'id', label: 'ID'},
            {code: 'language', label: 'Language'},
            {code: 'increment_id', label: 'Order number'},
            {code: 'first_name', label: 'First name'},
            {code: 'last_name', label: 'Last name'}
          ]
        }
        popup.options = options;
        event.listeners.add();
        popup.show = true;
      },
      async BulkActionPopupSelect(option) {
        let action = this.bulk.action;
        let popup = action.popup;
        if (action.selected.code == 'send_message') {
          if (option) {
            if (!option.template) {
              let template = await this.GetOrderSMSTemplate(option.id);
              if (template) option.template = template.text;
            }
            if (popup.args && popup.args.some(arg => arg.code == option.code)) {
              let arg = popup.args.find(arg => arg.code == option.code);
              console.log(arg);
            }
          }
          let textarea = this.$refs.bulk_action_popup_textarea;
          textarea.value = (option || {}).template || '';
          this.FitToContent({target: textarea});
        }        
        popup.selected = option || '';
        popup.show = false; // Force update
        popup.show = true;
      },
      SelectedOrders() {
        let orders = this.bulk.select.orders;
        let all = orders.all.length - orders.unchecked.length;
        let checked = orders.checked.length;
        return (all < 0 ? 0 : all) || (checked < 0 ? 0 : checked);
      },
      HandleDropdownTrigger(event) {
        let trigger = event.target;
        if (trigger.checked) {
          let mousedown = (e) => {
            if (!trigger.parentElement.contains(e.target)) {
              window.removeEventListener('mousedown', mousedown);
              trigger.checked = false;
            }
          }
          window.addEventListener('mousedown', mousedown, false);
        }
      },
      HandleDropdownOption(option) {
        let bulk = this.bulk;
        let select = bulk.select;
        let action = bulk.action;
        switch (option.code) {
          case 'select_all': {
            let index = select.visible.indexOf(option.code);
            if (index > -1) select.visible[index] = 'deselect_all';
            index = select.visible.indexOf('select_page');
            if (index > -1) select.visible.splice(index, 1);
            select.visible = [...select.visible, ...['deselect_page']];
            select.orders.unchecked = [];
            for (let parcel of this.parcels) {
              if (!select.orders.checked.includes(parcel.id)) {
                select.orders.checked.push(parcel.id);
              }
              parcel.checked = true;
            }
            select.orders.all.length = this.entries;
            action.visible = false; // Force update
            action.visible = true;
            select.all = true;
            break;
          }
          case 'deselect_all': {
            select.orders.checked = [];
            select.visible = ['select_all', 'select_page'];
            select.orders.unchecked = [];
            for (let parcel of this.parcels) {
              parcel.checked = false;
            }
            select.orders.all = [];
            action.visible = true; // Force update
            action.visible = false;
            select.all = false;
            break;
          }
          case 'select_page': {
            let index = select.visible.indexOf(option.code);
            if (index > -1) select.visible[index] = 'deselect_page';
            if (!select.visible.includes('deselect_all')) {
              select.visible.splice(1, 0, 'deselect_all');
            }
            for (let parcel of this.parcels) {
              if (!select.orders.checked.includes(parcel.id)) {
                select.orders.checked.push(parcel.id);
              }
              let index = select.orders.unchecked.indexOf(parcel.id);
              if (index > -1) select.orders.unchecked.splice(index, 1);
              parcel.checked = true;
            }
            if (select.orders.checked.length == this.entries || select.all) {
              index = select.visible.indexOf('select_all');
              if (index > -1) select.visible.splice(index, 1);
              select.all = true;
            }
            action.visible = false; // Force update
            action.visible = true;
            break;
          }
          case 'deselect_page': {
            let index = select.visible.indexOf(option.code);
            if (index > -1) select.visible[index] = 'select_page';
            if (!select.visible.includes('select_all')) {
              select.visible.splice(1, 0, 'select_all');
            }
            for (let parcel of this.parcels) {
              if (!select.orders.unchecked.includes(parcel.id)) {
                select.orders.unchecked.push(parcel.id);
              }
              let index = select.orders.checked.indexOf(parcel.id);
              if (index > -1) select.orders.checked.splice(index, 1);
              parcel.checked = false;
            }
            if (!this.SelectedOrders()) {
              this.HandleDropdownOption({code: 'deselect_all'});
            } else {
              action.visible = false; // Force update
              action.visible = true;
            }
          }
        }
        select.visible = [...new Set(select.visible)];
        //console.log(this.CloneObject(select))
      },
      ToggleCheckbox(event, parcel) {
        let checkbox = event.target;
        let checked = checkbox.checked;
        let bulk = this.bulk;
        let select = bulk.select;
        let action = bulk.action;
        if (checked) {
          let index = select.orders.unchecked.indexOf(parcel.id);
          if (index > -1) select.orders.unchecked.splice(index, 1);
          if (!select.orders.checked.includes(parcel.id)) {
            select.orders.checked.push(parcel.id);
          }
          for (let code of ['deselect_all', 'deselect_page']) {
            if (!select.visible.includes(code)) {
              select.visible.push(code);
            }
          }
          if (select.orders.checked.length == this.entries || select.all) {
            index = select.visible.indexOf('select_all');
            if (index > -1) select.visible.splice(index, 1);
            select.all = true;
          }
          if (!this.parcels.some(parcel => !parcel.checked)) {
            let index = select.visible.indexOf('select_page');
            if (index > -1) select.visible.splice(index, 1);
          }
          parcel.checked = true;
        } else {
          let index = select.orders.checked.indexOf(parcel.id);
          if (index > -1) select.orders.checked.splice(index, 1);
          if (!select.orders.unchecked.includes(parcel.id)) {
            select.orders.unchecked.push(parcel.id);
          }
          for (let code of ['select_all', 'select_page']) {
            if (!select.visible.includes(code)) {
              select.visible.push(code);
            }
          }
          if (!this.parcels.some(parcel => parcel.checked)) {
            let index = select.visible.indexOf('deselect_page');
            if (index > -1) select.visible.splice(index, 1);
          }
          parcel.checked = false;
        }
        if (!select.orders.checked.length) {
          for (let code of ['deselect_all', 'deselect_page']) {
            let index = select.visible.indexOf(code), splice = true;
            //if (code == 'deselect_all') splice = select.all;
            if (index > -1 && splice) select.visible.splice(index, 1);
          }
        }
        select.visible = [...new Set(select.visible)];
        action.visible = select.orders.checked.length;
        for (let type of ['checked', 'unchecked']) {
          select.orders[type].sort();
        }
        //console.log(this.CloneObject(select))
      },
      ClickOpen(event) {
        if (!event) return;
        let row = {};
        let elm = event.target;
        if (elm.localName != 'tr') row = elm.closest('tr');
        const filter = [/*'pin', 'pin pinned', */'checkbox', 'lock', 'lock locked', 'name', 'comment-exclamation', 'tracking url', 'list-icon', 'actions', 'icon dots'];
        const mousedown = (state) => {
          if (state === true || state === false) {
            row.mousedown = state;
          } else {
            return row.mousedown;
          }
        }
        for (let i = 0; i < this.clickables.length; i++) {
          if (this.clickables[i] != row) {
            this.clickables[i].classList.remove('hover', 'selected');
          }
        }
        if (elm.localName == 'a') return;
        if (elm.style.display == 'table-cell') return;
        if (/selector/.test(elm.className)) return;
        if (/tracking url/.test(elm.className)) return;
        if (elm.classList.contains('courier-icon')) {
          const parent = elm.closest('.trackingnumber');
          const tracking = parent.dataset.tracking;
          if (tracking && tracking != 'shop') return;
        }
        if (filter.some(x => new RegExp(x).test(elm.className))) {
          if (elm.className != 'name') return;
          if (elm.parentElement.classList.contains('note')) return;
        }
        if (event.type == 'mousedown') {
          mousedown(event.which == 1);
        }
        if (event.type == 'mousemove') {
          mousedown(false);
        }
        if (event.type == 'mouseup') {
          if (mousedown()) {
            this.ViewParcelShow(row.dataset.id);
          }
          mousedown(false);
        }
      },
      ProductImage(event = {}) {
        let product_image = event.target;
        if (!product_image.style) return;
        if (product_image.children.length) return;
        let image = document.createElement('img');
        let background = document.createElement('div');
        let client_rect = product_image.getBoundingClientRect();
        let style_property = product_image.style.getPropertyValue('--product-img-url');
        let url = String(style_property).replace(/^url\((.*)\)$/, '$1').replace(/\\/g, '');
        product_image.classList.add('selected');
        background.className = 'big-image';
        image.className = 'image';
        image.draggable = false;
        Object.assign(image.style, {
          top: `${client_rect.top}px`,
          left: `${client_rect.left}px`,
          transition: 'all 0.25s ease'
        });
        product_image.append(background);
        background.append(image);
        image.onload = () => {
          let size = 500; // pixels
          let width = window.innerWidth;
          let height = window.innerHeight;
          background.classList.add('visible');
          Object.assign(image.style, {
            top: `${(height / 2) - (size / 2)}px`,
            left: `${(width / 2) - (size / 2)}px`,
            width: `${size}px`,
            height: `${size}px`
          });
          image.ontransitionend = (e) => {
            if (e.propertyName == 'width') {
              image.removeAttribute('style');
              image.classList.add('big');
            }
          }
        }
        background.onclick = (e) => {
          if (e.target == image) return;
          background.style.opacity = 0;
          product_image.classList.remove('selected');
          let duration = parseFloat(this.Style(background, 'transition-duration'));
          setTimeout(() => {background.remove()}, duration * 1000);
        }
        image.src = url;
      },
      SelectorTrigger(event) {
        const trigger = event.target;
        const selector = trigger.closest('.selector');
        const options = selector.querySelector('.selector-options');
        trigger.classList.toggle('expanded');
        options.bottom = options.getBoundingClientRect().bottom;
        let above = options.bottom >= this.main.view.clientHeight;
        trigger.classList[above ? 'add' : 'remove']('above');
        this.main.view.classList.toggle('no-scroll');
      },
      StateChange(event = {}) {
        let option = event.target;
        if (option.classList.contains('checked')) return;
        const order_id = option.id;
        const selector = option.closest('.selector');
        selector.trigger = selector.querySelector('.selector-trigger');
        selector.value = selector.trigger.querySelector('.selector-value');
        selector.options = selector.querySelectorAll('.selector-option-value');
        const value = selector.value.dataset.value;
        selector.trigger.classList.remove('expanded');
        selector.value.dataset.value = option.value;
        selector.value.textContent = this.$t(this.Capitalize(option.value));
        this.main.view.classList.remove('no-scroll');
        const successful = () => {
          const filter = this.search.state;
          const states = ['new', 'pick', 'pack', 'manual'];
          const parcels = this.CloneObject(this.parcels);
          let order = parcels.find(order => order.id == order_id);
          let company_code = BPA.company('code', order.company_id);
          if (states.includes(option.value)) {
            let count = this.count[company_code][option.value];
            this.count[company_code][option.value] = count + 1;
          }
          if (states.includes(value)) {
            let count = this.count[company_code][value];
            this.count[company_code][value] = count - 1;
          }
          for (let i = 0; parcels.length; i++) {
            if (parcels[i].id == order_id) {
              parcels[i].state = option.value;
              if (filter && filter != option.value) {
                this.entries = this.entries - 1;
                parcels.splice(i, 1);
              }
              break;
            }
          }
          this.parcels = [];
          this.$nextTick().then(() => this.parcels = parcels);
          this.SummarizeCompanyOrderCount(company_code);
        }
        const unsuccessful = () => {
          selector.value.dataset.value = value;
          selector.value.textContent = this.$t(this.Capitalize(value));
          for (let radio of selector.options) radio.checked = radio.value == value;
        }
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, change it',
          disapprove: 'No',
          message: 'Changes the status of the order.',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return unsuccessful();
          this.loading = true;
          BPA.api.SetOrderState({
            order_id: order_id,
            state: option.value
          }).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            this.loading = false;
            if (!response.ok) return;
            let comment = 'Order status changed by ' + this.UserName() + '.\n';
            comment += 'Status: "' + this.Capitalize(value) + '" → "' + this.Capitalize(option.value) + '".';
            this.SubmitOrderComment({
              order_id: order_id,
              comment: comment,
              comment_type: 'state',
              refresh: true,
              prompt: false
            }).then(() => {
              successful();
              this.$eventHub.$emit('ShowMessages', {
                message: 'Order status successfully changed',
                type: 'success',
                hide: 2000
              });
            });
          }).catch(unsuccessful);
        });
      },
      async ViewParcelShow(order_id, modal_tab) {
        let clickable = null;
        for (let i = 0; i < this.clickables.length; i++) {
          if (this.clickables[i].dataset.id == order_id) {
            clickable = this.clickables[i];
            clickable.classList.add('selected');
            if (this.cached.parcel && Object.keys(this.cached.parcel).length) {
              clickable.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center'
              });              
            }
            break;
          }
        }
        if (this.AllowCompanySearch()) {
          const company = this.company.selected || {};
          if (company.code && company.code != this.main.company) {
            order_id += '?target_company=' + company.code;
          }
        }
        this.modal.view_parcel.lock = {};
        this.modal.view_parcel.locked = false;
        this.modal.view_parcel.tab.active = modal_tab || 'shipping';
        this.modal.view_parcel.loading = true;
        if (!modal_tab) this.loading = true;
        //console.log(this.parcels.find(parcel => parcel.id == order_id))
        return await new Promise((resolve, reject) => {
          BPA.api.GetParcel(order_id).then(response => {
            return BPA.api.response({response, return: 'json', error: (message) => {
              if (response && response.status == 401) return response;
              this.$eventHub.$emit('ShowMessages', {
                message: message,
                type: 'error',
                close: true
              });
            }});
          }).then(async response => {
            if (this.get_parcel_from_url) {
              if (response.status == 401) {
                if (this.AllowCompanySearch()) {
                  let current_company = BPA.util.GetCompany();
                  for (let company of this.company.options) {
                    if (company.code != current_company) {
                      order_id += '?target_company=' + company.code;
                      response = await BPA.api.GetParcel(order_id).then(response => {
                        return BPA.api.response({response, return: 'json', error(){}})
                      }).then(response => response).catch(() => response);
                      if (response.ok || !response.result) {
                        let parcel = response.result || {};
                        this.search.query = parcel.company_id + ':' + parcel.increment_id;
                        this.Search();
                        break;
                      }
                    }
                  }
                }
              }
            }
            this.loading = false;
            this.modal.view_parcel.loading = false;
            if (!response.ok || !response.result) return;
            let parcel = response.result || {};
            this.modal.view_parcel.locked = parcel.is_locked;
            let increment_code = parcel.company_id + ':' + parcel.increment_id;
            if (this.get_parcel_from_url) {
              this.search.query = increment_code;
              this.Search();
            }
            resolve(increment_code);
            order_id = parseInt(order_id);
            if (parcel.is_locked) BPA.api.GetLock(order_id).then(response => {
              return BPA.api.response({response, return: 'json'});
            }).then(response => {
              if (!response.ok) return;
              this.modal.view_parcel.lock = response.result || {};
            }).catch(e => e);
            const company_check = this.companies.find(company => {
              return company.code == this.main.company;
            }).id == parcel.company_id; 
            let grouped_items = {}, sorted_items = [];
            const permission_check = this.AllowStateChange();
            const changable = company_check && permission_check;
            const order = this.parcels.find(parcel => parcel.id == order_id) || {};
            const ordered_items = JSON.parse(JSON.stringify(parcel.ordered_items));
            const selected_shipping_method = this.shipping.options.find(option => option.code == parcel.shipping_description);
            this.modal.view_parcel.tab.shipping.changeable = changable;
            this.modal.view_parcel.tab.shipping.selected = selected_shipping_method || '';
            this.modal.view_parcel.tab.shipping.value = selected_shipping_method || '';
            parcel.shipping_courier = (selected_shipping_method || {}).agent || this.CourierFromShippingMethod(parcel.shipping_description);
            parcel.shipping_description = (selected_shipping_method || {}).code || parcel.shipping_description;
            parcel.shipping_type = (selected_shipping_method || {}).type || '';
            parcel.courier_difference = parcel.courier && parcel.courier != parcel.shipping_courier;
            parcel.pack_log.sort((a, b) => new Date(b.date) - new Date(a.date));
            parcel.shipment_list_ids = parcel.pack_log.some(item => item.shipment_list_id);
            parcel.shipment_list_lookup = this.user.permission.some(check => [0, 11].includes(check));
            for (let item of parcel.pack_log) {
              item.shipment_list_data = {};
              item.loading = false;
            }
            for (let item of ordered_items) {
              item.country = this.country.options.find(option => option.id == item.country_id);
            }
            ordered_items.map(item => {
              item.product_unique.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
              if (item.parent_order_item_id) {
                let parent_id = item.parent_order_item_id;
                if (parent_id in grouped_items) {
                  grouped_items[parent_id].push(item);
                } else {
                  grouped_items[parent_id] = [item];
                }
                item.is_parent = false;
                item.is_child = true;
              } else {
                item.is_child = false;
              }
            });
            ordered_items.map(item => {
              if (item.id in grouped_items) {
                grouped_items[item.id].unshift(item);
                item.is_parent = true;
                item.is_child = false;
              } else {
                if (item.is_virtual) {
                  grouped_items[item.id] = [item];
                }
                item.is_parent = false;
              }
            });
            grouped_items = Object.values(grouped_items).flat();
            sorted_items = ordered_items.filter(ordered_item => {
              return !grouped_items.some(grouped_item => {
                return grouped_item.id == ordered_item.id;
              });
            });
            sorted_items.push(grouped_items);
            parcel.picked_by = parcel.picked_by || order.picked_by || [];
            parcel.ordered_items = sorted_items.flat();
            for (let order_item of parcel.ordered_items) {
              let image = new Image();
              image.onload = function() {
                if ('naturalHeight' in this) {
                  if (this.naturalWidth + this.naturalHeight == 0) {
                    return this.onerror();
                  }
                } else if (this.width + this.height == 0) {
                  return this.onerror();
                }
              };
              image.onerror = function() {
                order_item.img_url = null;
              };
              if (/None$/.test(order_item.img_url)) {
                order_item.img_url = null;
              } else {
                image.src = order_item.img_url;
              }
            }
            parcel.company_code = BPA.company('code', parcel.company_id);
            parcel.company_name = BPA.company('name', parcel.company_code);
            parcel.company_main = BPA.company('main', parcel.company_name);
            ['weight', 'tariff', 'country'].map(prop => parcel[`${prop}_changeable`] = parcel.company_code == this.main.company);
            let language = {iso: parcel.language || ''};
            language.code = String(language.iso.split('_')[0]).toLowerCase();
            language.domain = String(language.iso.split('_')[1]).toLowerCase();
            if (/gb/.test(language.domain)) language.domain = 'co.uk';
            if (/nb/.test(language.code)) language.code = 'no';
            parcel.webshop = {url: `https://${parcel.company_code}.${language.domain}`, lang: language.code};
            if ('cancel_checked' in this.parcel) {
              parcel.cancel_checked = this.parcel.cancel_checked;
            }
            if (this.parcel.pack_log) {
              parcel.cancellable = this.parcel.cancellable;
              for (let i = 0; i < parcel.pack_log.length; i++) {
                let item = parcel.pack_log[i];
                let parcel_item = this.parcel.pack_log[i];
                item.cancellable = parcel_item.cancellable;
                if ('cancelled' in parcel_item) {
                  item.cancelled = parcel_item.cancelled;
                }
              }
            }
            parcel.language = language;
            parcel.shipping_address_form = [];
            for (let type of ['billing', 'shipping']) {
              let address = parcel[`${type}_address`];
              for (let field in address) {
                if (!address[field] || /^(undefined|null)?$/i.test(address[field])) {
                  address[field] = '';
                }
              }
              address.street_address = this.TitleCase(address.street_address);
              address.state_province = String(address.state_province).replace(/^-/, '');
            }
            if (!this.get_parcel_from_url) {
              BPA.cache.session({name: this.$options.name, set: {parcel}});
            }
            if ('discount' in parcel) {
              parcel.discount = '-' + String(parcel.discount).replace(/^-/, '');
            }
            if (!('customer' in parcel)) parcel.customer = {};
            parcel.customer_exists = Object.keys(parcel.customer).some(key => parcel.customer[key]);
            if (clickable) {
              let new_customer = clickable.querySelector('ul.item-actions > li:nth-child(2) > a');
              let parcel_item = this.parcels.find(item => item.id == parcel.id);
              if (new_customer && parcel_item) {
                new_customer.classList[parcel.customer_exists ? 'add' : 'remove']('disabled');
                parcel_item.customer_exists = parcel.customer_exists;
              }
            }
            parcel.video_list = [];
            this.parcel = parcel;
            console.log(this.CloneObject(this.parcel))
            //await this.GetOrderVideoList();
            //await this.GetOrderVideo();
            this.ScanInputHandler(false);
            this.modal.view_parcel.open = true;
            this.main.view.classList.add('no-scroll');
            this.main.view.scroll.top = this.main.view.scrollTop;
            this.$nextTick().then(() => {
              const lock = document.querySelector('#order-lock'); if (!lock) return;
              const title = lock.closest('.modal-wrapper').querySelector('.title');
              const modal_tabs_body = document.querySelector('.modal-tabs__body');
              title.prepend(lock);
              if (!this.modal.view_parcel.tab.body.resize) {
                if (modal_tabs_body) this.modal.view_parcel.tab.body = {
                  resize: new ResizeObserver(e => this.ModalTabBodyScroll(e[0])).observe(modal_tabs_body)
                };
              }
              if (modal_tab == 'order') modal_tabs_body.scroll();
              this.ModalTabBodyScroll({target: modal_tabs_body});
              modal_tabs_body.dispatchEvent(new Event('resize'));
              modal_tabs_body.scroll();
              if (!selected_shipping_method) {
                //console.log(order.shipping_description)
                this.$eventHub.$emit('ShowMessages', {
                  message: `No shipping methods found with following description: "${parcel.shipping_description}"`,
                  type: 'warning',
                  close: true
                });
              }
            });
            if (modal_tab == 'tracking') {
              await this.GetOrderVideoList(this.parcel.id);
              let pack_log = this.parcel.pack_log;
              let pack_log_list = [...document.querySelectorAll('.tracking-history .list-content .list-row')];
              for (let item of pack_log_list) {
                let datetime = item.dataset.datetime;
                let pack_log_item = pack_log.find(item => (new Date(item.date)).getTime() == datetime) || {};
                if (pack_log_item.video) {
                  let play_circle = item.querySelector('.play-circle');
                  play_circle.classList.add('enabled');
                }
              }
              await this.HandleLabelCancelState();
            }
            if (this.modal.view_parcel.tab.shipping.changeable) {
              for (let field in parcel.shipping_address) {
                if (field in this.modal.view_parcel.tab.shipping.address) {
                this.modal.view_parcel.tab.shipping.address[field] = parcel.shipping_address[field] || '';
                }
              }
            }
          }).catch(reject);
        }).catch(e => e);
      },
      async GetCountryShippingAgentMethods() {
        return new Promise((resolve, reject) => {
          if (this.GetCountryShippingAgentMethods.result) {
            return resolve(this.GetCountryShippingAgentMethods.result);
          }
          window.fetch(`../shipping.json?${new Date().getTime()}`).then(response => {
            if (response && response.status == 200) return response.json();
          }).then(response => {
            this.GetCountryShippingAgentMethods.result = response;
            resolve(this.GetCountryShippingAgentMethods.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async GetLabelGeneratorSetup() {
        return await new Promise((resolve, reject) => {
          if (this.GetLabelGeneratorSetup.result) {
            return resolve(this.GetLabelGeneratorSetup.result);
          }
          BPA.api.GetLabelGeneratorSetup().then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            let settings = response.result;
            let result = {};
            for (let agent in settings) {
              result[agent] = [];
              for (let method in settings[agent]) {
                result[agent].push({
                  code: method,
                  agent: agent,
                  label: settings[agent][method].name
                });
              }
            }
            this.GetLabelGeneratorSetup.result = result;
            resolve(this.GetLabelGeneratorSetup.result);
          }).catch(reject);
        }).catch(e => e);
      },
      ModalTabBodyScroll(event) {
        const modal_tabs_body = event.target;
        let order_items_total = modal_tabs_body.querySelector('.order-items__totals');
        if (order_items_total) {
          modal_tabs_body.rect = modal_tabs_body.getBoundingClientRect();
          order_items_total.rect = order_items_total.getBoundingClientRect();
          let stuck = order_items_total.rect.bottom > modal_tabs_body.rect.bottom + 1;
          order_items_total.classList[stuck ? 'add' : 'remove']('stuck');
        }
      },
      HandleOrderItemTariff(e) {
        if (!e) return;
        const event = e.type;
        const input = e.target;
        if (event == 'keydown') {
          if (e.key == 'Enter') {
            e.preventDefault();
            input.blur();
          }
        }
        if (event == 'blur') {
          const item = this.parcel.ordered_items.find(item => item.id == input.dataset.item);
          const tariff = input.textContent.replace(/\s/g, '').trim();
          const order_id = this.parcel.id;
          const item_tariff = item.tarif;
          if (!tariff) return input.textContent = item_tariff;
          if (tariff == item_tariff) return;
          input.textContent = tariff;
          this.$eventHub.$emit('ValidateModalStart', {
            approve: 'Yes, change it',
            disapprove: 'No',
            message: 'Changes the order item tariff.',
            type: 'success'
          });
          this.$eventHub.$on('ValidateModalStop', (approve) => {
            this.$eventHub.$off('ValidateModalStop');
            if (!approve) return input.textContent = item_tariff;
            this.loading = true;
            BPA.api.SetOrderItemTariff({
              order_item_id: item.id,
              tariff: tariff
            }).then(response => {
              return BPA.api.response({response});
            }).then(response => {
              this.loading = false;
              if (!response.ok) return;
              let comment = 'Order item tariff changed by ' + this.UserName() + '.\n';
              comment += 'Item ID: ' + item.id + '.\nSKU: ' + item.sku + '.\n';
              comment += 'Tariff: "' + item_tariff + '" → "' + tariff + '".';
              item.tarif = tariff;
              this.SubmitOrderComment({
                order_id: order_id,
                comment: comment,
                comment_type: 'trff',
                refresh: true,
                prompt: false
              }).then(() => {
                this.$eventHub.$emit('ShowMessages', {
                  message: 'Order item tariff successfully changed',
                  type: 'success',
                  hide: 2000
                });
              });
            }).catch(() => input.textContent = item_tariff);
          });
        }
      },
      HandleOrderItemOriginCountry(e, item) {
        if (!e) return;
        const event = e.type;
        const input = e.target;
        const span = input.parentElement;
        const list = input.list;
        const options = Array.from(list.options);
        const country_options = this.country.options;
        const order_id = this.parcel.id;
        let value = input.value.replace(/\s/g, '');
        if (event == 'keydown' && e.key == 'Space') {
          return e.preventDefault();
        }
        if (event == 'input') {
          return span.dataset.value = value;
        }
        if (event == 'blur') {
          let country_code = '';
          const setInputValue = (code) => {
            input.value = this.CountryName(code);
            input.dispatchEvent(new Event('input'));
          }
          if (item.country) country_code = item.country.code;
          if (value) {
            let valid = false;
            for (let option of options) {
              let val = String(value).toLowerCase();
              let lbl = String(option.label).toLowerCase();
              if (val.length >= lbl.length ? new RegExp(`^${lbl}`).test(val) : new RegExp(`^${val}`).test(lbl)) {
                setInputValue(option.dataset.code);
                valid = true;
                break;
              }
            }
            !valid && setInputValue(country_code);
          } else {
            setInputValue(country_code);
          }
          if (input.value == this.CountryName(country_code)) return;
          let option = options.find(o => o.label == input.value);
          let country_id = Number(option.dataset.id);
          this.$eventHub.$emit('ValidateModalStart', {
            approve: 'Yes, change it',
            disapprove: 'No',
            message: 'Changes the order item origin country.',
            type: 'success'
          });
          this.$eventHub.$on('ValidateModalStop', (approve) => {
            this.$eventHub.$off('ValidateModalStop');
            if (!approve) return setInputValue(country_code);
            this.loading = true;
            BPA.api.SetOrderItemOriginCountry({
              order_item_id: item.id,
              country_id: country_id,
            }).then(response => {
              return BPA.api.response({response});
            }).then(response => {
              this.loading = false;
              if (!response.ok) return;
              let old_country = country_options.find(o => o.code == country_code) || '';
              let new_country = country_options.find(o => o.id == country_id);
              if (old_country) old_country = old_country.label;
              item.country_id = country_id;
              item.country = new_country;
              new_country = new_country.label;
              let comment = 'Order item origin country changed by ' + this.UserName() + '.\n';
              comment += 'Item ID: ' + item.id + '.\nSKU: ' + item.sku + '.\n';
              comment += 'Country: "' + old_country + '" → "' + new_country + '".';
              this.SubmitOrderComment({
                order_id: order_id,
                comment: comment,
                comment_type: 'cntr',
                refresh: true,
                prompt: false
              }).then(() => {
                this.$eventHub.$emit('ShowMessages', {
                  message: 'Order item origin country successfully changed',
                  type: 'success',
                  hide: 2000
                });
              });
            }).catch(() => setInputValue(country_code));
          });
        }
      },
      HandleOrderItemWeight(event) {
        if (!event) return;
        const type = event.type;
        const input = event.target;
        const zero = '0.0000';
        const value = () => {
          let text = input.textContent.replace(/\s\s+/g, '').replace(',', '.').replace(/[^\d.-]/g, '').trim();
          return !isNaN(text) ? Number(text).toFixed(4) : zero;
        }
        const weight_kg = value() ? value() : zero;
        const item = this.parcel.ordered_items.find(item => item.id == input.dataset.item);
        let order_id = this.parcel.id;
        let item_weight = item.weight;
        let item_id = item.id;
        if (type == 'keydown') {
          if (event.key == 'Enter') {
            event.preventDefault();
            input.blur();
          }
        }
        if (type == 'blur') {
          input.textContent = this.CurrencyFormat(weight_kg, 4);
          if (weight_kg != item_weight) {
            this.$eventHub.$emit('ValidateModalStart', {
              approve: 'Yes, change it',
              disapprove: 'No',
              message: 'Changes the order item weight.\nNOTE: This also changes the total weight.',
              type: 'success'
            });
            this.$eventHub.$on('ValidateModalStop', (approve) => {
              let item_weight_locale = this.CurrencyFormat(item_weight, 4);
              this.$eventHub.$off('ValidateModalStop');
              if (!approve) return input.textContent = item_weight_locale;
              this.loading = true;
              BPA.api.SetOrderWeight({
                weight_kg: weight_kg,
                target_id: item_id,
                target_type: 'item'
              }).then(response => {
                return BPA.api.response({response});
              }).then(response => {
                this.loading = false;
                if (!response.ok) return;
                item.weight = weight_kg;
                let total_order_weight = 0.0000;
                for (let order_item of this.parcel.ordered_items) {
                  total_order_weight += Number(order_item.weight);
                }
                total_order_weight = Number(total_order_weight).toFixed(4);
                let parcel_weight_locale = this.CurrencyFormat(total_order_weight, 4);
                let total_order_weight_input = document.querySelector('#total-order-weight');
                BPA.api.SetOrderWeight({
                  weight_kg: total_order_weight,
                  target_id: order_id,
                  target_type: 'order'
                }).then(response => {
                  return BPA.api.response({response});
                }).then(response => {
                  if (!response.ok) return;
                  total_order_weight_input.textContent = parcel_weight_locale;
                }).catch(e => e);
                let comment = 'Order item weight changed by ' + this.UserName() + '.\n';
                comment += 'Item ID: ' + item_id + '.\nSKU: ' + item.sku + '.\n';
                comment += 'Weight: "' + item_weight + ' kg" → "' + weight_kg + ' kg".';
                this.SubmitOrderComment({
                  order_id: order_id,
                  comment: comment,
                  comment_type: 'wgt',
                  refresh: true,
                  prompt: false
                }).then(() => {
                  this.$eventHub.$emit('ShowMessages', {
                    message: 'Order item weight successfully changed',
                    type: 'success',
                    hide: 2000
                  });
                });
              }).catch(() => input.textContent = item_weight_locale);
            });
          }
        }
      },
      HandleOrderTotalWeight(event) {
        if (!event) return;
        const type = event.type;
        const input = event.target;
        const zero = '0.0000';
        const value = () => {
          let text = input.textContent.replace(/\s\s+/g, '').replace(',', '.').replace(/[^\d.-]/g, '').trim();
          return !isNaN(text) ? Number(text).toFixed(4) : zero;
        }
        const weight_kg = value() ? value() : zero;
        let parcel_weight = this.parcel.weight;
        let order_id = this.parcel.id;
        if (type == 'keydown') {
          if (event.key == 'Enter') {
            event.preventDefault();
            input.blur();
          }
        }
        if (type == 'blur') {
          input.textContent = this.CurrencyFormat(weight_kg, 4);
          if (weight_kg != parcel_weight) {
            this.$eventHub.$emit('ValidateModalStart', {
              approve: 'Yes, change it',
              disapprove: 'No',
              message: 'Changes the total order weight.',
              type: 'success'
            });
            this.$eventHub.$on('ValidateModalStop', (approve) => {
              let parcel_weight_locale = this.CurrencyFormat(parcel_weight, 4);
              this.$eventHub.$off('ValidateModalStop');
              if (!approve) {
                input.textContent = parcel_weight_locale;
                return;
              }
              this.loading = true;
              BPA.api.SetOrderWeight({
                weight_kg: weight_kg,
                target_id: order_id,
                target_type: 'order'
              }).then(response => {
                return BPA.api.response({response});
              }).then(response => {
                this.loading = false;
                if (!response.ok) return;
                this.parcel.weight = weight_kg;
                let comment = 'Total order weight changed by ' + this.UserName() + '.\n';
                comment += 'Weight: "' + parcel_weight + ' kg" → "' + weight_kg + ' kg".';
                this.SubmitOrderComment({
                  order_id: order_id,
                  comment: comment,
                  comment_type: 'wgt',
                  refresh: true,
                  prompt: false
                }).then(() => {
                  this.$eventHub.$emit('ShowMessages', {
                    message: 'Total order weight successfully changed',
                    type: 'success',
                    hide: 2000
                  });
                });
              }).catch(() => input.textContent = parcel_weight_locale);
            });
          }
        }
      },
      async HasResParcelConf() {
        return await BPA.api.HasResParcelConf().then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok || !response.result) return;
          return response.result;
        }).catch(e => e);
      },
      async GetShippingMethods(company_code) {
        return await BPA.api.GetShippingOptions(company_code).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok || !response.result) return;
          let options = response.result || [];
          let agent_codes = {};
          let shipping_methods = [];
          this.shipping.methods = options;
          for (let i = 0; i < options.length; i++) {
            if (!agent_codes[options[i].agent_code]) agent_codes[options[i].agent_code] = [];
            agent_codes[options[i].agent_code].push(options[i].shipping_method/*.replace(/%2F/g, '/')*/);
          }
          agent_codes = Object.fromEntries(Object.entries(agent_codes).sort());
          for (let i in agent_codes) {
            shipping_methods.push(agent_codes[i].sort((a, b) => a < b ? -1 : a > b ? 1 : 0));
          }
          shipping_methods = shipping_methods.flat();
          const shipping_methods_options = [];
          for (let shipping_method of shipping_methods) {
            let option = options.find(o => o.shipping_method == shipping_method);
            shipping_methods_options.push({
              code: shipping_method,
              label: shipping_method,
              agent: option.agent_code,
              type: option.type
            });
          }
          //this.shipping.options = shipping_methods;
          this.shipping.options = shipping_methods_options;
          //this.modal.view_parcel.tab.shipping.options = shipping_methods;
          this.modal.view_parcel.tab.shipping.options = shipping_methods_options;
          return options;
        }).catch(e => e);
      },
      SetShippingMethod(shipping_method) {
        if (!shipping_method) {
          shipping_method = this.modal.view_parcel.tab.shipping.value;
          this.modal.view_parcel.tab.shipping.selected = shipping_method;
          return;
        }
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, change method',
          disapprove: 'No',
          message: 'Changes the shipping method.',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) {
            this.SetShippingMethod(null);
            return;
          }
          this.loading = true;
          BPA.api.SetShippingOption({
            id: this.parcel.id, 
            method: shipping_method.code
          }).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            this.loading = false;
            if (!response.ok) return;
            let comment = 'Shipping method changed by ' + this.UserName() + '.\n';
            comment += 'Description: "' + this.modal.view_parcel.tab.shipping.value.code  + '" → "' + shipping_method.code + '".';
            this.modal.view_parcel.tab.shipping.value = shipping_method;
            this.modal.view_parcel.tab.shipping.selected = shipping_method;
            const parcel = this.parcels.find(parcel => parcel.id == this.parcel.id);
            parcel.shipping_courier = shipping_method.agent;
            parcel.shipping_description = shipping_method.code;
            parcel.shipping_type = shipping_method.type;
            if (parcel.pack_log.length) {
              parcel.courier = parcel.pack_log[0].agent_code;
              parcel.tracking = parcel.pack_log[0].tnt;
            }
            parcel.courier_difference = parcel.courier != parcel.shipping_courier;
            if (!parcel.tracking) {
              parcel.courier_difference = false;
              parcel.courier = parcel.shipping_courier;
            }
            ['shipping_courier', 'shipping_description', 'shipping_type', 'courier_difference'].map(key => this.parcel[key] = parcel[key]);
            this.SubmitOrderComment({
              order_id: parcel.id,
              comment: comment,
              comment_type: 'mthd',
              refresh: true,
              prompt: false
            }).then(() => {
              this.$eventHub.$emit('ShowMessages', {
                message: 'Shipping method changed',
                type: 'success'
              });
            });
          }).catch(() => this.SetShippingMethod(null));
        });
      },
      async HandleLabelCancelState() {
        if (this.parcel.cancel_checked) return;
        this.parcel.cancellable = null;
        const cancellable = ['dao'];
        const pack_log = this.parcel.pack_log;
        for (let i = 0; i < pack_log.length; i++) {
          let item = pack_log[i];
          item.cancellable = cancellable.includes(item.agent_code);
          if (item.cancellable) item.loading = true;
        }
        for (let i = 0; i < pack_log.length; i++) {
          let item = pack_log[i];
          if (item.cancellable) {
            item.cancelled = await this.LabelCancelled(item);
            item.cancellable = !item.cancelled;
            if (item.cancellable) {
              this.parcel.cancellable = true;
            }
            item.loading = false;
          }
          //console.log(this.CloneObject(item))
        }
        this.parcel.cancel_checked = true;
      },
      async ShowCollectListData(event, item) {
        if (!event || !item) return;
        if (!item.shipment_list_id) return;
        let event_type = event.type;
        let event_target = event.target;
        let list_id = item.shipment_list_id;
        if (event_type == 'mouseenter') {
          if (!Object.keys(item.shipment_list_data).length) {
            item.shipment_list_data = await this.GetColletcListData(list_id);
          }
          event_target.classList.add('hover');
        }
        if (event_type == 'mouseleave') {
          event_target.classList.remove('hover');
        }
      },
      async GetColletcListData(list_id) {
        if (!this.IsMainCompany()) return {};
        return await new Promise((resolve, reject) => {
          if (!list_id) return reject();
          BPA.api.GetShipmentList(list_id).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            resolve(response.result.items[0]);
          }).catch(reject);
        }).catch(e => e);
      },
      async CopyCollectListID(list_id = '') {
        await Tool.CopyToClipboard('collect/' + list_id, true);
      },
      async CopyInvoiceListID(list_id = '') {
        await Tool.CopyToClipboard('invoices/' + list_id, true);
      },
      async CopyDataToClipboard(data = {}) {
        await Tool.CopyToClipboard(JSON.stringify(data), true);
      },
      async PrintOrder(order_id) {
        const user_name = this.UserName();
        const view_parcel = this.modal.view_parcel;
        const printer_id = (BPA.printer.fetch('a4') || {}).id;
        const params = {order_id, printer_id};
        if (!printer_id) {
          return this.$eventHub.$emit('ShowMessages', {
            message: 'A4 printer is not selected',
            type: 'error',
            close: true
          });
        }
        this.loading = true;
        await BPA.api.PrintOrder(params).then(response => {
          return BPA.api.response({response});
        }).then(response => {
          this.loading = false;
          if (!response.ok) return;
          this.SubmitOrderComment({
            order_id: order_id,
            comment: `Parcel order PDF printed by ${user_name}`,
            comment_type: 'prnt',
            refresh: view_parcel.open,
            prompt: false
          }).then(() => {
            this.$eventHub.$emit('ShowMessages', {
              message: 'Parcel order PDF sent to A4 printer',
              type: 'success',
              hide: 2000
            });
          });
        }).catch(e => e);
      },
      ParcelTracking(e = {}) {
        const params = {};
        let element = e.target;
        if (!element) return;
        if (/courier|tracking/.test(element.className)) {
          params.tracking = /tracking/.test(element.className);
          element = element.closest('.trackingnumber');
          if (!element.dataset.tracking) return;
          if (element.dataset.tracking == 'shop') return;
          let cursor = {x: e.clientX, y: e.clientY};
          let link = element.querySelector('.url').getBoundingClientRect();
          let logo = element.querySelector('.courier-icon').getBoundingClientRect();
          const trigger_link_url = () => {
            params.agent = element.dataset.courier;
            params.code = element.dataset.tracking;
            params.lang = element.dataset.language;
            params.zip_code = element.dataset.zip_code
            let id = element.closest('[data-id]').dataset.id;
            let company = this.parcels.find(o => o.id == id).company_code;
            let url = this.TrackingURL({...params, ...{company: company}});
            if (!url) {
              return this.$eventHub.$emit('ShowMessages', {
                message: 'No tracking URL found for this courier',
                type: 'error',
                hide: 2000
              });
            }
            window.open(url, '_blank');
          }
          const clicked_inside_link = e => cursor.x >= e.left && cursor.x <= e.right && cursor.y >= e.top && cursor.y <= e.bottom;
          if (clicked_inside_link(logo) || clicked_inside_link(link)) trigger_link_url();
        }
      },
      TrackingURL(params = {}) {
        params.company = params.company || this.main.company;
        return Tool.TrackingURL(params);
      },
      async LabelCancelled(parcel = {}) {
        let uncancellable = new RegExp('cancelled', 'ig');
        if (!Object.keys(parcel).length) return false;
        if (!parcel.agent_code || !parcel.tnt) return false;
        switch (parcel.agent_code) {
          case 'dao': {
            uncancellable = new RegExp('annulleret|annulled|cancelled|leveret|delivered', 'ig');
            return await BPA.api.GetLabelStatus(['dao', parcel.tnt]).then(response => {
              return BPA.api.response({response, return: 'json', error: (message) => {
                if (response && response.status == 424) message += ' ' + parcel.tnt;
                this.$eventHub.$emit('ShowMessages', {
                  message: message,
                  type: 'error',
                  close: true
                });
              }});
            }).then(response => {
              if (!response.ok) return;
              let json = response.result || {};
              if (![200].includes(json.status_code)) return;
              if (!((json.info || {}).haendelser || []).length) return;
              return uncancellable.test(json.info.haendelser.slice(-1)[0].beskrivelse);
            }).catch(e => e);
            /*
            let type = parcel.type == 'return' ? 'shop' : 'direct';
            let json = await this.GetDAOLabelStatus({type, code: parcel.tnt}) || {rows: []};
            if (json.rows.length) {
              let data = json.rows[0].data;
              let index = type == 'shop' ? 18 : 20;
              let field = data[index];
              if (typeof field == 'string') {
                if (!field.length) return false;
                return type == 'shop' && /slettet/.test(field);
              }
              if (typeof field == 'object') {
                return /annulleret/.test(field.value);
              }
            }
            return false;
            */
          }
          default:
            return false;
        }
      },
      async GetDAOLabelStatus(label = {}) {
        return new Promise((resolve, reject) => {
          if (!Object.keys(label).length) return reject(false);
          if (!label.type || !label.code) return reject(false);
          window.fetch(`https://clubace.dk/get/dao/${label.type}.php?search=${label.code}`).then(response => {
            if (response && response.status == 200) return response.json();
          }).then(resolve).catch(reject);
        });
      },
      async OrderLabelStatus(order = {}) {
        const order_keys = Object.keys(order);
        return await new Promise((resolve, reject) => {
          let params = ['increment_id', 'check_value']; // phone or email
          if (!order_keys.some(key => params.includes(key))) return reject();
          for (let i = 0; i < params.length; i++) params[i] = order[params[i]];
          BPA.api.GetOrderLabelStatus(params).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            console.log(this.CloneObject(response.result))
            resolve(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      CancelLabel(parcel = {}) {
        if (!Object.keys(parcel).length) return;
        if (!parcel.agent_code || !parcel.tnt) return;
        const user_name = this.UserName();
        const option = this.courier.options.find(option => option.code == parcel.agent_code);
        const label_type = (/return/i.test(parcel.type) ? 'return' : /shipping/i.test(parcel.type) ? 'shipping' : '') + ' label';
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, cancel it',
          disapprove: 'No',
          message: 'Cancels the current' + (option ? ' ' + option.label : '') + ' shipment.',
          type: 'danger'
        });
        this.$eventHub.$on('ValidateModalStop', (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          BPA.api.CancelLabel({
            agent_code: parcel.agent_code, 
            barcode: parcel.tnt
          }).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            this.loading = false;
            if (!response.ok) return;
            let pack_log = this.parcel.pack_log;
            for (let item of pack_log) {
              if (item.tnt == parcel.tnt) {
                item.cancelled = true;
                item.cancellable = false;
              }
            }
            this.parcel.cancellable = pack_log.some(item => item.cancellable);
            this.parcel.pack_log = pack_log;
            this.SubmitOrderComment({
              order_id: this.parcel.id,
              comment: `${option.label} ${label_type} cancelled by ${user_name}.\nTracking: "${parcel.tnt}".`,
              comment_type: 'cncld',
              refresh: true,
              prompt: false
            }).then(() => {
              this.$eventHub.$emit('ShowMessages', {
                message: 'Shipment successfully cancelled',
                type: 'success',
                hide: 2000
              });
            });
          }).catch(e => e);
        });
      },
      /*
      async GetOrderCmsStatusHistory(order_id) {
        if (this.modal.view_parcel.tab.status.loaded) return;
        this.modal.view_parcel.loading = true;
        await BPA.api.GetOrderCmsStatusHistory(order_id).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          this.modal.view_parcel.loading = false;
          if (!response.ok) return;
          let list = response.result || [];
          //console.log(this.CloneObject(list))
          const status = ['pending', 'processing', 'complete', 'closed'];
          this.modal.view_parcel.tab.status.history = list;
          this.modal.view_parcel.tab.status.history.map((item, i) => {
            if (!item.comment) { // Remove empty entries
              this.modal.view_parcel.tab.status.history.splice(i, 1);
            } else {
              let packship = 'Packship: ';
              let username = new RegExp(/:\s\[(.*?)\]/);
              let match = username.exec(item.comment);
              item.cms = !(new RegExp(packship, 'i')).test(item.comment);
              username = match ? match[1] : '';
              if (username.length > 50) {
                username = username.split(' ');
                username = username[0] + ' ' + username.slice(-1)[0];
              }
              item.created_by = item.cms ? 'CMS' : username ? username : 'PackShip';
              item.comment = this.Capitalize(item.comment.slice(!item.cms ? match ? match.index + match[0].length + 1 : packship.length : 0)) || '';
              item.status = status.find(status => item.status.toLowerCase().includes(status)) || item.status;
            }
          });
          //console.log(this.CloneObject(this.modal.view_parcel.tab.status.history))
          this.modal.view_parcel.tab.status.loaded = true;
        }).catch(e => e);
      },
      */
      async GetOrderVideoList(order_id) {
        let view_parcel = this.modal.view_parcel;
        let tab = view_parcel.tab;
        let parcel = this.parcel;
        if (tab.videos.loaded) return;
        //view_parcel.loading = true;
        order_id = order_id || parcel.id;
        return await new Promise((resolve, reject) => {
          BPA.api.GetOrderVideoList(order_id).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(async response => {
            //view_parcel.loading = false;
            //console.log(response.result)
            let video_label_match = {};
            let pack_log = parcel.pack_log;
            let video_list = response.result || [];
            let date_regex = /^(\d{4})_(\d{2})_(\d{2})_(\d{2})_(\d{2})_(\d{2})$/;
            let ISO = (str) => (str || '').replace(date_regex, '$1-$2-$3 $4:$5:$6');
            for (let video of video_list) {
              let metadata = video.metadata;
              let start_at = ISO(metadata.start_at);
              let end_at = ISO(metadata.end_at);
              let start_date = new Date(start_at);
              let end_date = new Date(end_at);
              start_date.setSeconds(start_date.getSeconds() - metadata.pre_start_sec);
              let start_time = start_date.getTime();
              let end_time = end_date.getTime();
              let resolution = metadata.resolution.split('x') || [];
              let video_name = metadata.name.toLowerCase();
              video.width = Number(resolution[0] || 0);
              video.height = Number(resolution[1] || 0);
              video.start_at = this.DateToISO(start_date);
              video.end_at = this.DateToISO(end_date);
              video.duration = (end_time - start_time) / 1000;
              video.pack = {pos: metadata.name};
              if (video.duration > 630) {
                video.duration = 630;
              }
              for (let label of pack_log) {
                let label_date = new Date(label.date);
                let label_pos = label.pos.toLowerCase();
                if (label_date > start_date && label_date < end_date) {
                  if (label_pos == video_name) {
                    if (!video_label_match[video.id]) {
                      video_label_match[video.id] = [];
                    }
                    video_label_match[video.id].push(label.date);
                    if (video_label_match[video.id].length > 1) {
                      delete video_label_match[video.id];
                    }
                    if (video.id in video_label_match) {
                      video.pack = this.CloneObject(label);
                      if (!video.is_video_removed) {
                        label.video = this.CloneObject(video);
                      }
                    }
                  }
                }
              }
            }
            video_list.sort((a, b) => new Date(b.end_at) - new Date(a.end_at));
            parcel.video_list = video_list;
            tab.videos.loaded = true;
            resolve(video_list);
          }).catch(reject);
        }).catch(e => e);
      },
      async GetOrderVideo(video = {}, download = false) {
        return await new Promise((resolve, reject) => {
          if (!video.id) return reject();
          let video_list = this.parcel.video_list;
          let video_item = video_list.find(item => item.id == video.id) || {};
          let order_id = this.parcel.increment_id;
          let meta = video_item.metadata;
          let name = `${order_id}_${(meta.name).toUpperCase()}_${meta.end_at}`;
          let blob = video_item.blob;
          let start_pos = meta.pre_start_sec;
          const resolve_blob = async (name, blob) => {
            if (!blob) return reject();
            video_item.name = name;
            video_item.blob = blob;
            video_item.bytes = blob.size;
            video.blob = video_item.blob;
            if (download) {
              BPA.api.download({name, blob});
              return resolve(true);
            }
            let player = document.createElement('video');
            player.addEventListener('loadedmetadata', () => {
              /*
              let duration = (player.duration * 1000) - 1000;
              let end_date = new Date(video_item.end_at);
              let end_time = end_date.getTime();
              let start_time = end_time - duration;
              let start_date = new Date(start_time);
              video_item.start_at = this.DateToISO(start_date);
              */
              video_item.duration = player.duration;
              video_item.width = player.videoWidth;
              video_item.height = player.videoHeight;
              resolve(player);
            });
            blob = window.URL.createObjectURL(blob);
            player.defaultPlaybackRate = 2; // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/playbackRate
            player.autoplay = false;
            player.controls = true;
            player.src = `${blob}${start_pos && `#t=${start_pos}`}`; // https://developer.mozilla.org/en-US/docs/Web/Guide/Audio_and_video_delivery#specifying_playback_range
          }
          if (blob) return resolve_blob(name, blob);
          this.loading = true;
          BPA.api.GetOrderVideo(video.id).then(response => {
            return BPA.api.response({response, return: 'blob'});
          }).then(response => {
            this.loading = false;
            if (!response.ok || !response.result) return reject();
            meta = video.metadata;
            name = `${order_id}_${(meta.name).toUpperCase()}_${meta.end_at}`;
            blob = response.result;
            resolve_blob(name, blob);
          }).then().catch(reject);
        }).catch(e => e);
      },
      async PlayOrderVideo(video) {
        /*  TO-DO LIST
        [ ] 1. Fix canvas capturing issue when tab is not focused
               https://github.com/fbsamples/Canvas-Streaming-Example/issues/3#issuecomment-634821455
               https://stackoverflow.com/a/44172801
        [x] 2. Fix re-drawing rate when resizing selected shape.rect
        [ ] 3. Fix re-calculation of blur area size and position when resizing video.area
        [ ] 4. If it is required to handle multiple blur areas
               https://stackoverflow.com/a/31795864
               http://jsfiddle.net/n6vrxuLe/1/
        [ ] 5. 
        */
        if (!video) return;
        if (this.video.id) {
          if (this.video.id != video.id) {
            this.video.event.remove();
          } else return;
        }
        video = this.CloneObject(video);
        let element = document.createElement('div');
        let canvas = document.createElement('canvas');
        let buffer = {canvas: canvas.cloneNode()};
        for (let prop of ['width', 'height']) {
          buffer.canvas[prop] = video[prop];
          canvas[prop] = video[prop];
        }
        let context = canvas.getContext('2d');
        buffer.context = buffer.canvas.getContext('2d', {willReadFrequently: true});
        canvas.style.cursor = 'crosshair';
        video = {...video, ...{
          ratio: (video.height / video.width) * 100, 
          keyframe: {}, 
          scale: 1, 
          canvas: {
            shape: {
              rect: null, 
              anchor: {
                size: 10, 
                point: (() => {
                  let direction = {};
                  let points = ['nw', 'n', 'ne', 'e', 'se', 's', 'sw', 'w'];
                  for (let point of points) direction[point] = {x: 0, y: 0};
                  return direction;
                })(), 
                direction: () => {
                  let cnvs = video.canvas;
                  let event = cnvs.event;
                  let mouse = event.mouse;
                  let shape = cnvs.shape;
                  let anchor = shape.anchor;
                  for (let direction in anchor.point) {
                    let point = anchor.point[direction];
                    if (mouse.x >= point.x && mouse.x <= point.x + anchor.size && mouse.y >= point.y && mouse.y <= point.y + anchor.size) {
                      return direction;
                    }
                  }
                  return null;
                }
              }, 
              inside: () => {
                let cnvs = video.canvas;
                let event = cnvs.event;
                let mouse = event.mouse;
                let shape = cnvs.shape;
                let player = video.player;
                let anchor = shape.anchor;
                let frame = player.frame.get();
                let rect = frame.rect;
                if (!canvas || !rect) return;
                if (isNaN(mouse.x) || isNaN(mouse.y)) return;
                buffer.canvas.width = canvas.width;
                buffer.canvas.height = canvas.height;
                buffer.context.filter = 'none';
                buffer.context.clearRect(0, 0, canvas.width, canvas.height);
                buffer.context.beginPath();
                buffer.context.fillStyle = 'white';
                buffer.context.rect(rect.x, rect.y, rect.w, rect.h);
                buffer.context.fill();
                let pixel = buffer.context.getImageData(mouse.x, mouse.y, 1, 1);
                return pixel.data[3] > 0 || anchor.direction();
              }
            }, 
            style: {
              stroke: {
                width: 2, 
                color: '#0075ff'
              }, 
              blur: 'blur(10px)'
            }, 
            offset: {x: 0, y: 0}, 
            client: () => canvas.getBoundingClientRect(), 
            buffer: () => {
              let cnvs = document.createElement('canvas');
              cnvs.width = canvas.width;
              cnvs.height = canvas.height;
              let ctxt = cnvs.getContext('2d');
              return {canvas: cnvs, context: ctxt};
            }, 
            clear: (cntxt = context) => {
              cntxt.clearRect(0, 0, canvas.width, canvas.height);
              cntxt.drawImage(video.player, 0, 0, canvas.width, canvas.height);
            }, 
            draw: {
              allowed: true, 
              rect: {x: 0, y: 0, w: 0, h: 0, width: 0, height: 0, canvas: {width: 0, height: 0}, scale: 1}, 
              reset: () => {
                let draw = video.canvas.draw;
                draw.rect = {x: 0, y: 0, w: 0, h: 0, width: 0, height: 0, canvas: {width: 0, height: 0}, scale: 1};
              }, 
              scale: () => {
                let player = video.player;
                let frame = player.frame.get();
                let rect = frame.rect;
                if (rect) {
                  for (let prop of ['x', 'y', 'w', 'h', 'width', 'height']) {
                    rect[prop] = (rect[prop] / rect.scale) * video.scale;
                  }
                  rect.scale = video.scale;
                }
              }, 
              pixelate: () => {
                let cnvs = video.canvas;
                let event = cnvs.event;
                let player = video.player;
                let frame = player.frame.get();
                let rect = frame.rect;
                event.edit = false;
                if (!canvas) return;
                if (!rect) return cnvs.clear();
                if (rect.w == 0 || rect.h == 0) return;
                if (rect.w < 0) {
                  rect.width = rect.x + rect.w;
                } else {
                  rect.width = rect.x;
                }
                if (rect.h < 0) {
                  rect.height = rect.y + rect.h;
                } else {
                  rect.height = rect.y;
                }
                cnvs.clear();
                cnvs.clear(buffer.context);
                buffer.canvas.width = canvas.width;
                buffer.canvas.height = canvas.height;
                buffer.context.imageSmoothingEnabled = false;
                //buffer.context.mozImageSmoothingEnabled = false;
                //buffer.context.webkitImageSmoothingEnabled = false;
                let scaled = this.CloneObject(rect);
                let block_size = 0.1 / video.scale;
                let width = canvas.width * block_size;
                let height = canvas.height * block_size;
                if (video.event.resizing) {
                  for (let prop of ['x', 'y', 'w', 'h', 'width', 'height']) {
                    scaled[prop] = (rect[prop] / rect.scale) * video.scale;
                  }
                }
                buffer.context.drawImage(canvas, 0, 0, width, height);
                buffer.context.drawImage(buffer.canvas, 0, 0, width, height, 0, 0, canvas.width, canvas.height);
                let image = buffer.context.getImageData(scaled.x, scaled.y, scaled.w, scaled.h);
                context.putImageData(image, scaled.width, scaled.height);
              }, 
              blur: () => {
                let cnvs = video.canvas;
                let event = cnvs.event;
                let player = video.player;
                let frame = player.frame.get();
                let rect = frame.rect;
                event.edit = false;
                if (!canvas) return;
                if (!rect) return cnvs.clear();
                if (rect.w == 0 || rect.h == 0) return;
                if (rect.w < 0) {
                  rect.width = rect.x + rect.w;
                } else {
                  rect.width = rect.x;
                }
                if (rect.h < 0) {
                  rect.height = rect.y + rect.h;
                } else {
                  rect.height = rect.y;
                }
                /*
                for (let prop of ['width', 'height']) {
                  buffer.canvas[prop] = canvas[prop];
                }
                buffer.context.filter = cnvs.style.blur;
                buffer.context.fillRect(0, 0, canvas.width, canvas.height);
                buffer.context.drawImage(video.player, 0, 0, canvas.width, canvas.height, rect.x, rect.y, rect.w, rect.h);
                context.drawImage(video.player, 0, 0, canvas.width, canvas.height);
                context.drawImage(buffer.canvas, rect.x, rect.y, rect.w, rect.h);
                */
                /*
                let scale = Math.min(canvas.width / rect.canvas.width, canvas.height / rect.canvas.height);
                console.log(scale)
                */

                /*
                console.log(this.CloneObject(rect))
                let width_diff = canvas.width - rect.canvas.width;
                console.log('width_diff', width_diff)
                let width_diff_pct = Math.abs(width_diff / rect.canvas.width * 100);
                console.log('width_diff_pct', width_diff_pct)
                let width_diff_pct_dec = width_diff_pct / 100;
                console.log('width_diff_pct_dec', width_diff_pct_dec)
                let rect_w = Math.floor(width_diff < 0 ? rect.w - (rect.w * width_diff_pct_dec) : rect.w + (rect.w * width_diff_pct_dec));
                console.log('rect_w', rect_w)
                //rect.w = rect_w;
                */
                cnvs.clear();
                cnvs.clear(buffer.context);
                buffer.canvas.width = canvas.width;
                buffer.canvas.height = canvas.height;
                buffer.context.filter = 'none';
                buffer.context.clearRect(0, 0, canvas.width, canvas.height);
                //buffer.context.scale(scale, scale);
                buffer.context.filter = cnvs.style.blur;
                buffer.context.fillRect(0, 0, canvas.width, canvas.height);
                buffer.context.drawImage(canvas, 0, 0, canvas.width, canvas.height);
                let image = buffer.context.getImageData(rect.x, rect.y, rect.w, rect.h);
                context.putImageData(image, rect.width, rect.height);
              }, 
              area: async () => {
                let cnvs = video.canvas;
                let draw = cnvs.draw;
                let rect = draw.rect;
                let event = cnvs.event;
                let style = cnvs.style;
                let stroke = style.stroke;
                let border = stroke.width;
                let mouse = event.mouse;
                let frame = video.player.frame;

                rect.x = mouse.start.x;
                rect.y = mouse.start.y;
                rect.w = mouse.x - rect.x;
                rect.h = mouse.y - rect.y;
                rect.canvas = {
                  width: canvas.width, 
                  height: canvas.height
                };
                rect.scale = video.scale;
                
                /*
                let stroke = {
                  x: rect.x + border / 2,
                  y: rect.y + border / 2,
                  w: rect.w - border,
                  h: rect.h - border
                }
                //console.log('rect', this.CloneObject(rect))
                //console.log('border', this.CloneObject(stroke))
                */
                cnvs.clear();
                //draw.blur();

                await frame.rect.set({rect});
                draw.boundary(true);

                context.lineWidth = border;
                context.strokeStyle = stroke.color;
                //context.setLineDash([border, border]);
                context.strokeRect(rect.x, rect.y, rect.w, rect.h);
                //context.strokeRect(stroke.x, stroke.y, stroke.w, stroke.h);
                
                mouse.prev.x = rect.x;
                mouse.prev.y = rect.y;
                mouse.prev.width = rect.w;
                mouse.prev.height = rect.h;
              },
              select: (active = true) => {
                let cnvs = video.canvas;
                let shape = cnvs.shape;
                let anchor = shape.anchor;
                let stroke = cnvs.style.stroke;
                let outline = active === false;
                let color = outline ? 'lightgray' : stroke.color;
                let player = video.player;
                let frame = player.frame.get();
                let rect = frame.rect;
                if (!canvas || !rect) return;
                cnvs.draw.select.outline = outline;
                cnvs.clear(buffer.context);
                buffer.canvas.width = canvas.width;
                buffer.canvas.height = canvas.height;
                buffer.context.clearRect(0, 0, canvas.width, canvas.height);
                buffer.context.drawImage(outline ? canvas : video.player, 0, 0, canvas.width, canvas.height);
                if (outline) buffer.context.globalAlpha = 0.5;
                buffer.context.lineWidth = stroke.width;
                buffer.context.strokeStyle = color;
                buffer.context.strokeRect(rect.x, rect.y, rect.w, rect.h);
                buffer.context.fillStyle = !outline ? 'white' : color;
                for (let direction in anchor.point) {
                  let point = anchor.point[direction];
                  let half = anchor.size / 2;
                  switch (direction) {
                    case 'nw': { // top-left
                      point.x = rect.x - half;
                      point.y = rect.y - half;
                      break;
                    }
                    case 'n': { // top-middle
                      point.x = rect.x + rect.w / 2 - half;
                      point.y = rect.y - half;
                      break;
                    }
                    case 'ne': { // top-right
                      point.x = rect.x + rect.w - half;
                      point.y = rect.y - half;
                      break;
                    }
                    case 'e': { // right-middle
                      point.x = rect.x + rect.w - half;
                      point.y = rect.y + rect.h / 2 - half;
                      break;
                    }
                    case 'se': { // bottom-right
                      point.x = rect.x + rect.w - half;
                      point.y = rect.y + rect.h - half;
                      break;
                    }
                    case 's': { // bottom-middel
                      point.x = rect.x + rect.w / 2 - half;
                      point.y = rect.y + rect.h - half;
                      break;
                    }
                    case 'sw': { // bottom-left
                      point.x = rect.x - half;
                      point.y = rect.y + rect.h - half;
                      break;
                    }
                    case 'w': { // left-middle
                      point.x = rect.x - half;
                      point.y = rect.y + rect.h / 2 - half;
                    }
                  }
                  
                  buffer.context.beginPath();
                  buffer.context.arc(point.x + half, point.y + half, anchor.size / 2, 0, Math.PI * 2);
                  buffer.context.fill();
                  
                  //buffer.context.fillRect(point.x, point.y, anchor.size, anchor.size);
                }
                context.drawImage(buffer.canvas, 0, 0, canvas.width, canvas.height);
              }, 
              boundary: (resize) => {
                let player = video.player;
                let frame = player.frame.get();
                let rect = frame.rect;
                let top = rect.y;
                let left = rect.x;
                let width = Math.abs(rect.w);
                let height = Math.abs(rect.h);
                let right = left + width;
                let bottom = top + height;
                let negative = {w: rect.w < 0, h: rect.h < 0};

                if (negative.w) {
                  right = left;
                  left = Math.abs(left) - width;
                }

                if (negative.h) {
                  bottom = top;
                  top = Math.abs(top) - height;
                }

                if (left <= 0) {
                  if (resize) {
                    if (negative.w) {
                      rect.w = -right;
                      rect.x = right;
                    } else {
                      rect.w = right;
                      rect.x = 0;
                    }
                  } else {
                    rect.x = negative.w ? width : 0;
                  }
                }

                if (top <= 0) {
                  if (resize) {
                    if (negative.h) {
                      rect.h = -bottom;
                      rect.y = bottom;
                    } else {
                      rect.h = bottom;
                      rect.y = 0;
                    }
                  } else {
                    rect.y = negative.h ? height : 0;
                  }
                }

                if (right >= canvas.width) {
                  if (resize) {
                    if (negative.w) {
                      rect.w = (left < 0 ? 0 : left) - canvas.width;
                      rect.x = canvas.width;
                    } else {
                      rect.w = canvas.width - rect.x;
                    }
                  } else {
                    rect.x = negative.w ? canvas.width : canvas.width - rect.w;
                  }
                }

                if (bottom >= canvas.height) {
                  if (resize) {
                    if (negative.h) {
                      rect.h = (top < 0 ? 0 : top) - canvas.height;
                      rect.y = canvas.height;
                    } else {
                      rect.h = canvas.height - rect.y;
                    }
                  } else {
                    rect.y = negative.h ? canvas.height : canvas.height - rect.h;
                  }
                }
              }, 
              resize: async (direction) => {
                let cnvs = video.canvas;
                let frame = video.player.frame;
                let rect = await frame.rect.get();
                let draw = cnvs.draw;
                let event = cnvs.event;
                let mouse = event.mouse;
                let old = {x: rect.x, y: rect.y};
                switch (direction) {
                  case 'nw': { // top-left
                    rect.x = mouse.x;
                    rect.y = mouse.y;
                    rect.w += old.x - mouse.x;
                    rect.h += old.y - mouse.y;
                    break;
                  }
                  case 'n': { // top-middle
                    rect.y = mouse.y;
                    rect.h += old.y - mouse.y;
                    break;
                  }
                  case 'ne': { // top-right
                    rect.y = mouse.y;
                    rect.w = mouse.x - old.x;
                    rect.h += old.y - mouse.y;
                    break;
                  }
                  case 'e': { // right-middle
                    rect.w = mouse.x - old.x;
                    break;
                  }
                  case 'se': { // bottom-right
                    rect.w = mouse.x - old.x;
                    rect.h = mouse.y - old.y;
                    break;
                  }
                  case 's': { // bottom-middel
                    rect.h = mouse.y - old.y;
                    break;
                  }
                  case 'sw': { // bottom-left
                    rect.x = mouse.x;
                    rect.w += old.x - mouse.x;
                    rect.h = mouse.y - old.y;
                    break;
                  }
                  case 'w': { // left-middle
                    rect.x = mouse.x;
                    rect.w += old.x - mouse.x;
                    break;
                  }
                }
                await frame.rect.set({rect});
                draw.boundary(true);
                draw.select();
              }, 
              move: async (direction = {}) => {
                let cnvs = video.canvas;
                let frame = video.player.frame;
                let rect = await frame.rect.get();
                let draw = cnvs.draw;
                let event = cnvs.event;
                let mouse = event.mouse;
                let offset = cnvs.offset;
                if (!rect) return;
                if (!Object.keys(direction).length) {
                  rect.x = mouse.x - offset.x;
                  rect.y = mouse.y - offset.y;
                } else for (let axis in direction) {
                  rect[axis] -= -direction[axis];
                }
                await frame.rect.set({rect});
                draw.boundary();
                draw.select();
              }
            }, 
            event: {
              down: false, 
              drag: false, 
              draw: false, 
              move: false, 
              edit: false, 
              size: false, 
              capture: false, 
              mouse: {
                x: 0, 
                y: 0, 
                start: {
                  x: 0, 
                  y: 0
                },
                prev: {
                  x: 0, 
                  y: 0, 
                  width: 0, 
                  height: 0
                }, 
                down: (e) => {
                  if (e.target == canvas) {
                    let cnvs = video.canvas;
                    let event = cnvs.event;
                    let mouse = event.mouse;
                    let shape = cnvs.shape;
                    let offset = cnvs.offset;
                    let anchor = shape.anchor;
                    let player = video.player;
                    let paused = player.paused;
                    let inside = shape.inside();
                    let frame = player.frame.get();
                    let rect = frame.rect;
                    cnvs.client = canvas.getBoundingClientRect();
                    mouse.x = parseInt(e.clientX - cnvs.client.x);
                    mouse.y = parseInt(e.clientY - cnvs.client.y);
                    mouse.start.x = mouse.x;
                    mouse.start.y = mouse.y;
                    event.down = true;
                    if (inside && paused) {
                      let cursor = 'default';
                      rect.anchor = anchor.direction();
                      if (rect.anchor) {
                        cursor = rect.anchor;
                        event.size = true;
                      } else if (!event.size) {
                        cursor = 'move';
                      }
                      event.move = true;
                      event.edit = true;
                      canvas.style.cursor = cursor;
                      offset.x = mouse.x - rect.x;
                      offset.y = mouse.y - rect.y;
                      rect.x = mouse.x - offset.x;
                      rect.y = mouse.y - offset.y;
                      cnvs.draw.select();
                    } else {
                      canvas.style.cursor = paused && !rect ? 'crosshair' : 'default';
                      event.size = false;
                      event.move = false;
                      event.edit = false;
                      cnvs.draw.pixelate();
                    }
                  }
                }, 
                up: async () => {
                  let cnvs = video.canvas;
                  let event = cnvs.event;
                  let player = video.player;
                  let frame = player.frame.get();
                  let rect = frame.rect;
                  const set_rect_size = async () => {
                    if (!rect) return;
                    let width = Math.abs(rect.w);
                    let height = Math.abs(rect.h);
                    if (width < 20) rect.w = rect.w < 0 ? -20 : 20;
                    if (height < 20) rect.h = rect.h < 0 ? -20 : 20;
                    if (event.draw) {
                      await player.frame.rect.set({rect});
                    } else if (event.drag || event.size) {
                      await player.frame.rect.set({rect});
                    }
                    cnvs.draw.select();
                  }
                  if (event.draw) {
                    event.edit = true;
                    cnvs.draw.reset();
                    set_rect_size();
                    if (rect) {
                      let top = video.frame.getBoundingClientRect().top;
                      video.frame.style.top = top + 'px';
                      video.frame.append(video.bottom);
                      if (video.handle.maximized) {
                        video.handle.maximize();
                      }
                      cnvs.draw.allowed = false;
                      video.timeline.update();
                    }
                  } else if (event.edit) {
                    set_rect_size();
                    video.timeline.update();
                  }
                  event.down = false;
                  event.move = false;
                  event.size = false;
                }, 
                move: (e) => {
                  let cnvs = video.canvas;
                  let event = cnvs.event;
                  let mouse = event.mouse;
                  let draw = cnvs.draw;
                  let shape = cnvs.shape;
                  let anchor = shape.anchor;
                  let player = video.player;
                  let inside = shape.inside();
                  let frame = player.frame.get();
                  let rect = frame.rect || draw.rect;
                  let paused = video.player.paused;
                  let mouseX = parseInt(e.clientX - cnvs.client.x);
                  let mouseY = parseInt(e.clientY - cnvs.client.y);
                  let setCursorResizeDirection = (direction) => {
                    canvas.style.cursor = direction + '-resize';
                  }
                  event.drag = event.down && (mouseX != mouse.x || mouseY != mouse.y);
                  event.draw = event.drag && paused;
                  mouse.x = mouseX;
                  mouse.y = mouseY;
                  if (!paused) return;
                  if (event.edit) {
                    event.draw = false;
                    canvas.style.cursor = inside ? 'move' : 'default';
                    let rect_anchor = anchor.direction();
                    if (rect_anchor) {
                      //console.log(this.CloneObject(rect), rect.anchor, rect_anchor)
                      setCursorResizeDirection(rect_anchor);
                    }
                    if (event.drag) {
                      if (event.size) {
                        event.size = true;
                        event.move = false;
                        cnvs.draw.resize(rect.anchor);
                        setCursorResizeDirection(rect.anchor);
                      } else {
                        event.size = false;
                        event.move = true;
                        cnvs.draw.move();
                      }
                    }
                  } else {
                    if (rect) {
                      //event.draw = false;
                      if (inside) {
                        if (!cnvs.draw.select.outline) {
                          canvas.style.cursor = 'move';
                          cnvs.draw.select(false);
                        }
                      } else {
                        if (cnvs.draw.select.outline) {
                          cnvs.draw.select.outline = false;
                          canvas.style.cursor = 'default';
                          video.player.frame.update();
                        }
                      }
                    }
                    canvas.style.cursor = draw.allowed ? 'crosshair' : 'default';
                    if (event.draw && draw.allowed) {
                      rect.x = parseInt(mouse.x - cnvs.client.x);
                      rect.y = parseInt(mouse.y - cnvs.client.y);
                      canvas.style.cursor = 'crosshair';
                      cnvs.draw.area();
                    }
                  }
                }
              }
            }, 
            init: () => {
              let cnvs = video.canvas;
              let mouse = cnvs.event.mouse;
              cnvs.client = canvas.getBoundingClientRect();
              for (let event of ['down', 'up', 'move']) {
                //video.layer['onmouse' + event] = mouse[event];
                window.addEventListener('mouse' + event, mouse[event], false);
              }
              cnvs.kill = () => {
                for (let event of ['down', 'up', 'move']) {
                  //delete video.layer['onmouse' + event];
                  window.removeEventListener('mouse' + event, mouse[event]);
                }
              }
            }
          }, 
          event: {
            target: null, 
            dragging: false, 
            resizing: false, 
            resized: false,
            click: {x: null, y: null}, 
            position: {x: null, y: null}, 
            handle: async (e) => {
              if (e.type == 'mousedown') {
                if (video.speed.toggle != e.target && !video.speed.toggle.contains(e.target)) {
                  if (parseInt(video.speed.elevator.style.height)) video.speed.elevator.style.height = '0px';
                }
                if (video.canvas.event.edit && e.target != canvas) video.player.frame.update();
                /*
                if (/inverse/.test(e.target.className)) {
                  if (/left/.test(e.target.className)) {

                  }
                  if (/right/.test(e.target.className)) {
                    
                  }
                }
                */
                video.event.target = e.target;
                if (e.button != 0) return;
                if (e.target != video.handle) return;
                video.event.click.x = e.clientX;
                video.event.click.y = e.clientY;
                video.event.position.x = video.frame.offsetLeft;
                video.event.position.y = video.frame.offsetTop;
                video.event.dragging = true;
              }
              if (e.type == 'mousemove') {
                if (video.event.target == canvas) {
                  e.preventDefault();
                }
                if (video.event.dragging) {
                  e.preventDefault();
                  video.frame.style.left = video.event.position.x + e.clientX - video.event.click.x + 'px';
                  video.frame.style.top = video.event.position.y + e.clientY - video.event.click.y + 'px';
                }
                if (video.event.resizing) video.event.resized = true;
              }
              if (e.type == 'mouseup') {
                if (video.event.dragging) {
                  video.canvas.client = canvas.getBoundingClientRect();
                }
                if (video.event.resized) {
                  video.canvas.draw.scale();
                }
                video.event.resized = false;
                video.event.resizing = false;
                video.event.dragging = false;
                if (video.event.target != e.target) return video.event.target = null;
                if ([video.layer, video.close].includes(video.event.target)) {
                  if (e.button == 0) video.event.remove(true);
                }
                video.event.target = null;
              }
              if (e.type == 'keydown') {
                let key_code = String(e.code).toLowerCase();
                if (key_code == 'escape') {
                  video.event.remove();
                }
                if (key_code == 'space') {
                  video.player.state.toggle();
                }
                if (key_code == 'delete') {
                  if (video.canvas.event.edit) {
                    let rect = {rect: null};
                    let prev_index = video.player.frame.index() - 1;
                    let prev_keyframe = video.keyframe[prev_index] || {};
                    if (!prev_keyframe.rect) rect.break = false;
                    await video.player.frame.rect.set(rect);
                    canvas.style.cursor = 'crosshair';
                    video.canvas.draw.allowed = true;
                    video.canvas.event.edit = false;
                    video.canvas.shape.rect = null;
                    video.canvas.clear();
                    if (Object.values(video.keyframe).some(frame => frame.rect)) {
                      video.timeline.update();
                    } else {
                      video.bottom.remove();
                      if (video.handle.maximized) {
                        video.handle.maximize();
                      }
                    }
                  }
                }
                if (/^arrow/.test(key_code)) {
                  let seeker = video.slider;
                  let start = video.range.left;
                  let stop = video.range.right;
                  let move = e.shiftKey ? 1 : 10;
                  let focused = document.activeElement;
                  if (/up|down$/.test(key_code)) {
                    let speed = video.speed;
                    if (speed.slider != focused) {
                      if ([seeker, start, stop].includes(focused)) {
                        e.preventDefault();
                      }
                      let value = parseInt(speed.slider.value);
                      let step = parseInt(speed.slider.step);
                      if (/up/.test(key_code)) {
                        if (video.canvas.event.edit) {
                          await video.canvas.draw.move({y: -move});
                          video.timeline.update();
                        } else {
                          value -= -step;
                        }
                      }
                      if (/down/.test(key_code)) {
                        if (video.canvas.event.edit) {
                          await video.canvas.draw.move({y: move});
                          video.timeline.update();
                        } else {
                          value -= step;
                        }
                      }
                      video.speed.slider.value = value;
                      video.speed.slider.dispatchEvent(new Event('input'));
                    }
                  }
                  if (/left|right$/.test(key_code)) {
                    let step = e.shiftKey ? 0.04 : 2.5;
                    if (![seeker, start, stop].includes(focused)) {
                      if (/left/.test(key_code)) {
                        if (video.canvas.event.edit) {
                          await video.canvas.draw.move({x: -move});
                          video.timeline.update();
                        } else {
                          video.player.currentTime -= step;
                        }
                      }
                      if (/right/.test(key_code)) {
                        if (video.canvas.event.edit) {
                          await video.canvas.draw.move({x: move});
                          video.timeline.update();
                        } else {
                          video.player.currentTime += step;
                        }
                      }
                    }
                  }
                }
                //console.log(key_code)
                //console.log(e.code)
              }
              video.handle.classList[video.event.dragging ? 'add' : 'remove']('dragging');
            }, 
            time: (seconds = 0, parts = false) => {
              if (isNaN(seconds)) seconds = 0;
              let string = new Date(seconds * 1000).toISOString().substr(11, 8);
              let time = {minutes: string.substr(3, 2), seconds: string.substr(6, 2)};
              return parts ? time : time.minutes + ':' + time.seconds;
            }, 
            flash: () => {
              clearTimeout(video.event.flash.timeout);
              if (video.container.classList.contains('flash')) {
                video.container.classList.remove('flash');
                video.camera.timer = setTimeout(() => {
                  video.container.classList.add('flash');
                });
              } else {
                video.container.classList.add('flash');
              }
              video.event.flash.timeout = setTimeout(() => {
                video.container.classList.remove('flash');
              }, 1000);
            }, 
            editor: (show = 'video') => {
              this.Empty(video.top);
              if (show) {
                if (show == 'video') {
                  video.camera.classList.add('inactive');
                  video.video.style.setProperty('cursor', 'default');
                }
                if (show == 'camera') {
                  video.video.classList.add('inactive');
                  video.camera.style.setProperty('cursor', 'default');
                  let clipboard = window.navigator.clipboard;
                  if (clipboard) clipboard = clipboard.write;
                  video.top.append(video.handle, video.capture, /*video.video,*/ video.camera, /*video.save, video.circle, video.box,*/ video.title, /*(clipboard ? video.resolve : ''),*/ video.reject, video.close);
                }
              } else if (!show) {
                video.camera.classList.remove('inactive');
                video.video.classList.remove('inactive');
                video.camera.style.removeProperty('cursor');
                video.video.style.removeProperty('cursor');
                video.top.append(video.handle, video.capture, /*video.video,*/ video.camera, /*video.save, video.circle, video.box,*/ video.title, video.close);
              }
              video.event.editor.show = show;
            }, 
            snapshot: async () => {
              video.player.pause();
              video.container.append(video.snapshot());
              /*
              video.player.classList.add('hidden');
              video.area.style.resize = 'none';
              video.event.editor('camera', true);
              */
              if (!video.snapshot.canvas) return;
              let location = window.location;
              let clipboard = window.navigator.clipboard;
              if (clipboard && window.ClipboardItem) {
                let clipboardItem = blob => new window.ClipboardItem({[blob.type]: blob});
                video.snapshot.canvas.toBlob(blob => clipboard.write([clipboardItem(blob)]));
              } else {
                // https://medium.com/nerd-for-tech/navigator-clipboard-api-b96be187df5d
                if (this.browser.chrome) {
                  if (location.protocol != 'https') {
                    console.info('chrome://flags -> "Insecure origins treated as secure" -> Add "' + location.origin + '" to list -> "Enabled"');
                  }
                }
                if (this.browser.firefox) {
                  if (location.protocol != 'https') {
                    console.info('Firefox requires SSL/TLS (https) to utilize the Navigator Clipboard API');
                  }
                  console.info('Firefox supports navigator.clipboard.write by setting "asyncClipboard" using about:config');
                }
              }
              video.event.reset();
            }, 
            reset: () => {
              video.player.classList.remove('hidden');
              //video.area.style.resize = 'horizontal';
              if (video.snapshot.canvas) {
                video.snapshot.canvas.remove();
                delete video.snapshot.canvas;
              }
              video.event.editor(false);
              //video.player.focus();
            }, 
            remove: (force = false) => {
              if (video.event.editor.show && force !== true) {
                return video.event.reset();
              }
              for (let event of ['mousedown', 'mousemove', 'mouseup', 'keydown']) {
                window.removeEventListener(event, video.event.handle);
              }
              video.player.observer.resize.unobserve(video.area);
              video.layer.remove();
              video.canvas.kill();
              this.video = {};
            }
          }
        }};
        for (let event of ['mousedown', 'mousemove', 'mouseup', 'keydown']) {
          window.addEventListener(event, video.event.handle, false);
        }
        for (let node of ['layer', 'frame', 'top', 'handle', 'camera', 'video', 'capture', 'save', 'circle', 'box', 'title', 'resolve', 'reject', 'close', 'area', 'container', 'controls', 'toggle', 'play', 'pause', 'seeker', 'timer', 'elapsed', 'duration', 'fullscreen', 'bottom', 'timeline', 'line', 'censor', 'cursor']) {
          video[node] = element.cloneNode();
          video[node].className = node;
          if (/circle|box/.test(node)) {
            let clone = element.cloneNode();
            Object.assign(video[node].style, {
              width: '20px', 
              height: '20px', 
              display: 'flex',
              position: 'relative', 
              //marginLeft: '20px', 
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.15)', 
              borderRadius: '10px', 
              cursor: 'pointer'
            });
            Object.assign(clone.style, {
              width: '18px', 
              height: '18px', 
              position: 'relative', 
              border: '2px dotted #ffffff', 
              pointerEvents: 'none'
            });
            if (/circle/.test(node)) {
              for (let elm of [video[node], clone]) {
                Object.assign(elm.style, {
                  width: '20px', 
                  height: '20px', 
                  borderRadius: '50%', 
                });
              }
            }
            video[node].append(clone);
          }
          if (/save|resolve|reject|close/.test(node)) {
            if (/save|resolve|reject/.test(node)) {
              Object.assign(video[node].style, {
                width: '40px', 
                height: '20px', 
                position: 'relative', 
                //marginLeft: `${/save/.test(node) ? '20px' : ''}`, 
                //marginRight: `${/save/.test(node) ? '' : '20px'}`, 
                backgroundColor: `#${/resolve/.test(node) ? '58bf5d' : /reject/.test(node) ? 'ff686e' : '1b577a'}`, 
                borderRadius: '4px', 
                cursor: 'pointer'
              });
            }
            if (/close/.test(node)) {
              Object.assign(video[node].style, {
                width: '20px', 
                height: '20px', 
                position: 'relative', 
                cursor: 'pointer'
              });
            }
            if (/save/.test(node)) {
              for (let i = 3; i--;) {
                let line = element.cloneNode();
                video[node].append(line);
                Object.assign(line.style, {
                  width: '2px', 
                  height: `${i ? '5' : '10'}px`, 
                  top: `${i ? '56' : '50'}%`, 
                  left: `${i ? i == 1 ? '48' : '52' : '50'}%`, 
                  position: 'absolute', 
                  pointerEvents: 'none', 
                  backgroundColor: '#ffffff', 
                  transform: `translate(-50%, -50%) ${i ? `rotate(${i == 1 ? '-' : ''}45deg)` : ''}`, 
                  transformOrigin: `${i ? 'bottom' : 'center'} center`
                });
              }
            }
            if (/resolve|reject|close/.test(node)) {
              for (let i = 2; i--;) {
                let line = element.cloneNode();
                video[node].append(line);
                Object.assign(line.style, {
                  top: '50%', 
                  left: '50%', 
                  position: 'absolute', 
                  pointerEvents: 'none', 
                  backgroundColor: '#ffffff', 
                  transform: `translate(${i == 1 ? '-25%, -50%' : '-100%, 10%'}) rotate(${i == 1 ? '-' : ''}45deg)`
                });
                if (/resolve/.test(node)) {
                  Object.assign(line.style, {
                    width: `${i == 1 ? '25' : '10'}%`, 
                    height: '2px', 
                    borderRadius: `${i == 1 ? '0 2px 2px 0' : '2px 0 0 2px'}`, 
                    transform: `translate(${i == 1 ? '-25%, -50%' : '-100%, 10%'}) rotate(${i == 1 ? '-' : ''}45deg)`
                  });
                }
                if (/reject|close/.test(node)) {
                  Object.assign(line.style, {
                    width: `${node == 'reject' ? '25' : '100'}%`, 
                    height: `${node == 'reject' ? '2' : '4'}px`, 
                    borderRadius: '2px', 
                    transform: `translate(-50%, -50%) rotate(${i == 1 ? '-' : ''}45deg)`
                  });
                }
              }
            }
          }
        }

        Object.assign(video.layer.style, {
          top: '0', 
          left: '0', 
          width: '100%', 
          height: '100%', 
          display: 'flex', 
          alignItems: 'center', 
          flexDirection: 'column', 
          justifyContent: 'center', 
          //background: 'rgba(0, 0, 0, 0.25)', 
          pointerEvents: 'none', 
          position: 'fixed', 
          zIndex: 4
        });

        Object.assign(video.frame.style, {
          display: 'flex', 
          borderRadius: '6px', 
          flexDirection: 'column', 
          boxShadow: '2px 4px 10px 0 rgba(0, 0, 0, 0.25)', 
          backgroundImage: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAQAAAC1QeVaAAAASElEQVQY02NgQALS/5F5Ssg8qa/IUgooUg+QpWRRpA7hNB5VCsV4VANReKjOQDWDgYFIm/G4F7cv8YSNLG4pFCNQeSjq0MwAAPCoHW3Q0Dt9AAAAAElFTkSuQmCC)', 
          backgroundBlendMode: 'overlay', 
          backgroundColor: '#1a1a1a', 
          pointerEvents: 'initial', 
          position: 'absolute', 
          overflow: 'hidden', 
          zIndex: 3
        });

        Object.assign(video.top.style, {
          width: '100%', 
          display: 'flex', 
          padding: '0 20px', 
          position: 'relative', 
          alignItems: 'center'
        });

        Object.assign(video.title.style, {
          height: '40px', 
          display: 'flex', 
          marginBottom: 0, 
          flex: '1 1 auto', 
          position: 'relative', 
          alignItems: 'center', 
          userSelect: 'none', 
          pointerEvents: 'none', 
          fontSize: 'inherit', 
          fontWeight: '600', 
          color: '#ffffff', 
          textTransform: 'capitalize', 
          justifyContent: 'center', 
          marginRight: '44px'
        });

        Object.assign(video.area.style, {
          width: video.width + 'px', 
          //height: video.height + 'px', 
          minWidth: video.width / 1.5 + 'px', 
          minHeight: video.height / 1.5 + 'px', 
          display: 'flex', 
          flexDirection: 'column', 
          backgroundColor: '#000000', 
          paddingTop: video.ratio + '%', 
          //borderRadius: '0 0 6px 6px', 
          position: 'relative', 
          overflow: 'hidden', 
          //resize: 'horizontal'
        });

        Object.assign(video.bottom.style, {
          //bottom: 0,
          width: '100%', 
          height: '40px', 
          display: 'flex', 
          padding: '0 20px', 
          position: 'relative', 
          alignItems: 'center', 
          backgroundColor: '#000000', 
          //transform: 'translateY(100%)'
        });

        Object.assign(video.capture.style, {
          width: '24px', 
          height: '24px'
        });

        video.capture.canvas = canvas.cloneNode();
        video.capture.canvas.width = video.width;
        video.capture.canvas.height = video.height;
        video.capture.context = video.capture.canvas.getContext('2d');

        video.area.onmouseenter = () => {
          clearTimeout(video.controls.timeout);
          video.controls.style.transform = 'translateY(0%)';
        }

        video.area.onmouseleave = () => {
          clearTimeout(video.controls.timeout);
          video.controls.style.transition = 'transform 0.25s ease';
          video.controls.timeout = setTimeout(() => {
            video.speed.elevator.style.height = '0px';
            video.controls.style.transform = 'translateY(100%)';
          }, 2000);
        }

        video.audio = {
          stopped: false, 
          frequency: 1, 
          callback: () => {}, 
          context: new AudioContext(), 
          silence: {}, 
          oscillator: {
            run: () => {
              let audio = video.audio;
              let context = audio.context;
              let silence = audio.silence;
              let frequency = audio.frequency;
              let oscillator = context.createOscillator();
              oscillator.onended = audio.oscillator.run;
              oscillator.connect(silence);
              oscillator.start(0);
              oscillator.stop(context.currentTime + frequency);
              audio.callback(context.currentTime);
              if (audio.stopped) {
                oscillator.onended = () => context.close();
              }
            }
          }, 
          loop: (callback, frequency) => {
            let audio = video.audio;
            audio.callback = callback;
            audio.frequency = frequency / 1000;
            audio.context = new AudioContext();
            let context = audio.context;
            audio.silence = context.createGain();
            let silence = audio.silence;
            silence.gain.value = 0;
            silence.connect(context.destination);
            audio.oscillator.run();
            return () => audio.stopped = true;
          }
        }

        video.capture.onclick = () => {
          if (video.capturing) return;
          if (!video.player.paused) return;
          //video.player.pause();
          video.capturing = true;
          video.capture.classList.add('recording');
          let playback_rate = video.player.playbackRate;
          let start = video.player.duration * video.slider.min / 100;
          //let end = video.player.duration * video.slider.max / 100;
          //let start_time = video.event.time(start);
          //let end_time = video.event.time(end);
          //let start_time = video.elapsed.textContent;
          //let end_time = video.duration.textContent;
          //let src = video.player.src;
          //let regex = /^(.*?)(#t=(.*?))$/;
          //let url = src.replace(regex, '$1');
          //let time = src.replace(regex, '$2');
          //let time = '#t=' + start_time/* + ',' + end_time*/;
          let chunks = [];
          let framerate = video.metadata.framerate;
          let stream = /*video.capture.canvas*/canvas.captureStream(framerate);
          let mimeType = 'video/webm';
          let order_id = this.parcel.increment_id;
          let meta = video.metadata;
          let file = mimeType.replace('/', '.').replace('video', `${order_id}_${(meta.name).toUpperCase()}_${meta.end_at}`);
          let frame_top = video.frame.style.top;
          let area_width = video.area.style.width;
          //let chrome = this.browser.chrome;
          let render = {};
          //if (chrome) {
            render.update = () => video.player.frame.update();
            render.stop = video.audio.loop(render.update, 1000 / framerate);
          //}
          //video.frame.style.removeProperty('top');
          //video.area.style.width = video.width + 'px';
          video.recorder = new MediaRecorder(stream);
          /*
          if (this.browser.firefox) mimeType += ';codecs=vp8';
          if (this.browser.chrome) mimeType += ';codecs=h264';
          video.recorder = new MediaRecorder(stream, {
            mimeType, ignoreMutedMedia: true, 
            videoBitsPerSecond: 2500000, 
            audioBitsPerSecond: 128000, 
          });
          */
          video.recorder.ondataavailable = (e) => {
            if (e.data.size > 0) chunks.push(e.data);
          }
          video.recorder.onstop = () => {
            /*chrome &&*/ render.stop();
            clearTimeout(video.timeout);
            video.timeout = setTimeout(() => {
              let type = video.blob.type;
              let blob = new Blob(chunks, {type});
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement('a');
              a.href = url;
              a.download = file;
              a.click();
              window.URL.revokeObjectURL(url);
            }, 0);
            video.frame.style.top = frame_top;
            video.area.style.width = area_width;
            video.player.playbackRate = playback_rate;
            video.capture.classList.remove('recording');
            video.capturing = false;
          }
          /*
          video.player.onloadstart = () => {
            video.elapsed.textContent = start_time;
            video.duration.textContent = end_time;
          }
          video.player.onloadedmetadata = () => {
            video.player.playbackRate = 1;
            video.recorder.start();
            video.player.play();
          }
          video.player.src = url + time;
          */
          video.player.currentTime = start;
          video.player.playbackRate = 1;
          video.recorder.start();
          video.player.play();
        }

        Object.assign(video.controls.style, {
          bottom: '0', 
          width: '100%', 
          height: '40px', 
          display: 'flex', 
          padding: '0 10px', 
          position: 'absolute', 
          alignItems: 'center', 
          transform: 'translateY(0%)', 
          backgroundColor: 'rgba(0, 0, 0, 0.65)'
        });
        
        video.toggle.classList.add('icon', 'play');

        Object.assign(video.fullscreen.style, {
          marginLeft: 'auto'
        });

        video.fullscreen.classList.add('icon', 'expand');

        video.speed = {
          labels: {},
          values: [0.0625, 0.5, 1, 2, 4, 7, 10], 
          elevator: element.cloneNode(), 
          container: element.cloneNode(), 
          wrapper: element.cloneNode(), 
          steps: element.cloneNode(), 
          toggle: element.cloneNode(), 
          slider: document.createElement('input')
        }
        /*
        if (this.browser.firefox) {
          video.speed.values.length = 5;
        }
        */
        Object.assign(video.speed.toggle.style, {
          padding: '10px',  
          position: 'relative'
        });

        video.speed.slider.type = 'range';

        video.speed.toggle.dataset.value = 1;

        video.speed.toggle.classList.add('icon', 'speed');

        video.speed.toggle.onclick = (e) => {
          if (e.target != video.speed.toggle) return;
          let elevator = video.speed.elevator;
          let hidden = parseInt(elevator.style.height);
          elevator.style.height = hidden ? 0 : 116 + 'px';
          if (!hidden) {
            let transition = this.Style(elevator, 'transition-duration');
            let duration = parseFloat(transition) * 1000;
            clearTimeout(video.speed.timeout)
            video.speed.timeout = setTimeout(() => {
              video.speed.slider.focus();
            }, duration);
          } else {
            video.area.focus();
          }
        }

        Object.assign(video.speed.elevator.style, {
          top: '0', 
          left: '0', 
          width: '100%', 
          height: '0px', 
          display: 'flex', 
          cursor: 'default', 
          overflow: 'hidden', 
          position: 'absolute', 
          willChange: 'height', 
          flexDirection: 'column', 
          justifyContent: 'flex-start', 
          borderRadius: '6px 6px 0 0', 
          transform: 'translateY(-100%)', 
          transition: 'height 0.25s ease', 
          backgroundColor: 'rgba(0, 0, 0, 0.65)'
        });

        Object.assign(video.speed.container.style, {
          top: '0', 
          left: '0', 
          width: '100%', 
          height: '116px', 
          display: 'flex', 
          flexShrink: '0', 
          padding: '12px 0 4px', 
          position: 'absolute', 
          flexDirection: 'column', 
          justifyContent: 'flex-end'
        });

        Object.assign(video.speed.wrapper.style, {
          width: '100%', 
          height: '100px', 
          display: 'flex', 
          position: 'relative', 
          alignItems: 'center', 
          justifyContent: 'center'
        });
        video.speed.wrapper.classList.add('slider-background');

        Object.assign(video.speed.slider.style, {
          width: '100px', 
          height: '40px', 
          outline: 'none', 
          display: 'flex', 
          position: 'relative', 
          alignItems: 'center', 
          backgroundSize: '100% 8px', 
          backgroundRepeat: 'no-repeat', 
          backgroundPosition: 'center', 
          //backgroundImage: 'linear-gradient(#dbdbdb, #dbdbdb)'
        });

        Object.assign(video.speed.steps.style, {
          width: '100%', 
          height: '100%', 
          display: 'flex',
          fontSize: '9px', 
          color: '#ffffff', 
          fontWeight: '600', 
          position: 'absolute', 
          alignItems: 'center', 
          flexDirection: 'column', 
          justifyContent: 'space-evenly', 
          pointerEvents: 'none', 
          lineHeight: '7px'
        });

        video.speed.slider.min = 0;
        video.speed.slider.max = video.speed.values.length - 1;
        video.speed.slider.step = 1;
        video.speed.slider.value = video.speed.values.indexOf(1);
        video.speed.slider.classList.add('slider', 'vertical');

        let labels = document.createDocumentFragment();
        for (let value of video.speed.values) {
          let span = document.createElement('span');
          video.speed.labels[value] = span;
          if (value == 0.0625) value = 0.1;
          span.style.opacity = value == 1 ? 1 : 0;
          span.textContent = value;
          labels.prepend(span);
        }

        video.speed.slider.oninput = () => {
          let index = video.speed.slider.value;
          let value = video.speed.values[index];
          video.player.speed.set(value, true);
          video.speed.slider.blur();
        }

        video.speed.steps.append(labels);
        video.speed.wrapper.append(video.speed.slider, video.speed.steps);
        video.speed.container.append(video.speed.wrapper);
        video.speed.elevator.append(video.speed.container);
        video.speed.toggle.append(video.speed.elevator);

        for (let elm of [video.toggle, video.speed.toggle, video.fullscreen]) {
          elm.style.flexShrink = '0';
        }

        Object.assign(video.seeker.style, {
          width: '100%', 
          height: '40px', 
          margin: '0 8px', 
          display: 'flex', 
          position: 'relative', 
          alignItems: 'center', 
        });

        video.slider = document.createElement('input');

        video.slider.type = 'range';

        video.range = {
          container: element.cloneNode(),  
          slider: {
            wrapper: element.cloneNode(), 
            inverse: {
              left: element.cloneNode(), 
              right: element.cloneNode()
            }, 
            track: element.cloneNode(), 
            knob: {
              left: element.cloneNode(), 
              right: element.cloneNode()
            }, 
            sign: {
              left: {
                value: ''
              }, 
              right: {
                value: ''
              }
            },
            progress: () => {
              if (this.browser.chrome) {
                let min = video.slider.min;
                let max = video.slider.max;
                let value = video.slider.value;
                let progress = (value - min) * 100 / (max - min);
                video.slider.style.setProperty('--progress', progress + '%');
              }
            }
          }, 
          left: video.slider.cloneNode(), 
          right: video.slider.cloneNode()
        }

        video.range.slider.track.append(video.slider);

        video.range.slider.wrapper.append(
          video.range.slider.inverse.left, 
          video.range.slider.inverse.right, 
          video.range.slider.track, 
          video.range.slider.knob.left, 
          video.range.slider.knob.right
        );

        video.range.container.append(
          video.range.slider.wrapper, 
          video.range.left, 
          video.range.right
        );

        video.seeker.append(video.range.container);

        video.range.container.classList.add('range-container');
        video.range.slider.wrapper.classList.add('slider-wrapper');
        video.range.slider.track.classList.add('slider-track');
        video.slider.classList.add('slider');
        
        Object.assign(video.range.container.style, {
          width: '100%', 
          height: '100%', 
          display: 'flex', 
          position: 'relative', 
          alignItems: 'center'
        });

        Object.assign(video.range.slider.wrapper.style, {
          left: '11px', 
          right: '9px', 
          height: '100%', 
          display: 'flex', 
          position: 'absolute', 
          alignItems: 'center', 
          borderRadius: '50%', 
          backgroundSize: '100% 8px', 
          backgroundRepeat: 'no-repeat', 
          backgroundPosition: 'center', 
          //backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))'
        });

        Object.assign(video.range.slider.track.style, {
          left: '0%', 
          right: '0%', 
          height: '100%', 
          display: 'flex', 
          position: 'absolute', 
          alignItems: 'center', 
          backgroundSize: '100% 8px', 
          backgroundRepeat: 'no-repeat', 
          backgroundPosition: 'center', 
          backgroundImage: 'linear-gradient(#dbdbdb, #dbdbdb)'
        });

        Object.assign(video.slider.style, {
          left: '0px', 
          width: '100%', 
          height: '100%', 
          outline: 'none', 
          position: 'absolute'
        });

        if (this.browser.chrome) {
          video.slider.style.setProperty('--progress', '0%');
        }
        
        for (let side of ['left', 'right']) {
          video.range.slider.inverse[side].classList.add(`inverse-${side}`);
          video.range.slider.knob[side].classList.add(`knob-${side}`);
          video.range[side].classList.add(`range-${side}`);

          video.range.slider.inverse[side].style[side] = '0';
          Object.assign(video.range.slider.inverse[side].style, {
            height: '8px', 
            margin: `0 ${side == 'left' ? '7' : '9'}px`, 
            position: 'absolute', 
            //backgroundColor: 'rgba(0, 0, 0, 0.5)', 
            backgroundColor: `#${side == 'left' ? '002857' : '333333'}`, 
            borderRadius: '4px', 
            zIndex: '2'
          });

          Object.assign(video.range.slider.knob[side].style, {
            left: `${side == 'left' ? '0' : '100'}%`, 
            zIndex: '2', 
            height: '20px', 
            width: '20px', 
            textAlign: 'left', 
            marginLeft: '-11px', 
            //cursor: 'pointer', 
            position: 'absolute', 
            boxShadow: '0 3px 8px rgba(0, 0, 0, 0.40)', 
            backgroundColor: '#ffffff', 
            borderRadius: '50%', 
            outline: 'none', 
            willChange: 'background-image'
          });

          Object.assign(video.range[side].style, {
            width: '100%', 
            height: '8px', 
            zIndex: '3', 
            opacity: '0', 
            position: 'absolute', 
            pointerEvents: 'none', 
            appearance: 'none'
          });

          video.range[side].min = 0;
          video.range[side].max = 100;
          video.range[side].step = 1;
          video.range[side].value = side == 'left' ? 0 : 100;

          video.range[side].ondblclick = () => {
            video.slider.value = side == 'left' ? 0 : 100;
            video.slider.dispatchEvent(new Event('input'));
            video.range[side].blur();
          }

          video.range[side].onfocus = () => {
            video.range.slider.knob[side].style.setProperty('background-image', 'radial-gradient(10px circle at center, #0075ff 50%, transparent 51%)');
            video.range[side].blur();
          }

          video.range[side].onblur = () => {
            video.range.slider.knob[side].style.removeProperty('background-image');
          }
        }
        
        video.slider.min = 0;
        video.slider.max = 100;
        video.slider.step = 1;
        video.slider.value = 0;
        
        video.range.left.oninput = () => {
          //video.player.pause();
          let left = video.range.left;
          let right = video.range.right;
          let slider = video.range.slider;
          left.value = Math.min(left.value, right.value);
          let value = (left.value / parseInt(left.max)) * 100;
          slider.inverse.left.style.width = value + '%';
          slider.track.style.left = value + '%';
          slider.knob.left.style.left = value + '%';
          video.slider.min = left.value;
          let seconds = video.player.duration * left.value / 100;
          if (seconds >= video.player.currentTime) {
            video.player.currentTime = seconds;
            video.elapsed.textContent = video.event.time(seconds);
          }
          video.range.slider.progress();
        }
        
        video.range.right.oninput = () => {
          //video.player.pause();
          let left = video.range.left;
          let right = video.range.right;
          let slider = video.range.slider;
          right.value = Math.max(right.value, left.value);
          let value = (right.value / parseInt(right.max)) * 100;
          slider.inverse.right.style.width = (100 - value) + '%';
          slider.track.style.right = (100 - value) + '%';
          slider.knob.right.style.left = value + '%';
          video.slider.max = right.value;
          let seconds = video.player.duration * right.value / 100;
          video.duration.textContent = video.event.time(seconds);
          if (seconds <= video.player.currentTime) {
            video.player.currentTime = seconds;
          }
          video.range.slider.progress();
        }
        
        for (let elm of [video.handle, video.container]) {
          Object.assign(elm.style, {
            inset: 0, 
            //display: 'flex', 
            position: 'absolute', 
            //alignItems: 'center'
          });
        }

        for (let elm of [video.capture, video.camera, /*video.video*/]) {
          elm.classList.add('icon');
          if (elm == video.capture) {
            //elm.classList.add('save');
            elm.classList.add('video');
          }
          /*
          if (elm == video.video) {
            Object.assign(elm.style, {
              width: '24px', 
              height: '24px'
            });
          }
          */
        }

        Object.assign(video.timer.style, {
          display: 'flex', 
          padding: '0 10px', 
          position: 'relative', 
          alignItems: 'center', 
          userSelect: 'none', 
          whiteSpace: 'pre', 
          fontWeight: '600', 
          fontSize: '14px', 
          color: '#808080'
        });

        Object.assign(video.elapsed.style, {
          position: 'relative', 
          userSelect: 'none', 
          fontWeight: '400', 
          color: '#ffffff'
        });

        Object.assign(video.duration.style, {
          position: 'relative', 
          userSelect: 'none'
        });

        for (let elm of [video.elapsed, video.duration]) {
          elm.textContent = '00:00';
        }

        video.duration.textContent = video.event.time(video.duration);

        video.timer.append(video.elapsed, ' / ', video.duration);

        video.timeline.update = () => {
          let div = document.createElement('div');
          let fragment = document.createDocumentFragment();
          let keyframes = this.CloneObject(video.keyframe);
          let frames = Object.values(keyframes);
          let previous = frames[0].rect;
          let events = [];
          let count = 1;
          for (let i = 0; i < frames.length; i++) {
            let rect = frames[i].rect;
            if (JSON.stringify(rect || {}) != JSON.stringify(previous || {}) || i == frames.length - 1) {
              let size = (100 * count) / frames.length;
              if (!previous) {
                previous = {size};
              } else {
                previous.size = size;
              }
              events.push(previous);
              count = 1;
            } else {
              count++;
            }
            previous = rect;
          }
          for (let event of events) {
            let element = div.cloneNode();
            element.style.width = event.size + '%';
            element.className = 'event';
            element.draggable = false;
            if ('canvas' in event) {
              element.classList.add('censored');
              element.ondblclick = () => {
                let percent = 0;
                let siblings = Tool.PreviousSiblings(element);
                for (let sibling of siblings) {
                  percent += parseFloat(sibling.style.width);
                }
                let seconds = video.player.duration * percent / 100;
                video.player.currentTime = seconds;
              }
            }
            fragment.append(element);
          }
          this.Empty(video.censor);
          video.censor.append(fragment);
        }

        video.top.append(video.handle, video.capture, /*video.video,*/ video.camera, /*video.save, video.circle, video.box,*/ video.title, video.close);
        video.controls.append(video.toggle, video.speed.toggle, video.seeker, video.timer, video.fullscreen);
        video.timeline.append(video.line, video.censor, video.cursor);
        video.area.append(video.container, video.controls);
        video.frame.append(video.top, video.area);
        video.bottom.append(video.timeline);
        video.layer.append(video.frame);
        document.body.append(video.layer);

        this.video = video;
        this.loading = true;
        let loading = await this.$nextTick().then(() => this.$refs.loading);
        loading.style.setProperty('background', 'none');
        video.player = await this.GetOrderVideo(video) || {};
        this.loading = false;

        if (!video.player.src) {
          video.layer.remove();
          return this.video = {};
        }

        video.duration.textContent = video.event.time(video.player.duration);

        video.player.frame = {
          count: 0, 
          metadata: {},
          interval: null, 
          startTime: 0.0, 
          lastTime: -1, 
          rate: video.metadata.framerate, 
          rect: { 
            update: async (rect = {rect: null}) => {
              return await new Promise(async (resolve) => {
                await video.player.frame.rect.set(rect);
                resolve(video.canvas.shape.rect);
              });
            }, 
            setting: async () => {
              return await new Promise((resolve) => {
                let check = () => {
                  clearTimeout(check.timeout);
                  if (!video.player.frame.rect.set.setting) {
                    return resolve(true);
                  }
                  check.timeout = setTimeout(check);
                }
                check();
              });
            }, 
            set: async (frame = {rect: null}) => {
              return await new Promise((resolve) => {
                let player = video.player;
                let index = player.frame.index();
                let total = player.frame.index(true);
                let keyframe = video.keyframe;
                let frame_break = 'break' in frame ? frame.break : true;
                keyframe[index] = {...frame, ...{break: frame_break}};
                player.frame.rect.set.setting = true;
                const resolve_setting = () => {
                  player.frame.rect.set.setting = false;
                  resolve(keyframe);
                }
                index++;
                for (index; index <= total; index++) {
                  let prev_frame = keyframe[index - 1] || {};
                  let next_frame = keyframe[index + 1] || {};
                  if (!prev_frame.rect && !next_frame.rect) {
                    delete keyframe[index].break;
                  }
                  if (!keyframe[index].break) {
                    keyframe[index] = frame;
                  } else {
                    return resolve_setting();
                  }
                  if (index == total) {
                    resolve_setting();
                  }
                }
              });
            }, 
            get: async () => {
              return await new Promise(async (resolve) => {
                await video.player.frame.rect.setting();
                let frame = video.player.frame.get();
                let rect = this.CloneObject(frame.rect);
                if (video.canvas.event.draw) {
                  rect = frame.rect;
                }
                resolve(rect);
              });
            }
          },
          get: (index = video.player.frame.index()) => {
            return video.keyframe[index];
          },
          index: (last = false) => {
            // https://codepen.io/Yuschick/post/html5-currenttime-and-frame-numbers
            let rate = video.metadata.framerate;
            let time = video.player.currentTime;
            if (last) time = video.player.duration;
            return Math.floor(time * rate);
          }, 
          predict: () => {
            // https://github.com/RSATom/WebChimera.js/issues/92#issuecomment-233190947
            let frame = video.player.frame;
            let interval = Math.round(1000 / frame.rate);
            frame.interval = setInterval(() => {
              frame.count++;
              console.log(frame.count)
            }, interval);
          }, 
          clear: () => {
            let frame = video.player.frame;
            clearInterval(frame.interval);
          }, 
          reset: () => {
            let frame = video.player.frame;
            frame.clear();
            frame.count = 0;
          }, 
          callback: (now, metadata) => {
            // https://web.dev/requestvideoframecallback-rvfc/
            // https://requestvideoframecallback.glitch.me/script.js
            let frame = video.player.frame;
            if (frame.startTime === 0.0) {
              frame.startTime = now;
            }
            frame.metadata = metadata;
            let elapsed = (now - frame.startTime) / 1000.0;
            frame.rate = (++frame.count / elapsed).toFixed(3);
            video.player.requestVideoFrameCallback(frame.callback);
          }, 
          update: (resizing) => {
            let style = this.Style(video.area);
            if (!Object.keys(style).length) return;
            let width = parseInt(style.width);
            let height = parseInt(style.paddingTop);
            let player = video.player;
            let cnvs = video.canvas;
            let event = cnvs.event;
            let draw = cnvs.draw;
            let frame = player.frame.get();
            let rect = frame.rect;
            canvas.width = width;
            canvas.height = height;
            video.event.resizing = resizing ? true : false;
            video.scale = Math.min(width / video.width, height / video.height);
            cnvs.clear();
            if (event.draw && draw.allowed) {
              draw.area();
            } else if (rect) {
              draw.pixelate();
            }
            if (video.capturing) {
              let capture = video.capture;
              let capture_context = capture.context;
              //capture_context.save();
              capture_context.scale(video.width / canvas.width, video.height / canvas.height);
              capture_context.clearRect(0, 0, video.width, video.width);
              capture_context.drawImage(canvas, 0, 0);
              //capture_context.restore();
            }
          },
          request: () => {
            let player = video.player;
            let frame = player.frame;
            if (player.paused || player.ended) return;
            if (frame.lastTime != player.currentTime) {
              frame.update();
              frame.lastTime = player.currentTime;
              //console.log(frame.index(), 'requestAnimationFrame');
            }
            window.requestAnimationFrame(frame.request);
          }
        }
        
        // Setup video frame index for key frame animation
        await new Promise((resolve) => {
          let total = video.player.frame.index(true);
          for (let index = 0; index <= total; index++) {
            video.keyframe[index] = {rect: null};
            if (index == total) resolve(true);
          }
        });
        //console.log(this.CloneObject(video.keyframe))
        
        video.player.speed = {set: (value = 1, slider = false) => {
          let index = video.speed.values.indexOf(value);
          let speed = video.speed.values[index];
          let span = video.speed.labels[speed];
          if (!slider) video.speed.slider.value = index;
          else video.player.playbackRate = speed;
          for (let label of Object.values(video.speed.labels)) {
            label.style.setProperty('opacity', label == span ? '1' : '0');
            if (label == span) video.speed.toggle.dataset.value = span.textContent;
          }
          let percent = (index / video.speed.values.length) * 100;
          video.speed.wrapper.style.setProperty('--progress', percent + '%');
        }};

        video.player.observer = {resize: new ResizeObserver(() => {
          video.canvas.client = canvas.getBoundingClientRect();
          video.player.frame.update(true);
        })};

        video.player.state = {
          toggle: () => {
            let stopped = video.player.paused || video.player.ended;
            video.player[stopped ? 'play' : 'pause']();
          }
        }

        video.player.oncanplay = () => {
          video.controls.style.transition = 'none';
          video.controls.style.transform = 'translateY(0%)';
          if (!video.area.querySelector(':hover')) {
            video.area.dispatchEvent(new Event('mouseleave'));
          }
        }
        
        video.player.onplay = () => {
          let cnvs = video.canvas;
          let draw = cnvs.draw;
          draw.allowed = false;
          //let current = video.player.currentTime / video.slider.max * 100;
          let start = video.player.duration * video.slider.min / 100;
          let end = video.player.duration * video.slider.max / 100;
          //console.log(current, video.player.currentTime, end)
          if (video.player.currentTime >= end) {
            video.player.currentTime = start;
          }
          if (video.capturing) {
            video.toggle.classList.remove('play');
            video.toggle.classList.add('pause');
          } else {
            video.toggle.classList.toggle('pause');
            video.toggle.classList.toggle('play');
          }
          canvas.style.cursor = 'default';
          video.player.frame.request();
        }

        video.player.onpause = async () => {
          let cnvs = video.canvas;
          let shape = cnvs.shape;
          let draw = cnvs.draw;
          let player = video.player;
          let frame = player.frame.get();
          draw.allowed = !frame.rect;
          canvas.style.cursor = draw.allowed ? 'crosshair' : 'default';
          ['pause', 'play'].map(c => video.toggle.classList.toggle(c));
          //video.toggle.classList.toggle('pause');
          //video.toggle.classList.toggle('play');
          video.player.frame.update();
          if (video.recorder && video.capturing) {
            video.recorder.stop();
          }
          if (await shape.inside()) {
            draw.select(false);
          }
        }
        /*
        video.player.onseeking = () => {
          video.player.frame.update();
        }
        */
        video.player.onseeked = () => {
          video.player.frame.update();
        }

        video.player.ontimeupdate = () => {
          let player = video.player;
          let frame = player.frame.get();
          let draw = video.canvas.draw;
          let slider = {
            min: parseInt(video.slider.min), 
            max: parseInt(video.slider.max), 
            value: parseInt(video.slider.value)
          };
          let current_seconds = player.currentTime;
          let current_value = current_seconds / player.duration * 100;
          let start_seconds = player.duration * slider.min / 100;
          let end_seconds = player.duration * slider.max / 100;
          //let slider_seconds = player.duration * slider.value / 100;
          //let seconds = () => player.duration * video.slider.value / 100;
          /*
          console.log('start_seconds', start_seconds)
          console.log('current_seconds', current_seconds)
          console.log('slider_seconds', slider_seconds)
          console.log('seconds()', seconds())
          console.log('end_seconds', end_seconds)
          console.log('--------------------------------------')
          console.log('slider.min', slider.min)
          console.log('current_value', current_value)
          console.log('slider.max', slider.max)
          console.log('--------------------------------------')
          */
          if (current_seconds < start_seconds || current_seconds > end_seconds) {
            if (current_seconds < start_seconds) {
              current_seconds = start_seconds;
            }
            if (current_seconds > end_seconds) {
              current_seconds = end_seconds;
              player.pause();
            }
            player.currentTime = current_seconds;
          } else {
            video.slider.value = current_value;
            video.range.slider.progress();
          }
          draw.allowed = player.paused && !frame.rect;
          video.elapsed.textContent = video.event.time(current_seconds);
          video.cursor.style.left = `calc(${current_value}% - 1px)`;
          canvas.style.cursor = draw.allowed ? 'crosshair' : 'default';
        }

        video.player.onratechange = () => {
          let rate = video.player.playbackRate;
          let value = video.speed.values.reduce((prev, curr) => {
            return (Math.abs(curr - rate) < Math.abs(prev - rate) ? curr : prev);
          });
          video.player.speed.set(value);
        }

        video.player.dispatchEvent(new Event('ratechange'));

        video.slider.oninput = () => {
          let seconds = video.player.duration * video.slider.value / 100;
          video.elapsed.textContent = video.event.time(seconds);
          video.player.currentTime = seconds;
          video.range.slider.progress();
          video.slider.blur();
          //video.player.focus();
        }

        video.slider.onchange = () => {
          //video.player.focus();
        }

        for (let elm of [/*canvas,*/ video.toggle]) {
          elm.onclick = () => {
            video.player.state.toggle();
            //video.player.focus();
          }
        }
        /*
        canvas.ondblclick = () => {
          video.fullscreen();
        }
        */
        video.fullscreen.onclick = () => {
          video.fullscreen();
        }

        video.fullscreen = () => {
          if (!document.fullscreenElement) {
            video.player.requestFullscreen();
          } else {
            if (document.exitFullscreen) {
              document.exitFullscreen();
            }
          }
        }

        video.snapshot = () => {
          if (!canvas) return;
          video.canvas.clear(buffer.context);
          buffer.canvas.width = canvas.width;
          buffer.canvas.height = canvas.height;
          buffer.context.drawImage(canvas, 0, 0, canvas.width, canvas.height);
          return video.snapshot.canvas = buffer.canvas;
        }

        video.camera.onclick = () => {
          //if (!video.event.editor.show) {
            video.event.flash();
            video.event.snapshot();
          //}
        }
        
        /*
        video.save.onclick = async () => {
          if (!video.snapshot.canvas) return;
          let image = await new Promise(resolve => video.snapshot.canvas.toBlob(resolve));
          let format = image.type.split('/')[1];
          if (window.showSaveFilePicker) {
            let handle = await window.showSaveFilePicker({
              suggestedName: video.name, types: [{
                accept: {[image.type]: ['.' + format]},
              }],
            });
            let writable = await handle.createWritable();
            await writable.write(image);
            writable.close();
          } else {
            BPA.api.download({name: video.name, blob: image});
          }
          video.event.reset();
        }
        */

        video.resolve.onclick = () => {
          if (!video.snapshot.canvas) return;
          let clipboard = window.navigator.clipboard;
          console.log(clipboard, window.ClipboardItem)
          if (clipboard && window.ClipboardItem) {
            let clipboardItem = blob => new window.ClipboardItem({[blob.type]: blob});
            video.snapshot.canvas.toBlob(blob => clipboard.write([clipboardItem(blob)]));
            this.$eventHub.$emit('ShowMessages', {
              message: 'Copied to clipboard', 
              type: 'success', 
              hide: 2000
            });
            //video.event.flash();
          }
          video.event.reset();
        }

        video.reject.onclick = () => {
          video.event.reset();
        }
        /*
        video.handle.ondblclick = () => {
          clearTimeout(video.handle.timeout);
          if (video.handle.maximized) {
            video.frame.style.removeProperty('top');
            video.frame.style.removeProperty('left');
            video.area.style.width = video.width + 'px';
            return video.handle.timeout = setTimeout(() => {
              video.canvas.client = canvas.getBoundingClientRect();
              video.handle.maximized = false;
              video.canvas.draw.scale();
            }, 0);
          }
          video.handle.maximize();
        }
        */
        video.handle.maximize = () => {
          clearTimeout(video.handle.timeout);
          let area_style = (() => {
            let area = this.Style(video.area);
            let width = parseInt(area.width);
            let height = parseInt(area.paddingTop);
            return {width, height};
          })();
          let area_width = area_style.width;
          let area_height = area_style.height;
          let not_area_height = video.frame.clientHeight - area_height;
          let body_height = document.body.clientHeight - not_area_height;
          let adjusted_width = Math.round((body_height * area_width) / area_height);
          // https://math.stackexchange.com/a/180805
          video.area.style.width = adjusted_width + 'px';
          video.frame.style.removeProperty('left');
          video.frame.style.top = '0px';
          video.handle.timeout = setTimeout(() => {
            video.canvas.client = canvas.getBoundingClientRect();
            video.handle.maximized = true;
            video.canvas.draw.scale();
          }, 0);
        }
        
        video.title.textContent = `${video.pack.pos}${video.pack.res_user_full_name ? `: ${video.pack.res_user_full_name}` : ''}`;

        Object.assign(video.player.style, {
          width: '100%', 
          height: '100%', 
          outline: 'none', 
          position: 'relative'
        });

        video.player.observer.resize.observe(video.area);
        
        video.player.style.setProperty('display', 'none');

        video.player.addEventListener('fullscreenchange', () => {
          if (document.fullscreenElement) {
            video.player.style.removeProperty('display');
          } else {
            video.player.style.setProperty('display', 'none');
          }
        });

        video.container.append(video.player, canvas);

        video.player.play();

        video.canvas.init();

        video.player.frame.index(true);

        //video.player.requestVideoFrameCallback(video.player.frame.callback);

        //console.log(video.player.getVideoPlaybackQuality())

        //console.log(video)
      },
      VideoItem(event, video) {
        if (!event) return;
        let row = {};
        let elm = event.target;
        if (elm.localName != 'tr') row = elm.closest('tr');
        const filter = ['actions', 'icon dots'];
        const mousedown = (state) => {
          if (state === true || state === false) {
            row.mousedown = state;
          } else {
            return row.mousedown;
          }
        }
        if (elm.localName == 'a') return;
        if (elm.style.display == 'table-cell') return;
        if (filter.some(x => new RegExp(x).test(elm.className))) return;
        if (event.type == 'mousedown') {
          mousedown(event.which == 1);
        }
        if (event.type == 'mousemove') {
          mousedown(false);
        }
        if (event.type == 'mouseup') {
          if (mousedown()) {
            this.PlayOrderVideo(video);
          }
          mousedown(false);
        }
      },
      IsSuperUser() {
        return this.Check([/*0*/ 'admin']);
      },
      IsMainCompany() {
        return BPA.util.IsMainCompany();
      },
      AllowCompanySearch() {
        return this.IsSuperUser() && this.IsMainCompany();
      },
      async CanSearchParcelShops() {
        return await new Promise((resolve, reject) => {
          if ('parcel_shop_search' in this) {
            return resolve(this.parcel_shop_search);
          }
          BPA.api.HasGoogleMapsAPIKey().then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            this.parcel_shop_search = response.result.has_google_maps;
            resolve(this.parcel_shop_search);
          }).catch(reject);
        }).catch(e => e);
      },
      async GetOrderSMSTemplateList() {
        return await BPA.api.GetOrderSMSTemplateList().then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok) return;
          let list = response.result || [];
          return list.sort((a, b) => b.id - a.id);
        }).catch(e => e);
      },
      async GetOrderSMSTemplate(template_id = '') {
        if (!template_id) return;
        return await BPA.api.GetOrderSMSTemplate(template_id).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok || !response.result) return;
          return response.result;
        }).catch(e => e);
      },
      async GetProductLinkList() {
        return await BPA.api.GetProductLinkList().then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok) return;
          return response.result || [];
        }).catch(e => e);
      },
      ProductLinkLogoClassName(name = '') {
        let names = ['Akeneo', 'Magento', 'Odoo'];
        let match = name.match(new RegExp(names.join('|'), 'i'));
        return match ? match[0].toLowerCase() : '';
      },
      FormatText(event) {
        let input = event.target;
        let value = input.value;
        let lines = value.split('\n');
        let popup = this.bulk.action.popup;
        let text = [];
        for (let i = 0; i < lines.length; i++) {
          let line = lines[i].replace(/\s+/g, ' ').trim();
          if (i == 0) line = this.Capitalize(line);
          text.push(line);
        }
        input.value = text.join('\n');
        input.dispatchEvent(new Event('input'));
        popup.selection = {
          start: input.selectionStart, 
          end: input.selectionEnd
        };
      },
      FitToContent(event) {
        let input = event.target;
        input.style.height = ''; 
        input.style.height = `${input.scrollHeight + 2}px`;
      },
      PopupClickAround(e) {
        const event = e.type;
        const clicked = e.target;
        if (event == 'mousedown') {
          this.popup.mousedown = clicked;
        } else {
          const mousedown = this.popup.mousedown;
          const popup = document.querySelector('.validate-popup');
          const cancel = popup.querySelector('a.cancel');
          if ([popup, cancel].includes(clicked) && clicked == mousedown) {
            this.popup.return_label.courier_default = {};
            this.popup.return_label.courier_selected = {};
            this.popup.return_label.courier_value = '';
            this.popup.return_label.show = false;
            this.$eventHub.$off('ValidateModalStop');
          }
          this.popup.mousedown = null;
        }
      },
      PopupCourierSelect(option) {
        if (!option) option = this.popup.return_label.courier_default;
        this.popup.return_label.courier_selected = option;
        this.popup.return_label.courier_value = this.popup.return_label.courier_selected.label;
      },
      PopupConfirm() {
        this.$eventHub.$emit('ValidateModalStop', true);
        this.popup.return_label.show = false;
      },
      SetActiveModalTab(modal_name, tab) {
        this.modal[modal_name].tab.active = tab;
        const modal = document.getElementById(modal_name.replace(/_/g, '-'));
        modal && modal.querySelectorAll('ul.modal-tabs__body').forEach(ul => ul.scrollTop = 0);
      },
      async SetActiveTab(e) {
        let tab = e.target.dataset.tab;
        if (!tab) tab = e.target.closest('[data-tab]').dataset.tab;
        if (tab == 'order') {
          let ordered_items = this.parcel.ordered_items;
          let tab_order = this.modal.view_parcel.tab.order;
          if (!tab_order.product_link.length) {
            tab_order.product_link = await this.GetProductLinkList();
          }
          for (let item of ordered_items) {
            item.product_link = [];
            for (let link of tab_order.product_link) {
              item.product_link.push({name: link.name, link: link.link.replace(/\{\{.*\}\}/, item.sku)});
            }
            this.Alphabetize(item.product_link, 'name');
          }
        }
        if (/videos|tracking/.test(tab)) {
          await this.GetOrderVideoList(this.parcel.id);
        }
        this.modal.view_parcel.tab.shipping.editing = false;
        this.modal.view_parcel.tab.active = tab;
        this.$nextTick().then(async () => {
          let input = null;
          if (tab == 'order') {
            let modal_tabs_body = document.querySelector('.modal-tabs__body');
            //let origin_countries = modal_tabs_body.querySelectorAll('input[list=origin_country]');
            input = document.querySelector('#view-parcel .total-weight input');
            if (modal_tabs_body) {
              this.$nextTick().then(() => {
                modal_tabs_body.dispatchEvent(new Event('scroll'));
                //Array.from(origin_countries).map(input => input.dispatchEvent(new Event('input')));
              });
            }
            if (input) {
              let placeholder = input.nextElementSibling;
              let width = placeholder.clientWidth;
              input.style.width = width + 'px';
            }
          }
          if (tab == 'tracking') {
            let pack_log = this.parcel.pack_log;
            let pack_log_list = [...document.querySelectorAll('.tracking-history .list-content .list-row')];
            for (let item of pack_log_list) {
              let datetime = item.dataset.datetime;
              let pack_log_item = pack_log.find(item => (new Date(item.date)).getTime() == datetime) || {};
              if (pack_log_item.video) {
                let play_circle = item.querySelector('.play-circle');
                play_circle.classList.add('enabled');
              }
            }
            await this.HandleLabelCancelState();
          }
          if (tab == 'status') {
            input = document.querySelector('#view-parcel .message-history input');
            if (input) input.value = this.modal.view_parcel.tab.status.message;
          }
        });
        Object.values(this.modalTabsBody).map(ul => ul.scrollTop = 0);
      },
      PageController(next_page) {
        let load_page;
        const page = {};
        document.querySelectorAll('.page-turn').forEach(arrow => {
          if (arrow) page[arrow.classList.contains('prev') ? 'prev' : 'next'] = arrow;
        });
        if (next_page) {
          if (this.page.current != this.page.last) {
            this.page.current++;
            load_page = true;
            if (this.page.current == this.page.last) {
              page.next.classList.add('disabled');
            }
            page.prev.classList.remove('disabled');
          }
        } else {
          if (this.page.current > 1) {
            this.page.current--;
            load_page = true;
            if (this.page.current == 1) {
              page.prev.classList.add('disabled');
            }
            page.next.classList.remove('disabled');
          }
        }
        if (load_page) {
          this.QueryParcels();
        }
      },
      PageNavigator(e) {
        const page = {
          event: e.type,
          input: e.target,
          last: Number(e.target.max),
          first: Number(e.target.min),
          number: Number(e.target.value)
        }
        const within_limits = (n) => {
          return n >= page.first && n <= page.last;
        }
        const set_page_number = (n) => {
          this.page.number = n || page.number;
          page.number = Number(this.page.number);
        }
        document.querySelectorAll('.page-turn').forEach(arrow => {
          if (arrow) page[arrow.classList.contains('prev') ? 'prev' : 'next'] = arrow;
        });
        set_page_number();
        if (page.event == 'keydown') {
          if (e.key == 'Enter') {
            page.input.blur();
          }
        }
        if (page.event == 'blur') {
          ['prev', 'next'].map(arrow => {
            page[arrow] && page[arrow].classList.remove('disabled');
          });
          if (page.number <= page.first) {
            set_page_number(page.first);
            page.input.value = page.first;
            page.prev.classList.add('disabled');
            if (page.last == page.first) {
              page.next.classList.add('disabled');
            }
          } else if (page.number >= page.last) {
            set_page_number(page.last);
            page.input.value = page.last;
            page.next.classList.add('disabled');
            if (page.first == page.last) {
              page.prev.classList.add('disabled');
            }
          }
          if (within_limits(page.number) && page.number != this.page.current) {
            this.page.current = page.number;
            this.QueryParcels();
          }
        }
      },
      SetPageJumpWidth() {
        const current = document.querySelector('.page-number-current');
        const last = document.querySelector('.page-number-last');
        if (current && last) {
          const rect = last.getBoundingClientRect();
          const input = current.querySelector('input');
          if (rect && rect.width) {
            current.style.setProperty('width', rect.width + 'px');
          } else {
            current.style.removeProperty('width');
          }
          if (input) {
            input.dispatchEvent(new Event('blur'));
          }
        }
      },
      SetOrderBy(value) {
        if (this.page.order.by == value) {
          if (this.page.order.direction == 'desc') {
            this.page.order.direction = 'asc';
          } else {
            this.page.order.direction = 'desc';
          }
        }
        this.page.order.by = value;
        this.QueryParcels();
      },
      async ValidateForm(button) {
        return await new Promise(resolve => {
          if (button) {
            const form = button.closest('form');
            const fields = Array.from(form.elements);
            if (fields.some(field => !field.checkValidity())) {
              return button.click();
            }
          }
          resolve(true);
        }).catch(e => e);
      },
      async BypassUsedInPacking(orderId) {
        let orderIdToBypass = [ orderId ]

        this.$eventHub.$emit('ValidateModalStart', {
        approve: 'Bypass',
        disapprove: 'Abort',
        message: 'Bypass packing order',
        type: 'danger',
        })
        
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          
          if(!approve) return;
          
          let response = await BPA.api.BypassUsedInPacking(orderIdToBypass)

          if (response.status == 204) {
            this.$eventHub.$emit('ShowMessages', {
              message: 'Bypassed complete',
              type: 'success',
              hide: 4000,
            });
          } else {
            this.$eventHub.$emit('ShowMessages', {
              message: 'Bypass failed',
              type: 'error',
              hide: 4000,
            });
          }
        })
      },
      QRCodePopup(barcode) {
        this.qr_barcode = barcode
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../../assets/style/variables/colors';
  @import '../../../assets/style/variables/icons';

  .table tbody {
    tr {
      &.parent {
        background-image: linear-gradient(rgba(oldlace, 0.5), rgba(oldlace, 0.5));
      }

      &.child {
        &:nth-child(odd) {
          background-image: linear-gradient(rgba(ivory, 0.5), rgba(ivory, 0.5));
        }

        &:nth-child(even) {
          background-image: linear-gradient(rgba(oldlace, 0.5), rgba(oldlace, 0.5));
          background-color: transparent;
        }
      }

      &.virtual {
        background: repeating-linear-gradient(-45deg, rgba(ivory, 0.75), rgba(ivory, 0.75) 25%, rgba(oldlace, 0.75) 25%, rgba(oldlace, 0.75) 50%, rgba(ivory, 0.75) 50%) top left /*fixed*/;
        background-size: 30px 30px;
        position: relative;
        
        &::after {
          top: 0;
          right: 0;
          width: 20px;
          height: 100%;
          //content: '';
          display: block;
          position: absolute;
          background: red;
        }

        .ribbon {
          width: 100px;
          height: 100px;
          overflow: hidden;
          position: absolute;

          &.top-left {
            top: 0;
            left: 0;
          }

          &.top-right {
            top: 0;
            right: 0;

            .ribbon-text {
              transform: translate(-5px, 18px) rotate(45deg);
            }
          }

          &.bottom-left {
            bottom: 0;
            left: 0;
          }

          &.bottom-right {
            bottom: 0;
            right: 0;
          }

          &-text {
            width: 150px;
            color: white;
            padding: 5px;
            font-size: 12px;
            font-weight: 600;
            user-select: none;
            text-align: center;
            position: absolute;
            letter-spacing: 1px;
            text-transform: uppercase;
            //backdrop-filter: blur(5px);
            //text-shadow: 1px 1px rgba(0, 0, 0, 0.15);
            background-color: rgba(40, 128, 214, 0.5);
          }
        }
      }

      td {
        &.actions ul {
          &.item-actions {
            /*min-width: 200px;*/
          }
        }

        .product {
          display: flex;
          align-items: center;

          &-description {
            width: 100%;
            margin-left: 2em;
            position: relative;

            .extra {
              display: flex;
              margin-top: 6px;
              position: relative;
              flex-direction: column;
              //font-size: 0.875em;

              .barcode, .sku {
                span {
                  //letter-spacing: 1px;
                }
              }
              .unique-identifiers {
                width: auto;
                height: 18px;
                max-width: 100%;
                display: flex;
                flex-grow: 0;
                position: relative;

                label[for=serials] {
                  width: auto;
                  display: flex;
                  cursor: pointer;
                  position: relative;
                  align-items: center;

                  &::before, &::after {
                    pointer-events: none;
                    cursor: pointer;
                    display: block;
                    z-index: 1;
                  }
                  &::before {
                    font-weight: bold;
                    white-space: nowrap;
                    content: attr(placeholder);
                    //text-decoration: underline;
                  }

                  &::after {
                    width: 1.15em;
                    height: 1.15em;
                    content: '';
                    font-weight: 600;
                    position: relative;
                    margin-left: 0.15em;
                    mask-size: contain;
                    mask-repeat: no-repeat;
                    mask-position: center;
                    background-color: $textFont;
                    mask-image: $caret-down;
                  }

                  input#serials {
                    left: 0;
                    opacity: 0;
                    padding: 0;
                    z-index: 2;
                    height: 100%;
                    cursor: pointer;
                    position: absolute;
                    user-select: none;
                  }

                  datalist#uid {
                    cursor: pointer;

                    option:hover {
                      
                    }
                  }
                }

                .copied {
                  top: 0;
                  opacity: 0;
                  margin-left: 10px;
                  user-select: none;
                  position: relative;
                  pointer-events: none;
                  transform: translateY(-100%);
                  transition: opacity 0.1s ease;

                  &.display {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  [class*=flag] {
    flex-shrink: 0;
    margin-right: 10px;
  }
  
  .pre {
    white-space: pre;
  }

  .list-content {
    .list-row {
      padding-top: 10px;
      padding-bottom: 10px;

      .list-col:not(.date) {
        text-transform: capitalize;
      }
      

      &.return {
        //background-color: rgba(0, 0, 0, 0.035);
      }
    }

    &.odd-even {
      .return {
        /*
        background-image: 
          repeating-linear-gradient(-45deg, rgba(black, 0.025) 0px, rgba(black, 0.025) 20px, transparent 20px, transparent 40px), 
          repeating-linear-gradient(-135deg, rgba(black, 0.025) 0px, rgba(black, 0.025) 20px, transparent 20px, transparent 40px);
        background-position: top left, bottom left;
        background-repeat: no-repeat;
        background-size: 100% 50%;
        */
        & + .return {
          /*
          background-image: 
            repeating-linear-gradient(-45deg, transparent 0px, transparent 20px, rgba(black, 0.025) 20px, rgba(black, 0.025) 40px), 
            repeating-linear-gradient(-135deg, transparent 0px, transparent 20px, rgba(black, 0.025) 20px, rgba(black, 0.025) 40px);
          */
        }
      }
    }
  }

  .grid-state .v-select {
    //text-transform: capitalize;
  }

  #create-parcel {
    .flex {
      &-row {
        height: 100%;

        & + .flex-row {
          margin-top: 30px;
        }
      }

      &-column {
        width: 100%;

        & + .flex-column {
          margin-left: 30px;
        }
      }

      &-group {

      }
    }
  }

  table.odd-even tbody tr.order-items__totals {
    background-color: $white;
  }

  .collect-list {
    &-data {
      top: 0;
      left: 0;
      opacity: 0;
      cursor: initial;
      width: max-content;
      list-style: none;
      visibility: hidden;
      position: absolute;
      text-transform: initial;
      background-color: inherit;
      transform: translateY(-100%);
      transition: opacity, visibility, 0.2s ease;

      li {
        display: flex;
        justify-content: space-between;
      }
    }

    &-id {
      position: relative;

      &.external {
        cursor: alias;
      }

      &.hover .collect-list-data {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .pick-error {
    background-image: url('../../../assets/images/icons/error.svg');
    width: 30px;
    height: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0px;
  }
  .pick-error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .qr-popup {
    display: block;
    position: absolute;
    width: fit-content;
    height: fit-content;
    margin-top: -2em;
    margin-left: 25em;
    background-color: white;
    z-index: 1;
  }
  .qr-popup-container {
    border: solid;
    border-radius: 0.2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .ean-number {
    &:nth-of-type(2)::before {
        content: ", ";
      }
      
  }
</style>