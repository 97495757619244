<template>
  <div class="help" :data-tool-tip="message">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-question-circle"
      viewBox="0 0 16 16"
    >
      <path
        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
      />
      <path
        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"
      />
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    message: String,
    left: String,
    right: String,
    bottom: String,
    top: String,
  },
  data() {
    return {};
  },
  mounted() {
    document.documentElement.style.setProperty('--bottom', this.bottom);
    document.documentElement.style.setProperty('--top', this.top);
    document.documentElement.style.setProperty('--left', this.left);
    document.documentElement.style.setProperty('--right', this.right);
  },
};
</script>
<style lang="scss" scoped>
$bottom: 0rem;
$top: 0rem;
$left: 0rem;
$right: 0rem;

.help {
  margin-left: 5px;
  position: relative;
}
.help::after {
  content: attr(data-tool-tip);
  font-size: 0.875rem;
  font-family: 'Founders Grotesk', 'Open sans', 'sans-serif';
  display: block;
  position: absolute;
  background-color: #efefef;
  padding: 0.25rem 0.5rem;
  max-width: 200px;
  width: fit-content;
  text-align: center;
  white-space: normal;
  transform: scale(0);
  transition: transform ease-out 150ms, bottom ease-out 150ms;
  border-radius: 5px;
  z-index: 99999;
  bottom: calc(var(--bottom, $bottom) * 1rem);
  top: calc(var(--top, $top) * 1rem);
  left: calc(var(--left, $left) * 1rem);
  right: calc(var(--right, $right) * 1rem);
}

.help:hover::after {
  transform: scale(1);
}
</style>
